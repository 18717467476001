import React, { useEffect, useState } from "react";
import {
  Carousel,
  List,
  Row,
  Col,
  Card,
  Typography,
  Tag,
  Image,
  Button,
  Alert,
  Modal,
  Form,
  Input,
  Radio,
  Checkbox,
  Divider,
} from "antd";
import {
  FieldTimeOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  getAllDharmaOnlinePlublic,
  getAllDharmaOnlineWithResults,
} from "../services/DharmaOnlineService";
import { getAllBannerPlublic } from "../services/BannerService";
import moment from "moment";
import { Link } from "react-router-dom";
import { userCreateDharmaOnline } from "../services/DharamaOnlineResultsService";
import {
  walkRound,
  walkTime,
  sitRound,
  sitTime,
  speak,
  actions,
  solveProblem,
  progress,
} from "../constants/dharmaOnline.constants";
import OnsiteHome from "../components/OnsiteHome";
import OnYearHome from "../components/OnYearHome";

const { Paragraph, Text, Title } = Typography;
const { TextArea } = Input;
const contentStyle = {
  textAlign: "center",
  background: "#364d79",
  cursor: "pointer",
};

export default function Home() {
  const userRole = localStorage.getItem("@userRole");
  const userJwt = localStorage.getItem("@userJwt");

  const [loading, setLoading] = useState(false);
  const [allDharmaOnline, setAllDharmaOnline] = useState([]);
  const [allBanner, setAllBanner] = useState([]);

  const [ImageFiles, setImageFiles] = useState(null);
  const [ImageShow, setImageShow] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleError, setIsModalVisibleError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [sendTimeStatus, setSendTimeStatus] = useState("WAIT");
  const [alertOption, setAlertOption] = useState("");
  const [dharmaOnlineId, setDharmaOnlineId] = useState(null);
  const [dharmaOnlineUuid, setDharmaOnlineUuid] = useState(null);
  const [dharmaOnlineIndex, setDharmaOnlineIndex] = useState(null);
  const [isDisableWalk, setIsDisableWalk] = useState(1);
  const [isDisableSit, setIsDisableSit] = useState(1);

  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");

  useEffect(() => {
    initAllData();
  }, []);

  const initAllData = async () => {
    const banner = await getAllBannerPlublic();
    if (!banner._error) {
      setAllBanner(banner.data);
    }
    if (userRole === "user") {
      const res = await getAllDharmaOnlineWithResults();
      if (!res._error) {
        setAllDharmaOnline(res.data);
      }
    } else {
      const res = await getAllDharmaOnlinePlublic();
      if (!res._error) {
        setAllDharmaOnline(res.data);
      }
    }
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageFiles(event.target.files[0]);
      setImageShow(URL.createObjectURL(event.target.files[0]));
    }
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            span: 6,
          },
          wrapperCol: {
            span: 18,
          },
        }
      : null;

  const renderTeacher = (t1, t2, t3, t4, t5) => {
    let res = [];
    if (t1 !== null && t1.length > 0) {
      res.push(t1);
    }
    if (t2 !== null && t2.length > 0) {
      res.push(t2);
    }
    if (t3 !== null && t3.length > 0) {
      res.push(t3);
    }
    if (t4 !== null && t4.length > 0) {
      res.push(t4);
    }
    if (t5 !== null && t5.length > 0) {
      res.push(t5);
    }
    return `วิปัสสนาจารย์: ${String(res).split(",").join(", ")}`;
  };
  const renderStartEnd = (start, end) => {
    const startTime = moment(start).add(543, "year").format("DD/MM/YYYY HH:mm");
    const endTime = moment(end).add(543, "year").format("DD/MM/YYYY HH:mm");
    return (
      <>
        <FieldTimeOutlined /> {startTime} ถึง {endTime}
      </>
    );
  };
  const renderReadMore = (uuidLink) => {
    const url = `/home/dharma-online-detail?uuidLink=${uuidLink}`;
    return (
      <>
        <InfoCircleOutlined /> <Link to={url}>อ่านเพิ่มเติม</Link>
      </>
    );
  };
  const NavigationTo = (link) => {
    if (link && link !== "null" && link.length > 0) {
      window.open(link);
    }
    return null;
  };
  const renderStartEndReport = (start, end) => {
    return `*สามารถรายงานได้ตั้งแต่ ${moment(start)
      .add(543, "year")
      .format("DD/MM/YYYY HH:mm")} ถึง ${moment(end)
      .add(543, "year")
      .format("DD/MM/YYYY")} 23:59:59
    หลังเสร็จการปฏิบัติธรรมออนไลน์`;
  };
  const startReportDharmaOnline = (item, index) => {
    setIsModalVisible(true);
    setDharmaOnlineIndex(index);
    setDharmaOnlineId(item.dharma_online_id);
    setDharmaOnlineUuid(item.dharma_online_uuid);
  };
  const renderDhamaResult = (item, index) => {
    if (userRole === "user") {
      const stsResult = item.dharma_online_results_status;
      const sts = item.sendTimeStatus;
      const startEndReport = renderStartEndReport(
        item.dharma_online_endOnlineTime,
        item.dharma_online_endOnlineTime
      );
      if (stsResult !== null) {
        return (
          <>
            <Alert
              message={
                <Link to={"/dharma-online-user-checker"}>
                  รายงานแล้ว คลิกเพื่อตรวจสอบ
                </Link>
              }
              type="success"
            />
          </>
        );
      } else {
        if (sts === "WAIT") {
          return (
            <>
              <Alert
                message={`ยังไม่ถึงเวลาให้รายงาน ${startEndReport}`}
                type="warning"
              />
            </>
          );
        } else if (sts === "CLOSE") {
          return (
            <>
              <Alert
                message={`หมดเวลาให้รายงาน ${startEndReport}`}
                type="error"
              />
            </>
          );
        } else {
          return (
            <>
              <Alert
                message={
                  <>
                    <Button
                      block
                      danger
                      type="primary"
                      onClick={() => startReportDharmaOnline(item, index)}
                      icon={<EditOutlined />}
                    >
                      คลิกเพื่อรายงานการเข้าร่วม
                    </Button>
                    <div>{startEndReport}</div>
                  </>
                }
                type="error"
              />
            </>
          );
        }
      }
    }
    return;
  };

  const handleOk = () => {
    setLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        // console.log(values);
        var actionsValue = "";
        if (values.actions && values.actions.length > 0) {
          actionsValue = values.actions.join();
        }
        const formData = new FormData();
        formData.append("file", ImageFiles);
        formData.append("dharmaOnlineId", dharmaOnlineId);
        formData.append("dharmaOnlineUuid", dharmaOnlineUuid);
        formData.append("walkRound", values.walkRound);
        formData.append("walkTime", values.walkTime);
        formData.append("sitRound", values.sitRound);
        formData.append("sitTime", values.sitTime);
        formData.append("speak", values.speak);
        formData.append("actions", actionsValue);
        formData.append("solveProblem", values.solveProblem);
        formData.append("progress", values.progress);
        formData.append("detail", values.detail || "");
        const res = await userCreateDharmaOnline(formData);
        if (!res._error) {
          initAllData();
          setIsModalVisible(false);
          setImageFiles(null);
          setImageShow(null);
          form.resetFields();
          //setIsSent(true);
        } else {
          const errorStatus = res._error_data.response.data.data;
          if (errorStatus === "WAIT") {
            setErrorMessage("ยังไม่ถึงเวลาการรายงานผลการปฏิบัติธรรม");
          } else if (errorStatus === "CLOSE") {
            setErrorMessage("หมดเวลาในการรายงานผลการปฏิบัติธรรมนี้แล้ว");
          }
          setIsModalVisible(false);
          setIsModalVisibleError(true);
        }
        setLoading(false);
      })
      .catch((info) => {
        setLoading(false);
      });
  };
  const handleCancelError = () => {
    window.location.reload(false);
  };
  return (
    <div>
      <Carousel autoplay>
        {allBanner.map((v) => (
          <div key={v.banner_id}>
            <div
              style={contentStyle}
              onClick={() => NavigationTo(v.banner_link)}
            >
              <center>
                <img
                  alt={v.name}
                  style={{
                    width: "100%",
                    aspectRatio: "4/1",
                    objectFit: "cover",
                  }}
                  src={v.banner_image}
                />
              </center>
            </div>
          </div>
        ))}
      </Carousel>

      {allDharmaOnline.length > 0 && (
        <Row gutter={[24, 0]}>
          <Col xs={24} md={24}>
            <Row gutter={[24, 0]}>
              <Col xs={24} className="mb-24">
                <Card className="header-solid h-full ant-card-p-0">
                  <List
                    itemLayout="vertical"
                    size="small"
                    header={
                      <h3>
                        <b>ปฏิบัติธรรมออนไลน์</b>
                      </h3>
                    }
                    pagination={{
                      onChange: (page) => {},
                      pageSize: 10,
                    }}
                    dataSource={allDharmaOnline}
                    renderItem={(item, index) => (
                      <List.Item
                        key={item.dharma_online_id}
                        extra={
                          <Image
                            style={{
                              width: 320,
                              height: 180,
                              backgroundColor: "#CCCCCC",
                              objectFit: "cover",
                              borderRadius: 8,
                            }}
                            width={320}
                            alt={item.dharma_online_name}
                            src={item.dharma_online_image}
                          />
                        }
                      >
                        <List.Item.Meta
                          title={
                            <Paragraph
                              ellipsis={{
                                rows: 2,
                                expandable: false,
                              }}
                            >
                              <Link
                                to={`/home/dharma-online-detail?uuidLink=${item.dharma_online_uuid}`}
                              >
                                {item.dharma_online_name}
                              </Link>
                            </Paragraph>
                          }
                          description={
                            <>
                              <Paragraph
                                ellipsis={{
                                  rows: 2,
                                  expandable: false,
                                }}
                              >
                                {item.dharma_online_detail}
                              </Paragraph>
                              <Paragraph
                                ellipsis={{
                                  rows: 1,
                                  expandable: false,
                                }}
                              >
                                {renderTeacher(
                                  item.teachers1_firstName,
                                  item.teachers2_firstName,
                                  item.teachers3_firstName,
                                  item.teachers4_firstName,
                                  item.teachers5_firstName
                                )}
                              </Paragraph>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Tag>
                                  {renderStartEnd(
                                    item.dharma_online_startOnlineTime,
                                    item.dharma_online_endOnlineTime
                                  )}
                                </Tag>
                                <Tag style={{ marginTop: 8 }}>
                                  {renderReadMore(item.dharma_online_uuid)}
                                </Tag>
                              </div>
                            </>
                          }
                        />
                        {renderDhamaResult(item, index)}
                      </List.Item>
                    )}
                  />
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      )}

      <Row gutter={[24, 0]}>
        <Col xs={24} md={24}>
          <Row gutter={[24, 0]}>
            <Col xs={24} className="mb-24">
              <OnsiteHome />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={[24, 0]}>
        <Col xs={24} md={24}>
          <Row gutter={[24, 0]}>
            <Col xs={24} className="mb-24">
              <OnYearHome />
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal
        title="รายงานผลการปฏิบัติธรรมออนไลน์"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={() => {
          form.resetFields();
          setIsModalVisible(false);
          setImageFiles(null);
          setImageShow(null);
          setIsDisableWalk(1);
          setIsDisableSit(1);
        }}
        width={"80%"}
        confirmLoading={loading}
        closable={false}
        maskClosable={false}
        footer={[
          <Button
            icon={<CloseCircleOutlined />}
            key="back"
            onClick={() => {
              form.resetFields();
              setIsModalVisible(false);
              setImageFiles(null);
              setImageShow(null);
              setIsDisableWalk(1);
              setIsDisableSit(1);
            }}
            disabled={loading}
          >
            ยกเลิก
          </Button>,
          <Button
            icon={<CheckCircleOutlined />}
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            ยืนยันรายงานผลการปฏิบัติธรรมออนไลน์
          </Button>,
        ]}
      >
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: 18,
            }}
          >
            {allDharmaOnline[dharmaOnlineIndex] && (
              <Text strong>
                {allDharmaOnline[dharmaOnlineIndex]
                  ? allDharmaOnline[dharmaOnlineIndex].dharma_online_name
                  : ""}
              </Text>
            )}
            {allDharmaOnline[dharmaOnlineIndex] && (
              <Text type="danger">
                *สามารถรายงานได้ตั้งแต่{" "}
                {moment(
                  allDharmaOnline[dharmaOnlineIndex].dharma_online_endOnlineTime
                )
                  .add(543, "year")
                  .format("DD/MM/YYYY HH:mm")}
                {" ถึง "}
                {moment(
                  allDharmaOnline[dharmaOnlineIndex].dharma_online_endOnlineTime
                )
                  .add(543, "year")
                  .format("DD/MM/YYYY")}{" "}
                23:59:59 หลังเสร็จการปฏิบัติธรรมออนไลน์
              </Text>
            )}
          </div>
          <Form
            // {...formItemLayout}
            layout={"vertical"}
            form={form}
            initialValues={{
              layout: "vertical",
              walkRound: 1,
              walkTime: 1,
              sitRound: 1,
              sitTime: 1,
              speak: 1,
              solveProblem: 1,
              progress: 1,
            }}
          >
            {ImageShow !== null && (
              <center>
                <Image
                  src={ImageShow}
                  style={{
                    width: 320,
                    backgroundColor: "#CCCCCC",
                  }}
                />
              </center>
            )}
            <Form.Item
              label="รูปภาพประกอบกิจกรรม :"
              name="image"
              required
              rules={[
                {
                  required: true,
                  message: "กรุณาเลือกภาพปกกิจกรรม!",
                },
              ]}
            >
              <Input
                type={"file"}
                placeholder="ระบุไฟล์ภาพปกกิจกรรม"
                accept="image/jpeg, image/png"
                onChange={onImageChange}
              />
            </Form.Item>
            <Divider />
            <Title level={5}>แบบบันทึกอารมย์กรรมฐานประจำวัน</Title>
            <Form.Item
              label="จำนวนรอบในการเดินจงกรม :"
              name="walkRound"
              required
              onChange={(e) => {
                if (e.target.value > 1) {
                  setIsDisableWalk(e.target.value);
                  form.setFieldsValue({ walkTime: 2 });
                } else {
                  setIsDisableWalk(e.target.value);
                  form.setFieldsValue({ walkTime: 1 });
                }
              }}
            >
              <Radio.Group>
                {walkRound.map((v) => (
                  <Radio key={v.id} value={v.id}>
                    {v.value}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="เวลาเฉลี่ยในการเดินจงกรม :"
              name="walkTime"
              required
            >
              <Radio.Group
                disabled={isDisableWalk === "1" || isDisableWalk === 1}
              >
                {walkTime.map((v) => (
                  <Radio
                    disabled={
                      v.id === 1 &&
                      (isDisableWalk !== "1" || isDisableWalk !== 1)
                    }
                    key={v.id}
                    value={v.id}
                  >
                    {v.value}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <Divider />
            <Form.Item
              label="จำนวนรอบในการนั่งสมาธิ :"
              name="sitRound"
              required
              onChange={(e) => {
                if (e.target.value > 1) {
                  setIsDisableSit(e.target.value);
                  form.setFieldsValue({ sitTime: 2 });
                } else {
                  setIsDisableSit(e.target.value);
                  form.setFieldsValue({ sitTime: 1 });
                }
              }}
            >
              <Radio.Group>
                {sitRound.map((v) => (
                  <Radio key={v.id} value={v.id}>
                    {v.value}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="เวลาเฉลี่ยในการนั่งสมาธิ :"
              name="sitTime"
              required
            >
              <Radio.Group
                disabled={isDisableSit === "1" || isDisableSit === 1}
              >
                {sitTime.map((v) => (
                  <Radio
                    disabled={
                      v.id === 1 && (isDisableSit !== "1" || isDisableSit !== 1)
                    }
                    key={v.id}
                    value={v.id}
                  >
                    {v.value}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <Divider />
            <Form.Item label="การปิดวาจา :" name="speak" required>
              <Radio.Group>
                {speak.map((v) => (
                  <Radio key={v.id} value={v.id}>
                    {v.value}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <Divider />
            <Form.Item
              label="สภาวะการปฏิบัติประจำวัน : ท่านพบสภาวะอย่างไรบ้าง (ตอบได้มากกว่า 1 ข้อ)"
              name="actions"
              valuePropName="checked"
            >
              <Checkbox.Group
                onChange={(checkedValues) => {
                  //console.log(checkedValues);
                  form.setFieldsValue({ actions: checkedValues });
                }}
              >
                <Row gutter={[24, 0]}>
                  {actions.map((v) => (
                    <Col key={v.id} md={6} xs={12}>
                      <Checkbox value={v.id} style={{ lineHeight: "32px" }}>
                        {v.value}
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Form.Item>
            <Divider />
            <Form.Item
              label="เมื่อเกิดปัญหาหรือข้อสงสัย :"
              name="solveProblem"
              required
            >
              <Radio.Group>
                {solveProblem.map((v) => (
                  <Radio key={v.id} value={v.id}>
                    {v.value}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <Divider />
            <Form.Item
              label="การปฏิบัติประจำวันมีความก้าวหน้าหรือไม่ :"
              name="progress"
              required
            >
              <Radio.Group>
                {progress.map((v) => (
                  <Radio key={v.id} value={v.id}>
                    {v.value}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <Divider />
            <Form.Item
              label="สรุปการปฏิบัติธรรม/ข้อสงสัย/ข้อเสนอแนะ ของท่านในการปฏิบัติธรรมครั้งนี้"
              name="detail"
              required
              rules={[
                {
                  required: true,
                  message:
                    "กรุณาสรุปการปฏิบัติธรรม/ข้อสงสัย/ข้อเสนอแนะ ของท่านในการปฏิบัติธรรมครั้งนี้!",
                },
              ]}
            >
              <TextArea rows={2} />
            </Form.Item>
          </Form>
        </>
      </Modal>
      <Modal
        title="เกิดข้อผิดพลาด"
        visible={isModalVisibleError}
        footer={null}
        onCancel={handleCancelError}
      >
        <p>{errorMessage}</p>
      </Modal>
    </div>
  );
}
