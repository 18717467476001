import React, { useEffect, useState } from "react";
import {
  Carousel,
  Avatar,
  List,
  Space,
  Row,
  Col,
  Card,
  Typography,
  Tag,
  Image,
  Button,
  Modal,
  Form,
  Input,
} from "antd";
import {
  CloseCircleOutlined,
  CheckCircleOutlined,
  EditOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import { getOneDharmaOnlinePlublicId } from "../../services/DharmaOnlineService";
import {
  userCreateDharmaOnline,
  checkUserIsSent,
} from "../../services/DharamaOnlineResultsService";
import moment from "moment";
import { useLocation } from "react-router-dom";

const { Text, Title } = Typography;
const { TextArea } = Input;
export default function DharmaOnlineDetail() {
  let query = new URLSearchParams(useLocation().search);
  let uuidToFind = query.get("uuidLink");
  const userRole = localStorage.getItem("@userRole");
  const [loading, setLoading] = useState(false);
  const [allDharmaOnline, setAllDharmaOnline] = useState([]);

  const [isSent, setIsSent] = useState(false);
  const [ImageFiles, setImageFiles] = useState(null);
  const [ImageShow, setImageShow] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleError, setIsModalVisibleError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [sendTimeStatus, setSendTimeStatus] = useState("WAIT");
  const [alertOption, setAlertOption] = useState("");

  const [dharmaOnlineId, setDharmaOnlineId] = useState(null);
  const [dharmaOnlineUuid, setDharmaOnlineUuid] = useState(null);

  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");

  useEffect(() => {
    initAllData();
  }, []);

  const initAllData = async () => {
    const res = await getOneDharmaOnlinePlublicId(uuidToFind);
    if (!res._error) {
      setAllDharmaOnline(res.data);
      setDharmaOnlineId(res.data[0].dharma_online_id);
      setDharmaOnlineUuid(res.data[0].dharma_online_uuid);
      setSendTimeStatus(res.data[0].sendTimeStatus);
      const sts = res.data[0].sendTimeStatus;
      let saveStatusAlert = "";
      if (sts === "WAIT") {
        saveStatusAlert = "(ยังไม่ถึงเวลาให้รายงาน)";
      } else if (sts === "CLOSE") {
        saveStatusAlert = "(หมดเวลาให้รายงาน)";
      } else {
        saveStatusAlert = "";
      }
      setAlertOption(saveStatusAlert);
    }
    if (userRole === "user") {
      const userIsDone = await checkUserIsSent(res.data[0].dharma_online_id);
      if (!userIsDone._error) {
        if (userIsDone.data) {
          setIsSent(true);
        }
      }
    }
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageFiles(event.target.files[0]);
      setImageShow(URL.createObjectURL(event.target.files[0]));
    }
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            span: 6,
          },
          wrapperCol: {
            span: 18,
          },
        }
      : null;

  const handleOk = () => {
    setLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        const formData = new FormData();
        formData.append("file", ImageFiles);
        formData.append("dharmaOnlineId", dharmaOnlineId);
        formData.append("dharmaOnlineUuid", dharmaOnlineUuid);
        formData.append("detail", values.detail || "");
        const res = await userCreateDharmaOnline(formData);
        if (!res._error) {
          initAllData();
          setIsModalVisible(false);
          setImageFiles(null);
          setImageShow(null);
          form.resetFields();
          setIsSent(true);
        } else {
          const errorStatus = res._error_data.response.data.data;
          if (errorStatus === "WAIT") {
            setErrorMessage("ยังไม่ถึงเวลาการรายงานผลการปฏิบัติธรรม");
          } else if (errorStatus === "CLOSE") {
            setErrorMessage("หมดเวลาในการรายงานผลการปฏิบัติธรรมนี้แล้ว");
          }
          setIsModalVisible(false);
          setIsModalVisibleError(true);
        }
        setLoading(false);
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  const renderStartEnd = (start, end) => {
    const startTime = moment(start).add(543, "year").format("DD/MM/YYYY HH:mm");
    const endTime = moment(end).add(543, "year").format("DD/MM/YYYY HH:mm");
    return (
      <>
        {startTime} ถึง {endTime}
      </>
    );
  };

  const gotoResultPage = () => {
    window.location = "/dharma-online-user-checker";
  };

  const handleCancelError = () => {
    window.location.reload(false);
  };

  return (
    <div>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24}>
          <Row gutter={[24, 0]}>
            <Col xs={24} className="mb-24">
              <Card className="header-solid h-full ant-card-p-0">
                <h3>
                  <b>
                    ปฏิบัติธรรมออนไลน์ :{" "}
                    {allDharmaOnline[0]
                      ? allDharmaOnline[0].dharma_online_name
                      : ""}
                  </b>
                </h3>
                <div>
                  <List size="small">
                    <Row gutter={[24, 0]}>
                      <Col xs={24} md={12} className="mb-24">
                        {allDharmaOnline[0] && (
                          <List.Item>
                            <Image
                              style={{
                                width: "100%",
                                aspectRatio: "16 / 9",
                                backgroundColor: "#CCCCCC",
                                objectFit: "cover",
                                borderRadius: 8,
                              }}
                              alt={allDharmaOnline[0].dharma_online_name}
                              src={allDharmaOnline[0].dharma_online_image}
                            />
                          </List.Item>
                        )}
                        {allDharmaOnline[0] &&
                          allDharmaOnline[0].dharma_online_detail.length >
                            0 && (
                            <List.Item>
                              <Text style={{ whiteSpace: "pre-wrap" }}>
                                {allDharmaOnline[0]
                                  ? allDharmaOnline[0].dharma_online_detail
                                  : ""}
                              </Text>
                            </List.Item>
                          )}
                      </Col>
                      <Col xs={24} md={12} className="mb-24">
                        {/* {userRole === "user" && allDharmaOnline[0] && (
                      <List.Item style={{ flexDirection: "column" }}>
                        <Text>สำหรับนักศึกษา</Text>
                        <div>
                          {!isSent && (
                            <Button
                              onClick={() => setIsModalVisible(true)}
                              icon={<EditOutlined />}
                              disabled={alertOption.trim().length > 0}
                              style={{ whiteSpace: "normal", height: "auto" }}
                            >
                              รายงานการปฏิบัติธรรมออนไลน์ {alertOption}
                            </Button>
                          )}
                          {isSent && (
                            <Button
                              onClick={gotoResultPage}
                              icon={<FileSearchOutlined />}
                              style={{ whiteSpace: "normal", height: "auto" }}
                            >
                              ท่านได้รายงานผลแล้วโปรดตรวจสอบผลการรายงานการปฏิบัติธรรมออนไลน์
                            </Button>
                          )}
                        </div>
                        <Text type="danger">
                          *สามารถรายงานได้ตั้งแต่{" "}
                          {moment(
                            allDharmaOnline[0].dharma_online_endOnlineTime
                          )
                            .add(543, "year")
                            .format("DD/MM/YYYY HH:mm")}
                          {" ถึง "}
                          {moment(
                            allDharmaOnline[0].dharma_online_endOnlineTime
                          )
                            .add(543, "year")
                            .format("DD/MM/YYYY")}{" "}
                          23:59:59 หลังเสร็จการปฏิบัติธรรมออนไลน์
                        </Text>
                      </List.Item>
                    )} */}

                        {allDharmaOnline[0] && (
                          <List.Item>
                            <Title level={5}>
                              {allDharmaOnline[0]
                                ? allDharmaOnline[0].dharma_online_name
                                : ""}
                            </Title>
                          </List.Item>
                        )}
                        {allDharmaOnline[0] && (
                          <List.Item>
                            <Text>
                              วันที่เวลา :{" "}
                              {renderStartEnd(
                                allDharmaOnline[0]
                                  .dharma_online_startOnlineTime,
                                allDharmaOnline[0].dharma_online_endOnlineTime
                              )}
                            </Text>
                          </List.Item>
                        )}
                        {allDharmaOnline[0] &&
                          allDharmaOnline[0].dharma_online_link.length > 0 && (
                            <List.Item>
                              <Text>
                                ลิ้งเข้าร่วม :{" "}
                                {allDharmaOnline[0] ? (
                                  <a
                                    href={allDharmaOnline[0].dharma_online_link}
                                    target={"_blank"}
                                  >
                                    {allDharmaOnline[0].dharma_online_link}
                                  </a>
                                ) : (
                                  ""
                                )}
                              </Text>
                            </List.Item>
                          )}
                        {allDharmaOnline[0] &&
                          allDharmaOnline[0].dharma_online_channel.length >
                            0 && (
                            <List.Item>
                              <Text>
                                ช่องทางอื่น :{" "}
                                {allDharmaOnline[0]
                                  ? allDharmaOnline[0].dharma_online_channel
                                  : ""}
                              </Text>
                            </List.Item>
                          )}

                        {allDharmaOnline[0] &&
                          allDharmaOnline[0].dharma_online_roomId.length >
                            0 && (
                            <List.Item>
                              <Text>
                                ไอดีห้อง :{" "}
                                {allDharmaOnline[0]
                                  ? allDharmaOnline[0].dharma_online_roomId
                                  : ""}
                              </Text>
                            </List.Item>
                          )}

                        {allDharmaOnline[0] &&
                          allDharmaOnline[0].dharma_online_roomPassword.length >
                            0 && (
                            <List.Item>
                              <Text>
                                รหัสเข้าห้อง :{" "}
                                {allDharmaOnline[0]
                                  ? allDharmaOnline[0]
                                      .dharma_online_roomPassword
                                  : ""}
                              </Text>
                            </List.Item>
                          )}

                        {allDharmaOnline[0] &&
                          allDharmaOnline[0].dharma_online_teacher1_by !==
                            0 && (
                            <List.Item>
                              <div>
                                <Text>วิปัสสนาจารย์ : </Text>
                                <Avatar
                                  style={{ marginLeft: 4, marginRight: 4 }}
                                  src={allDharmaOnline[0].teachers1_avatar}
                                />
                                <Text>
                                  {allDharmaOnline[0].teachers1_firstName}{" "}
                                  {allDharmaOnline[0].teachers1_nickname}{" "}
                                  {allDharmaOnline[0].teachers1_lastName}
                                </Text>
                              </div>
                            </List.Item>
                          )}
                        {allDharmaOnline[0] &&
                          allDharmaOnline[0].dharma_online_teacher2_by !==
                            0 && (
                            <List.Item>
                              <div>
                                <Text>วิปัสสนาจารย์ : </Text>
                                <Avatar
                                  style={{ marginLeft: 4, marginRight: 4 }}
                                  src={allDharmaOnline[0].teachers2_avatar}
                                />
                                <Text>
                                  {allDharmaOnline[0].teachers2_firstName}{" "}
                                  {allDharmaOnline[0].teachers2_nickname}{" "}
                                  {allDharmaOnline[0].teachers2_lastName}
                                </Text>
                              </div>
                            </List.Item>
                          )}
                        {allDharmaOnline[0] &&
                          allDharmaOnline[0].dharma_online_teacher3_by !==
                            0 && (
                            <List.Item>
                              <div>
                                <Text>วิปัสสนาจารย์ : </Text>
                                <Avatar
                                  style={{ marginLeft: 4, marginRight: 4 }}
                                  src={allDharmaOnline[0].teachers3_avatar}
                                />
                                <Text>
                                  {allDharmaOnline[0].teachers3_firstName}{" "}
                                  {allDharmaOnline[0].teachers3_nickname}{" "}
                                  {allDharmaOnline[0].teachers3_lastName}
                                </Text>
                              </div>
                            </List.Item>
                          )}
                        {allDharmaOnline[0] &&
                          allDharmaOnline[0].dharma_online_teacher4_by !==
                            0 && (
                            <List.Item>
                              <div>
                                <Text>วิปัสสนาจารย์ : </Text>
                                <Avatar
                                  style={{ marginLeft: 4, marginRight: 4 }}
                                  src={allDharmaOnline[0].teachers4_avatar}
                                />
                                <Text>
                                  {allDharmaOnline[0].teachers4_firstName}{" "}
                                  {allDharmaOnline[0].teachers4_nickname}{" "}
                                  {allDharmaOnline[0].teachers4_lastName}
                                </Text>
                              </div>
                            </List.Item>
                          )}
                        {allDharmaOnline[0] &&
                          allDharmaOnline[0].dharma_online_teacher5_by !==
                            0 && (
                            <List.Item>
                              <div>
                                <Text>วิปัสสนาจารย์ : </Text>
                                <Avatar
                                  style={{ marginLeft: 4, marginRight: 4 }}
                                  src={allDharmaOnline[0].teachers5_avatar}
                                />
                                <Text>
                                  {allDharmaOnline[0].teachers5_firstName}{" "}
                                  {allDharmaOnline[0].teachers5_nickname}{" "}
                                  {allDharmaOnline[0].teachers5_lastName}
                                </Text>
                              </div>
                            </List.Item>
                          )}
                      </Col>
                    </Row>
                  </List>
                  <Modal
                    title="รายงานผลการปฏิบัติธรรมออนไลน์"
                    visible={isModalVisible}
                    onOk={handleOk}
                    onCancel={() => {
                      form.resetFields();
                      setIsModalVisible(false);
                      setImageFiles(null);
                      setImageShow(null);
                    }}
                    width={"80%"}
                    confirmLoading={loading}
                    closable={false}
                    maskClosable={false}
                    footer={[
                      <Button
                        icon={<CloseCircleOutlined />}
                        key="back"
                        onClick={() => {
                          form.resetFields();
                          setIsModalVisible(false);
                          setImageFiles(null);
                          setImageShow(null);
                        }}
                        disabled={loading}
                      >
                        ยกเลิก
                      </Button>,
                      <Button
                        icon={<CheckCircleOutlined />}
                        key="submit"
                        type="primary"
                        loading={loading}
                        onClick={handleOk}
                      >
                        ยืนยันรายงานผลการปฏิบัติธรรมออนไลน์
                      </Button>,
                    ]}
                  >
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          marginBottom: 18,
                        }}
                      >
                        {allDharmaOnline[0] && (
                          <Text strong>
                            {allDharmaOnline[0]
                              ? allDharmaOnline[0].dharma_online_name
                              : ""}
                          </Text>
                        )}
                        {allDharmaOnline[0] && (
                          <Text type="danger">
                            *สามารถรายงานได้ตั้งแต่{" "}
                            {moment(
                              allDharmaOnline[0].dharma_online_endOnlineTime
                            )
                              .add(543, "year")
                              .format("DD/MM/YYYY HH:mm")}
                            {" ถึง "}
                            {moment(
                              allDharmaOnline[0].dharma_online_endOnlineTime
                            )
                              .add(543, "year")
                              .format("DD/MM/YYYY")}{" "}
                            23:59:59 หลังเสร็จการปฏิบัติธรรมออนไลน์
                          </Text>
                        )}
                      </div>
                      <Form
                        {...formItemLayout}
                        layout={formLayout}
                        form={form}
                        initialValues={{
                          layout: "vertical",
                          isActive: true,
                        }}
                      >
                        {ImageShow !== null && (
                          <center>
                            <Image
                              src={ImageShow}
                              style={{
                                width: 320,
                                backgroundColor: "#CCCCCC",
                              }}
                            />
                          </center>
                        )}
                        <Form.Item
                          label="รูปภาพประกอบกิจกรรม"
                          name="image"
                          required
                          rules={[
                            {
                              required: true,
                              message: "กรุณาเลือกภาพปกกิจกรรม!",
                            },
                          ]}
                        >
                          <Input
                            type={"file"}
                            placeholder="ระบุไฟล์ภาพปกกิจกรรม"
                            accept="image/jpeg, image/png"
                            onChange={onImageChange}
                          />
                        </Form.Item>
                        <Form.Item label="รายละเอียดเพิ่มเติม" name="detail">
                          <TextArea
                            rows={4}
                            placeholder="ระบุรายละเอียดเพิ่มเติม"
                          />
                        </Form.Item>
                      </Form>
                    </>
                  </Modal>
                  <Modal
                    title="เกิดข้อผิดพลาด"
                    visible={isModalVisibleError}
                    footer={null}
                    onCancel={handleCancelError}
                  >
                    <p>{errorMessage}</p>
                  </Modal>
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
