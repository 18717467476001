import React, { useEffect, useState } from "react";
import {
  Carousel,
  Avatar,
  List,
  Space,
  Row,
  Col,
  Card,
  Typography,
  Tag,
  Image,
  Button,
  Modal,
  Form,
  Input,
  Divider,
  DatePicker,
} from "antd";
import {
  CloseCircleOutlined,
  CheckCircleOutlined,
  EditOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import {
  getOneDharmaOnyearPlublicId,
  userCheckRegister,
  userRegister,
} from "../../services/DharmaOnyearService";
import { getMe } from "../../services/UsersServices";
// import { userCreateDharmaOnyear } from "../../services/DharamaOnyearResultsService";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { onSiteServices } from "../../constants/dharmaOnsite.constants";

const { Text, Title } = Typography;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

export default function DharmaOnyearDetail() {
  let query = new URLSearchParams(useLocation().search);
  let uuidToFind = query.get("uuidLink");
  const userRole = localStorage.getItem("@userRole");
  const [loading, setLoading] = useState(false);
  const [allDharmaOnyear, setAllDharmaOnyear] = useState([]);

  const [isSent, setIsSent] = useState(false);
  const [userInfo, setUserInfo] = useState([]);
  const [roomImages, setRoomImages] = useState([]);
  const [ImageFiles, setImageFiles] = useState(null);
  const [ImageShow, setImageShow] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleError, setIsModalVisibleError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [userRegCode, setUserRegCode] = useState("");

  const [sendTimeStatus, setSendTimeStatus] = useState("WAIT");
  const [alertOption, setAlertOption] = useState("");

  const [dharmaOnyearId, setDharmaOnyearId] = useState(null);
  const [dharmaOnyearUuid, setDharmaOnyearUuid] = useState(null);

  const [isModalConfrim, setIsModalConfrim] = useState(false);

  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");

  useEffect(() => {
    initAllData();
  }, []);

  const initAllData = async () => {
    if (userRole === "user") {
      const me = await getMe();
      if (!me._error) {
        setUserInfo(me.data);
      }
    }
    const res = await getOneDharmaOnyearPlublicId(uuidToFind);
    if (!res._error) {
      setAllDharmaOnyear(res.data);
      // console.log(res.data);
      setDharmaOnyearId(res.data[0].dharma_onyear_id);
      setDharmaOnyearUuid(res.data[0].dharma_onyear_uuid);
      setSendTimeStatus(res.data[0].sendTimeStatus);
      const sts = res.data[0].sendTimeStatus;
      let saveStatusAlert = "";
      if (sts === "WAIT") {
        saveStatusAlert = "(ยังไม่ถึงเวลาให้รายงาน)";
      } else if (sts === "CLOSE") {
        saveStatusAlert = "(หมดเวลาให้รายงาน)";
      } else {
        saveStatusAlert = "";
      }
      setAlertOption(saveStatusAlert);

      const roomImagesData = [];
      if (res.data[0].rooms_image1) {
        roomImagesData.push(res.data[0].rooms_image1);
      }
      if (res.data[0].rooms_image2) {
        roomImagesData.push(res.data[0].rooms_image2);
      }
      if (res.data[0].rooms_image3) {
        roomImagesData.push(res.data[0].rooms_image3);
      }
      if (res.data[0].rooms_image4) {
        roomImagesData.push(res.data[0].rooms_image4);
      }
      if (res.data[0].rooms_image5) {
        roomImagesData.push(res.data[0].rooms_image5);
      }
      if (res.data[0].rooms_image6) {
        roomImagesData.push(res.data[0].rooms_image6);
      }
      setRoomImages(roomImagesData);

      if (userRole === "user") {
        const reg = await userCheckRegister(res.data[0].dharma_onyear_id);
        if (!reg._error) {
          setUserRegCode(reg.code);
        }
      }
    }
  };

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledDate = (current) => {
    const nowTime = new Date().getTime();
    const startEvent = new Date(
      allDharmaOnyear[0].dharma_onyear_startOnyearTime
    ).getTime();
    if (nowTime < startEvent) {
      const setDisabledDate =
        (current &&
          current <
            moment(allDharmaOnyear[0].dharma_onyear_startOnyearTime)
              .hour(0)
              .minute(0)) ||
        (current &&
          current >
            moment(allDharmaOnyear[0].dharma_onyear_endOnyearTime)
              .hour(23)
              .minute(59));
      return setDisabledDate;
    } else {
      const setDisabledDate =
        (current && current < moment(nowTime).hour(0).minute(0)) ||
        (current &&
          current >
            moment(allDharmaOnyear[0].dharma_onyear_endOnyearTime)
              .hour(23)
              .minute(59));
      return setDisabledDate;
    }
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            span: 10,
          },
          wrapperCol: {
            span: 14,
          },
        }
      : null;

  const handleConfrim = () => {
    setIsModalConfrim(true);
  };

  const handleOk = () => {
    setIsModalConfrim(false);
    setLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        const res = await userRegister(dharmaOnyearId);
        if (!res._error) {
          initAllData();
          setIsModalVisible(false);
          setImageFiles(null);
          setImageShow(null);
          form.resetFields();
          setIsSent(true);
          window.location = "/dharma-onyear-user-checker";
        }
        setLoading(false);
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  const renderStartEnd = (start, end) => {
    const startTime = moment(start).add(543, "year").format("DD/MM/YYYY HH:mm");
    const endTime = moment(end).add(543, "year").format("DD/MM/YYYY HH:mm");
    return (
      <>
        {startTime} ถึง {endTime}
      </>
    );
  };

  const handleCancelError = () => {
    window.location.reload(false);
  };

  const checkConditionReceive = () => {
    if (userRole === "user") {
      if (userRegCode === "00000") {
        return (
          <>
            <List.Item style={{ flexDirection: "column" }}>
              <Button icon={<CloseCircleOutlined />} block>
                ไม่สามารถลงทะเบียนได้ กิจกรรมนี้เริ่มเรียบร้อยแล้ว!
              </Button>
            </List.Item>
          </>
        );
      } else if (userRegCode === "00200") {
        return (
          <>
            <List.Item style={{ flexDirection: "column" }}>
              <Button icon={<CheckCircleOutlined />} block>
                ท่านลงทะเบียนกิจกรรมนี้เรียบร้อยแล้ว!
              </Button>
            </List.Item>
          </>
        );
      } else if (userRegCode === "00900") {
        return (
          <>
            <List.Item style={{ flexDirection: "column" }}>
              <Button icon={<CloseCircleOutlined />} block>
                ไม่สามารถลงทะเบียนได้ จำนวนผู้ลงทะเบียนเต็มแล้ว!
              </Button>
            </List.Item>
          </>
        );
      } else if (userRegCode === "00500") {
        return (
          <>
            <List.Item style={{ flexDirection: "column" }}>
              <Button icon={<CloseCircleOutlined />} block>
                ไม่สามารถลงทะเบียนได้เนื่องจากเงื่อนไขไม่ตรง!
              </Button>
            </List.Item>
          </>
        );
      } else if (userRegCode === "00400") {
        return (
          <>
            <List.Item style={{ flexDirection: "column" }}>
              <Button
                onClick={() => setIsModalVisible(true)}
                icon={<EditOutlined />}
                block
              >
                ลงทะเบียนกิจกรรมนี้!
              </Button>
            </List.Item>
          </>
        );
      }
      return <></>;
    }
    return <></>;
  };

  const onChangeOnlineTime = (value, dateString) => {
    // console.log("Selected Time: ", value);
    // console.log("Formatted Selected Time: ", dateString);
  };
  const onOkOnlineTime = (value) => {
    // console.log("onOk: ", value);
  };

  const renderServicesListSelected = (data = "") => {
    // console.log("data", data);
    if (data !== "") {
      const getData = data.split(",").map(Number);
      return getData
        .map((v) => {
          return onSiteServices[v - 1].value;
        })
        .join();
    }
    return "-";
  };

  return (
    <div>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24}>
          <Row gutter={[24, 0]}>
            <Col xs={24} className="mb-24">
              <Card className="header-solid h-full ant-card-p-0">
                <h3>
                  <b>
                    ปฏิบัติธรรมในสถานที่ :{" "}
                    {allDharmaOnyear[0]
                      ? allDharmaOnyear[0].dharma_onyear_name
                      : ""}
                  </b>
                </h3>
                <div>
                  <List size="small">
                    <Row gutter={[24, 0]}>
                      <Col xs={24} md={12} className="mb-24">
                        {allDharmaOnyear[0] && (
                          <List.Item>
                            <Image
                              style={{
                                width: "100%",
                                aspectRatio: "16 / 9",
                                backgroundColor: "#CCCCCC",
                                objectFit: "cover",
                                borderRadius: 8,
                              }}
                              alt={allDharmaOnyear[0].dharma_onyear_name}
                              src={allDharmaOnyear[0].dharma_onyear_image}
                            />
                          </List.Item>
                        )}
                        {allDharmaOnyear[0] &&
                          allDharmaOnyear[0].dharma_onyear_detail.length >
                            0 && (
                            <List.Item>
                              <Text style={{ whiteSpace: "pre-wrap" }}>
                                {allDharmaOnyear[0]
                                  ? allDharmaOnyear[0].dharma_onyear_detail
                                  : ""}
                              </Text>
                            </List.Item>
                          )}
                      </Col>
                      <Col xs={24} md={12} className="mb-24">
                        {allDharmaOnyear[0] && (
                          <List.Item>
                            <Title level={5}>
                              {allDharmaOnyear[0]
                                ? allDharmaOnyear[0].dharma_onyear_name
                                : ""}
                            </Title>
                          </List.Item>
                        )}
                        {allDharmaOnyear[0] && (
                          <List.Item>
                            <Text>
                              วันที่เวลา :{" "}
                              {renderStartEnd(
                                allDharmaOnyear[0]
                                  .dharma_onyear_startOnyearTime,
                                allDharmaOnyear[0].dharma_onyear_endOnyearTime
                              )}
                            </Text>
                          </List.Item>
                        )}

                        {allDharmaOnyear[0] &&
                          allDharmaOnyear[0].rooms_name.length > 0 && (
                            <List.Item>
                              <Text>
                                สถานที่ :{" "}
                                {allDharmaOnyear[0]
                                  ? allDharmaOnyear[0].rooms_name
                                  : ""}
                              </Text>
                            </List.Item>
                          )}

                        {allDharmaOnyear[0] &&
                          allDharmaOnyear[0].dharma_onyear_teacher1_by !==
                            0 && (
                            <List.Item>
                              <div>
                                <Text>วิปัสสนาจารย์ : </Text>
                                <Avatar
                                  style={{ marginLeft: 4, marginRight: 4 }}
                                  src={allDharmaOnyear[0].teachers1_avatar}
                                />
                                <Text>
                                  {allDharmaOnyear[0].teachers1_firstName}{" "}
                                  {allDharmaOnyear[0].teachers1_nickname}{" "}
                                  {allDharmaOnyear[0].teachers1_lastName}
                                </Text>
                              </div>
                            </List.Item>
                          )}
                        {allDharmaOnyear[0] &&
                          allDharmaOnyear[0].dharma_onyear_teacher2_by !==
                            0 && (
                            <List.Item>
                              <div>
                                <Text>วิปัสสนาจารย์ : </Text>
                                <Avatar
                                  style={{ marginLeft: 4, marginRight: 4 }}
                                  src={allDharmaOnyear[0].teachers2_avatar}
                                />
                                <Text>
                                  {allDharmaOnyear[0].teachers2_firstName}{" "}
                                  {allDharmaOnyear[0].teachers2_nickname}{" "}
                                  {allDharmaOnyear[0].teachers2_lastName}
                                </Text>
                              </div>
                            </List.Item>
                          )}
                        {allDharmaOnyear[0] &&
                          allDharmaOnyear[0].dharma_onyear_teacher3_by !==
                            0 && (
                            <List.Item>
                              <div>
                                <Text>วิปัสสนาจารย์ : </Text>
                                <Avatar
                                  style={{ marginLeft: 4, marginRight: 4 }}
                                  src={allDharmaOnyear[0].teachers3_avatar}
                                />
                                <Text>
                                  {allDharmaOnyear[0].teachers3_firstName}{" "}
                                  {allDharmaOnyear[0].teachers3_nickname}{" "}
                                  {allDharmaOnyear[0].teachers3_lastName}
                                </Text>
                              </div>
                            </List.Item>
                          )}
                        {allDharmaOnyear[0] &&
                          allDharmaOnyear[0].dharma_onyear_teacher4_by !==
                            0 && (
                            <List.Item>
                              <div>
                                <Text>วิปัสสนาจารย์ : </Text>
                                <Avatar
                                  style={{ marginLeft: 4, marginRight: 4 }}
                                  src={allDharmaOnyear[0].teachers4_avatar}
                                />
                                <Text>
                                  {allDharmaOnyear[0].teachers4_firstName}{" "}
                                  {allDharmaOnyear[0].teachers4_nickname}{" "}
                                  {allDharmaOnyear[0].teachers4_lastName}
                                </Text>
                              </div>
                            </List.Item>
                          )}
                        {allDharmaOnyear[0] &&
                          allDharmaOnyear[0].dharma_onyear_teacher5_by !==
                            0 && (
                            <List.Item>
                              <div>
                                <Text>วิปัสสนาจารย์ : </Text>
                                <Avatar
                                  style={{ marginLeft: 4, marginRight: 4 }}
                                  src={allDharmaOnyear[0].teachers5_avatar}
                                />
                                <Text>
                                  {allDharmaOnyear[0].teachers5_firstName}{" "}
                                  {allDharmaOnyear[0].teachers5_nickname}{" "}
                                  {allDharmaOnyear[0].teachers5_lastName}
                                </Text>
                              </div>
                            </List.Item>
                          )}

                        {checkConditionReceive()}
                      </Col>
                    </Row>
                  </List>
                  <Divider />
                  <Row gutter={[24, 0]}>
                    <Col xs={24} className="mb-24">
                      <Row gutter={[24, 0]}>
                        <Col xs={24} md={12} className="mb-24">
                          ข้อมูลสถานที่ :{" "}
                          <Text>
                            {allDharmaOnyear[0]
                              ? allDharmaOnyear[0].rooms_name
                              : ""}
                          </Text>
                        </Col>
                      </Row>
                      <Row gutter={[24, 0]}>
                        <Col xs={24} md={12} className="mb-24">
                          <Row gutter={[24, 0]}>
                            {roomImages.map((v, index) => (
                              <Col
                                key={index}
                                xs={12}
                                md={12}
                                style={{ marginBottom: 10 }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    aspectRatio: "1/1",
                                  }}
                                >
                                  <Image
                                    style={{
                                      width: "100%",
                                      aspectRatio: "1/1",
                                      objectFit: "cover",
                                    }}
                                    src={v}
                                  />
                                </div>
                              </Col>
                            ))}
                          </Row>
                        </Col>
                        <Col xs={24} md={12} className="mb-24">
                          <List size="small">
                            <List.Item>
                              <Text>
                                ชื่อสถานที่ :{" "}
                                {allDharmaOnyear[0]
                                  ? allDharmaOnyear[0].rooms_name
                                  : "-"}
                              </Text>
                            </List.Item>
                            <List.Item>
                              <Text>
                                อาคาร/ตึก :{" "}
                                {allDharmaOnyear[0]
                                  ? allDharmaOnyear[0].rooms_building
                                  : "-"}
                              </Text>
                            </List.Item>
                            <List.Item>
                              <Text>
                                ชั้น :{" "}
                                {allDharmaOnyear[0]
                                  ? allDharmaOnyear[0].rooms_floor
                                  : "-"}
                              </Text>
                            </List.Item>
                            <List.Item>
                              <Text>
                                จำนวนที่รองรับ :{" "}
                                {allDharmaOnyear[0]
                                  ? allDharmaOnyear[0].rooms_maxSeats
                                  : ""}
                              </Text>
                            </List.Item>
                            <List.Item>
                              <Text>
                                สิ่งอำนวยความสะดวก :{" "}
                                {allDharmaOnyear[0]
                                  ? renderServicesListSelected(
                                      allDharmaOnyear[0].rooms_services
                                    )
                                  : "-"}
                              </Text>
                            </List.Item>
                            {allDharmaOnyear[0] &&
                              allDharmaOnyear[0].rooms_detail.length > 0 && (
                                <List.Item>
                                  <Text>
                                    เพิ่มเติม :{" "}
                                    {allDharmaOnyear[0]
                                      ? allDharmaOnyear[0].rooms_detail
                                      : ""}
                                  </Text>
                                </List.Item>
                              )}
                          </List>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Modal
                    title="ลงทะเบียนเข้าร่วมกิจกรรมปฏิบัติธรรมประจำปี"
                    visible={isModalVisible}
                    onOk={handleConfrim}
                    onCancel={() => {
                      form.resetFields();
                      setIsModalVisible(false);
                      setImageFiles(null);
                      setImageShow(null);
                    }}
                    // width={"80%"}
                    centered
                    confirmLoading={loading}
                    closable={false}
                    maskClosable={false}
                    footer={[
                      <Button
                        icon={<CheckCircleOutlined />}
                        key="submit"
                        type="primary"
                        loading={loading}
                        onClick={handleConfrim}
                      >
                        ยืนยันลงทะเบียนเข้าร่วมกิจกรรม
                      </Button>,
                      <Button
                        icon={<CloseCircleOutlined />}
                        key="back"
                        onClick={() => {
                          form.resetFields();
                          setIsModalVisible(false);
                          setImageFiles(null);
                          setImageShow(null);
                        }}
                        disabled={loading}
                      >
                        ยกเลิก
                      </Button>,
                    ]}
                  >
                    <>
                      <Form
                        {...formItemLayout}
                        layout={formLayout}
                        form={form}
                        initialValues={{
                          layout: "vertical",
                          isActive: true,
                        }}
                      >
                        <div
                          style={{ flexDirection: "column", display: "flex" }}
                        ></div>
                        <Form.Item label="กิจกรรม" name="dharma_onyear_name">
                          {allDharmaOnyear[0] && (
                            <Text strong>
                              {allDharmaOnyear[0]
                                ? " " + allDharmaOnyear[0].dharma_onyear_name
                                : ""}
                            </Text>
                          )}
                        </Form.Item>
                        <Form.Item label="ชื่อสถานที่" name="rooms_name">
                          {allDharmaOnyear[0] && (
                            <Text>
                              {allDharmaOnyear[0]
                                ? allDharmaOnyear[0].rooms_name
                                : ""}
                            </Text>
                          )}
                        </Form.Item>
                        <Form.Item
                          label="วันที่เวลาของกิจกรรม"
                          name="dharma_onyear_startOnyearTime"
                        >
                          {allDharmaOnyear[0] && (
                            <Text>
                              {renderStartEnd(
                                allDharmaOnyear[0]
                                  .dharma_onyear_startOnyearTime,
                                allDharmaOnyear[0].dharma_onyear_endOnyearTime
                              )}
                            </Text>
                          )}
                        </Form.Item>
                      </Form>
                    </>
                  </Modal>
                  <Modal
                    title="ท่านต้องการยืนยัน? "
                    visible={isModalConfrim}
                    onOk={handleOk}
                    onCancel={() => setIsModalConfrim(false)}
                    centered
                    footer={[
                      <Button
                        icon={<CheckCircleOutlined />}
                        key="submit"
                        type="primary"
                        loading={loading}
                        onClick={handleOk}
                      >
                        ยืนยัน
                      </Button>,
                      <Button
                        icon={<CloseCircleOutlined />}
                        key="back"
                        onClick={() => {
                          setIsModalConfrim(false);
                        }}
                        disabled={loading}
                      >
                        ยกเลิก
                      </Button>,
                    ]}
                  >
                    <p>ท่านต้องการยืนยันการลงทะเบียนเข้าร่วมกิจกรรม</p>
                  </Modal>
                  <Modal
                    title="เกิดข้อผิดพลาด"
                    visible={isModalVisibleError}
                    footer={null}
                    onCancel={handleCancelError}
                    centered
                  >
                    <p>{errorMessage}</p>
                  </Modal>
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
