import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Input,
  Space,
  Modal,
  Form,
  Switch,
  Select,
  Typography,
  Image,
  DatePicker,
} from "antd";
import {
  SearchOutlined,
  PlusCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { SubAdminChecker } from "../../utils/AdminChecker";
import {
  getAllDharmaOnline,
  createDharmaOnline,
  updateDharmaOnline,
} from "../../services/DharmaOnlineService";
import { getAllDatas } from "../../services/TeacherService";
import moment from "moment";

const { TextArea } = Input;
const { RangePicker } = DatePicker;

function DharmaOnlineData() {
  SubAdminChecker();
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [allTeacher, setAllTeacher] = useState([]);

  const [ImageFiles, setImageFiles] = useState(null);
  const [ImageShow, setImageShow] = useState(null);

  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [editId, setEditId] = useState(-1);
  const [editPassword, setEditPassword] = useState(-1);

  const [form] = Form.useForm();
  const [formEdit] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");

  useEffect(() => {
    // todo
    // get all User to checker
    initAllData();
  }, []);

  const initAllData = async () => {
    const res = await getAllDharmaOnline();
    if (!res._error) {
      setAllData(res.data);
    }
    const teachers = await getAllDatas();
    if (!teachers._error) {
      setAllTeacher(teachers.data);
    }
  };

  const handleSwitch = async (values, e) => {
    const formData = new FormData();
    formData.append("id", values.dharma_online_id);
    formData.append("name", values.dharma_online_name);
    formData.append("link", values.dharma_online_link);
    formData.append("channel", values.dharma_online_channel || "");
    formData.append("roomId", values.dharma_online_roomId || "");
    formData.append("roomPassword", values.dharma_online_roomPassword || "");
    formData.append("detail", values.dharma_online_detail || "");
    formData.append("startOnlineTime", values.dharma_online_startOnlineTime);
    formData.append("endOnlineTime", values.dharma_online_endOnlineTime);
    formData.append("startShowTime", values.dharma_online_startShowTime);
    formData.append("endShowTime", values.dharma_online_endShowTime);
    formData.append("teacher1_by", values.dharma_online_teacher1_by);
    formData.append("teacher2_by", values.dharma_online_teacher2_by);
    formData.append("teacher3_by", values.dharma_online_teacher3_by);
    formData.append("teacher4_by", values.dharma_online_teacher4_by);
    formData.append("teacher5_by", values.dharma_online_teacher5_by);
    formData.append("isActive", e);
    const res = await updateDharmaOnline(formData);
    var newData = res.data[0];
    if (!res._error) {
      const updateData = allData.map((item) => {
        if (item.dharma_online_id === values.dharma_online_id) {
          var userActive = false;
          if (e === true || e === 1) {
            userActive = true;
          }
          return { ...item, ...newData, dharma_online_isActive: userActive };
        }
        return item;
      });
      // console.log(updateData);
      setAllData(updateData);
    }
  };

  const openEdit = (data) => {
    setEditId(data.dharma_online_id);
    setImageShow(data.dharma_online_image);
    var userActive = false;
    if (
      data.dharma_online_isActive === true ||
      data.dharma_online_isActive === 1
    ) {
      userActive = true;
    }
    const values = {
      layout: "vertical",
      name: data.dharma_online_name,
      link: data.dharma_online_link,
      detail: data.dharma_online_detail,
      channel: data.dharma_online_channel,
      roomId: data.dharma_online_roomId,
      roomPassword: data.dharma_online_roomPassword,
      onlineTime: [
        moment(data.dharma_online_startOnlineTime),
        moment(data.dharma_online_endOnlineTime),
      ],
      showTime: [
        moment(data.dharma_online_startShowTime),
        moment(data.dharma_online_endShowTime),
      ],
      teacher1_by: data.dharma_online_teacher1_by,
      teacher2_by: data.dharma_online_teacher2_by,
      teacher3_by: data.dharma_online_teacher3_by,
      teacher4_by: data.dharma_online_teacher4_by,
      teacher5_by: data.dharma_online_teacher5_by,
      isActive: userActive,
    };
    // console.log(values);
    formEdit.setFieldsValue(values);
    setIsModalEditVisible(true);
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            span: 6,
          },
          wrapperCol: {
            span: 18,
          },
        }
      : null;

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const renderAvatar = (avatar) => {
    if (avatar) {
      return (
        <Image
          style={{
            width: 160,
            height: 90,
            backgroundColor: "#CCCCCC",
            objectFit: "cover",
          }}
          src={avatar}
        />
      );
    }
    return (
      <div
        style={{
          width: 160,
          height: 90,
          backgroundColor: "#CCCCCC",
        }}
        icon={<UserOutlined />}
      />
    );
  };
  const columns = [
    {
      title: "ภาพปกกิจกรรม",
      dataIndex: "b.dharma_online_image",
      width: 200,
      render: (_, record) =>
        allData.length >= 1 ? renderAvatar(record.dharma_online_image) : null,
    },
    {
      title: "รหัส",
      width: 150,
      dataIndex: "dharma_online_id",
      key: "dharma_online_id",
      ...getColumnSearchProps("dharma_online_id"),
      sorter: (a, b) => a.dharma_online_id - b.dharma_online_id,
      sortOrder:
        sortedInfo.columnKey === "dharma_online_id" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "หัวข้อกิจกรรม",
      dataIndex: "dharma_online_name",
      key: "dharma_online_name",
      ...getColumnSearchProps("dharma_online_name"),
      sorter: (a, b) => a.prefixs_name.localeCompare(b.prefixs_name),
      sortOrder:
        sortedInfo.columnKey === "dharma_online_name" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ลิ้งออนไลน์",
      dataIndex: "dharma_online_link",
      key: "dharma_online_link",
      ...getColumnSearchProps("dharma_online_link"),
      sorter: (a, b) =>
        a.dharma_online_link.localeCompare(b.dharma_online_link),
      sortOrder:
        sortedInfo.columnKey === "dharma_online_link" ? sortedInfo.order : null,
      ellipsis: true,
      render: (_, record) => (
        <a target="_blank" href={record.dharma_online_link}>
          {record.dharma_online_link}
        </a>
      ),
    },
    {
      title: "วันเวลาเริ่มออนไลน์",
      dataIndex: "dharma_online_startOnlineTime",
      key: "dharma_online_startOnlineTime",
      ...getColumnSearchProps("dharma_online_startOnlineTime"),
      sorter: (a, b) =>
        a.dharma_online_startOnlineTime.localeCompare(
          b.dharma_online_startOnlineTime
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_online_startOnlineTime"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        moment(record.dharma_online_startOnlineTime).format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "วันเวลาสิ้นสุดออนไลน์",
      dataIndex: "dharma_online_endOnlineTime",
      key: "dharma_online_endOnlineTime",
      ...getColumnSearchProps("dharma_online_endOnlineTime"),
      sorter: (a, b) =>
        a.dharma_online_endOnlineTime.localeCompare(
          b.dharma_online_endOnlineTime
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_online_endOnlineTime"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        moment(record.dharma_online_endOnlineTime).format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "วันเวลาเริ่มแสดงข้อมูล",
      dataIndex: "dharma_online_startShowTime",
      key: "dharma_online_startShowTime",
      ...getColumnSearchProps("dharma_online_startShowTime"),
      sorter: (a, b) =>
        a.dharma_online_startShowTime.localeCompare(
          b.dharma_online_startShowTime
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_online_startShowTime"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        moment(record.dharma_online_startShowTime).format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "วันเวลาสิ้นสุดแสดงข้อมูล",
      dataIndex: "dharma_online_endShowTime",
      key: "dharma_online_endShowTime",
      ...getColumnSearchProps("dharma_online_endShowTime"),
      sorter: (a, b) =>
        a.dharma_online_endShowTime.localeCompare(b.dharma_online_endShowTime),
      sortOrder:
        sortedInfo.columnKey === "dharma_online_endShowTime"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        moment(record.dharma_online_endShowTime).format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "วิปัสสนาจารย์ 1",
      dataIndex: "teachers1_firstName",
      key: "teachers1_firstName",
      ...getColumnSearchProps("teachers1_firstName"),
      sorter: (a, b) =>
        a.teachers1_firstName.localeCompare(b.teachers1_firstName),
      sortOrder:
        sortedInfo.columnKey === "teachers1_firstName"
          ? sortedInfo.order
          : null,
      ellipsis: true,
    },
    {
      title: "วิปัสสนาจารย์ 2",
      dataIndex: "teachers2_firstName",
      key: "teachers2_firstName",
      ...getColumnSearchProps("teachers2_firstName"),
      sorter: (a, b) =>
        a.teachers2_firstName.localeCompare(b.teachers2_firstName),
      sortOrder:
        sortedInfo.columnKey === "teachers2_firstName"
          ? sortedInfo.order
          : null,
      ellipsis: true,
    },
    {
      title: "วิปัสสนาจารย์ 3",
      dataIndex: "teachers3_firstName",
      key: "teachers3_firstName",
      ...getColumnSearchProps("teachers3_firstName"),
      sorter: (a, b) =>
        a.teachers3_firstName.localeCompare(b.teachers3_firstName),
      sortOrder:
        sortedInfo.columnKey === "teachers3_firstName"
          ? sortedInfo.order
          : null,
      ellipsis: true,
    },
    {
      title: "วิปัสสนาจารย์ 4",
      dataIndex: "teachers4_firstName",
      key: "teachers4_firstName",
      ...getColumnSearchProps("teachers4_firstName"),
      sorter: (a, b) =>
        a.teachers4_firstName.localeCompare(b.teachers4_firstName),
      sortOrder:
        sortedInfo.columnKey === "teachers4_firstName"
          ? sortedInfo.order
          : null,
      ellipsis: true,
    },
    {
      title: "วิปัสสนาจารย์ 5",
      dataIndex: "teachers5_firstName",
      key: "teachers5_firstName",
      ...getColumnSearchProps("teachers5_firstName"),
      sorter: (a, b) =>
        a.teachers5_firstName.localeCompare(b.teachers5_firstName),
      sortOrder:
        sortedInfo.columnKey === "teachers5_firstName"
          ? sortedInfo.order
          : null,
      ellipsis: true,
    },
    {
      title: "สถานะการใช้งาน",
      dataIndex: "dharma_online_isActive",
      sorter: (a, b) =>
        JSON.stringify(a.dharma_online_isActive).localeCompare(
          JSON.stringify(b.dharma_online_isActive)
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_online_isActive"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      width: 120,
      render: (_, record) =>
        allData.length >= 1 ? (
          <Switch
            checkedChildren="เปิด "
            unCheckedChildren="ปิด "
            checked={record.dharma_online_isActive}
            onChange={(e) => handleSwitch(record, e)}
          />
        ) : null,
    },
    {
      title: "แก้ไข",
      dataIndex: "operation",
      fixed: "right",
      width: 120,
      render: (_, record) =>
        allData.length >= 1 ? (
          <>
            <Typography.Link
              onClick={() => openEdit(record)}
              style={{ marginLeft: 6 }}
            >
              {"แก้ไข"}
            </Typography.Link>
          </>
        ) : null,
    },
  ];

  const handleOk = () => {
    setLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        const formData = new FormData();
        formData.append("file", ImageFiles);
        formData.append("name", values.name);
        formData.append("link", values.link);
        formData.append("channel", values.channel || "");
        formData.append("roomId", values.roomId || "");
        formData.append("roomPassword", values.roomPassword || "");
        formData.append("detail", values.detail || "");
        formData.append("startOnlineTime", values.onlineTime[0]._d.toJSON());
        formData.append("endOnlineTime", values.onlineTime[1]._d.toJSON());
        formData.append("startShowTime", values.showTime[0]._d.toJSON());
        formData.append("endShowTime", values.showTime[1]._d.toJSON());
        formData.append("teacher1_by", values.teacher1_by);
        formData.append("teacher2_by", values.teacher2_by || 0);
        formData.append("teacher3_by", values.teacher3_by || 0);
        formData.append("teacher4_by", values.teacher4_by || 0);
        formData.append("teacher5_by", values.teacher5_by || 0);
        formData.append("isActive", values.isActive);
        const res = await createDharmaOnline(formData);
        if (!res._error) {
          initAllData();
          setIsModalVisible(false);
          setImageFiles(null);
          setImageShow(null);
          form.resetFields();
        }
        setLoading(false);
      })
      .catch((info) => {
        setLoading(false);
      });
  };
  const handleEditOk = () => {
    setLoading(true);
    formEdit
      .validateFields()
      .then(async (values) => {
        const formData = new FormData();
        formData.append("file", ImageFiles);
        formData.append("id", editId);
        formData.append("name", values.name);
        formData.append("link", values.link);
        formData.append("channel", values.channel || "");
        formData.append("roomId", values.roomId || "");
        formData.append("roomPassword", values.roomPassword || "");
        formData.append("detail", values.detail || "");
        formData.append("startOnlineTime", values.onlineTime[0]._d.toJSON());
        formData.append("endOnlineTime", values.onlineTime[1]._d.toJSON());
        formData.append("startShowTime", values.showTime[0]._d.toJSON());
        formData.append("endShowTime", values.showTime[1]._d.toJSON());
        formData.append("teacher1_by", values.teacher1_by);
        formData.append("teacher2_by", values.teacher2_by || 0);
        formData.append("teacher3_by", values.teacher3_by || 0);
        formData.append("teacher4_by", values.teacher4_by || 0);
        formData.append("teacher5_by", values.teacher5_by || 0);
        formData.append("isActive", values.isActive);
        const res = await updateDharmaOnline(formData);
        var newData = res.data[0];
        if (!res._error) {
          const updateData = allData.map((item) => {
            if (item.dharma_online_id === editId) {
              return {
                ...item,
                ...newData,
              };
            }
            return item;
          });
          setAllData(updateData);
          setIsModalEditVisible(false);
          setImageFiles(null);
          setImageShow(null);
          formEdit.resetFields();
        }
        setLoading(false);
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageFiles(event.target.files[0]);
      setImageShow(URL.createObjectURL(event.target.files[0]));
    }
  };

  const onChangeOnlineTime = (value, dateString) => {
    // console.log("Selected Time: ", value);
    // console.log("Formatted Selected Time: ", dateString);
  };
  const onOkOnlineTime = (value) => {
    // console.log("onOk: ", value);
  };

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24}>
          <Row gutter={[24, 0]}>
            <Col xs={24} className="mb-24">
              <Card
                className="header-solid h-full ant-card-p-0"
                title={
                  <>
                    <Row
                      gutter={[24, 0]}
                      className="ant-row-flex ant-row-flex-middle"
                    >
                      <Col xs={24} md={12}>
                        <h6 className="font-semibold m-0">
                          ข้อมูลปฏิบัติธรรมออนไลน์
                        </h6>
                      </Col>
                      <Col xs={24} md={12} className="d-flex">
                        <Button
                          onClick={() => setIsModalVisible(true)}
                          type="ghost"
                          icon={<PlusCircleOutlined />}
                        >
                          เพิ่มข้อมูลปฏิบัติธรรมออนไลน์
                        </Button>
                      </Col>
                    </Row>
                  </>
                }
              >
                <Table
                  sticky
                  dataSource={allData}
                  columns={columns}
                  onChange={handleChange}
                  scroll={{ x: 4800 }}
                  rowKey="dharma_online_id"
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "30"],
                  }}
                />
              </Card>
              <Modal
                title="เพิ่มข้อมูลปฏิบัติธรรมออนไลน์"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={() => {
                  form.resetFields();
                  setIsModalVisible(false);
                  setImageFiles(null);
                  setImageShow(null);
                }}
                width={"80%"}
                confirmLoading={loading}
                closable={false}
                maskClosable={false}
                footer={[
                  <Button
                    icon={<CloseCircleOutlined />}
                    key="back"
                    onClick={() => {
                      form.resetFields();
                      setIsModalVisible(false);
                      setImageFiles(null);
                      setImageShow(null);
                    }}
                    disabled={loading}
                  >
                    ยกเลิก
                  </Button>,
                  <Button
                    icon={<CheckCircleOutlined />}
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={handleOk}
                  >
                    ยืนยันเพิ่มข้อมูล
                  </Button>,
                ]}
              >
                <>
                  <Form
                    {...formItemLayout}
                    layout={formLayout}
                    form={form}
                    initialValues={{
                      layout: "vertical",
                      isActive: true,
                    }}
                  >
                    {ImageShow !== null && (
                      <center>
                        <Image
                          src={ImageShow}
                          style={{
                            width: 320,
                            height: 180,
                            backgroundColor: "#CCCCCC",
                            objectFit: "cover",
                          }}
                        />
                      </center>
                    )}
                    <Form.Item
                      label="ภาพปกกิจกรรม"
                      name="image"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาเลือกภาพปกกิจกรรม!",
                        },
                      ]}
                      extra="ภาพปกกิจกรรมจะแสดงสมบูรณ์ด้วยอัตราส่วน 16:9 "
                    >
                      <Input
                        type={"file"}
                        placeholder="ระบุไฟล์ภาพปกกิจกรรม"
                        accept="image/jpeg, image/png"
                        onChange={onImageChange}
                      />
                    </Form.Item>
                    <Form.Item
                      label="หัวข้อกิจกรรม"
                      name="name"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุข้อกิจกรรม!",
                        },
                      ]}
                    >
                      <Input placeholder="ระบุหัวข้อกิจกรรม" />
                    </Form.Item>
                    <Form.Item
                      label="ลิ้งเข้าออนไลน์"
                      name="link"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุลิ้งเข้าออนไลน์!",
                        },
                      ]}
                    >
                      <Input placeholder="ระบุลิ้งเข้าออนไลน์" />
                    </Form.Item>
                    <Form.Item label="ช่องทางอื่น (ถ้ามี)" name="channel">
                      <Input placeholder="ระบุช่องทางอื่น (ถ้ามี) เช่น GoogleMeet, Zoom, Microsoft Team, FacebookLive" />
                    </Form.Item>
                    <Form.Item label="ไอดีห้อง (ถ้ามี)" name="roomId">
                      <Input placeholder="ระบุไอดีห้อง (ถ้ามี)" />
                    </Form.Item>
                    <Form.Item label="รหัสเข้าห้อง (ถ้ามี)" name="roomPassword">
                      <Input placeholder="ระบุรหัสเข้าห้อง (ถ้ามี)" />
                    </Form.Item>
                    <Form.Item
                      label="วันเวลาออนไลน์ เริ่มต้น - สิ้นสุด"
                      name="onlineTime"
                      required
                      rules={[
                        {
                          required: true,
                          message:
                            "กรุณาระบุวันเวลาออนไลน์ เริ่มต้น - สิ้นสุด!",
                        },
                      ]}
                    >
                      <RangePicker
                        placeholder={["วันเวลาเริ่มต้น", "วันเวลาสิ้นสุด"]}
                        showTime={{
                          format: "HH:mm",
                        }}
                        format="YYYY-MM-DD HH:mm"
                        onChange={onChangeOnlineTime}
                        onOk={onOkOnlineTime}
                      />
                    </Form.Item>
                    <Form.Item
                      label="ตั้งค่าวันเริ่มต้น-สิ้นสุดข่าว"
                      name="showTime"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุตั้งค่าวันเริ่มต้น-สิ้นสุดข่าว!",
                        },
                      ]}
                    >
                      <RangePicker
                        showTime={{
                          format: "HH:mm",
                        }}
                        placeholder={["วันเวลาเริ่มต้น", "วันเวลาสิ้นสุด"]}
                        format="YYYY-MM-DD HH:mm"
                        onChange={onChangeOnlineTime}
                        onOk={onOkOnlineTime}
                      />
                    </Form.Item>
                    <Form.Item
                      label="วิปัสสนาจารย์ 1"
                      name="teacher1_by"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุวิปัสสนาจารย์ 1!",
                        },
                      ]}
                    >
                      <Select placeholder="เลือกวิปัสสนาจารย์ 1">
                        {allTeacher.map((v) => (
                          <Select.Option key={v.id} value={v.id}>
                            {v.firstName} {v.nickname} {v.lastName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="วิปัสสนาจารย์ 2" name="teacher2_by">
                      <Select placeholder="เลือกวิปัสสนาจารย์ 2">
                        {allTeacher.map((v) => (
                          <Select.Option key={v.id} value={v.id}>
                            {v.firstName} {v.nickname} {v.lastName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="วิปัสสนาจารย์ 3" name="teacher3_by">
                      <Select placeholder="เลือกวิปัสสนาจารย์ 3">
                        {allTeacher.map((v) => (
                          <Select.Option key={v.id} value={v.id}>
                            {v.firstName} {v.nickname} {v.lastName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="วิปัสสนาจารย์ 4" name="teacher4_by">
                      <Select placeholder="เลือกวิปัสสนาจารย์ 4">
                        {allTeacher.map((v) => (
                          <Select.Option key={v.id} value={v.id}>
                            {v.firstName} {v.nickname} {v.lastName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="วิปัสสนาจารย์ 5" name="teacher5_by">
                      <Select placeholder="เลือกวิปัสสนาจารย์ 5">
                        {allTeacher.map((v) => (
                          <Select.Option key={v.id} value={v.id}>
                            {v.firstName} {v.nickname} {v.lastName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="รายละเอียดเพิ่มเติม" name="detail">
                      <TextArea
                        rows={4}
                        placeholder="ระบุรายละเอียดเพิ่มเติม"
                      />
                    </Form.Item>
                    <Form.Item
                      valuePropName="checked"
                      label="เปิด/ปิดการใช้งาน"
                      name="isActive"
                    >
                      <Switch
                        checkedChildren="เปิด "
                        unCheckedChildren="ปิด "
                        defaultChecked
                      />
                    </Form.Item>
                  </Form>
                </>
              </Modal>

              <Modal
                title="แก้ไขข้อมูลปฏิบัติธรรมออนไลน์"
                visible={isModalEditVisible}
                onOk={handleEditOk}
                onCancel={() => {
                  formEdit.resetFields();
                  setIsModalEditVisible(false);
                  setImageFiles(null);
                  setImageShow(null);
                }}
                width={"80%"}
                confirmLoading={loading}
                closable={false}
                maskClosable={false}
                footer={[
                  <Button
                    icon={<CloseCircleOutlined />}
                    key="back"
                    onClick={() => {
                      formEdit.resetFields();
                      setIsModalEditVisible(false);
                      setImageFiles(null);
                      setImageShow(null);
                    }}
                    disabled={loading}
                  >
                    ยกเลิก
                  </Button>,
                  <Button
                    icon={<CheckCircleOutlined />}
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={handleEditOk}
                  >
                    ยืนยันแก้ไขข้อมูล
                  </Button>,
                ]}
              >
                <>
                  <Form
                    {...formItemLayout}
                    layout={formLayout}
                    form={formEdit}
                    initialValues={{
                      layout: "vertical",
                      role: "นิสิต",
                      isActive: true,
                    }}
                  >
                    {ImageShow !== null && (
                      <center>
                        <Image
                          src={ImageShow}
                          style={{
                            width: 320,
                            height: 180,
                            backgroundColor: "#CCCCCC",
                            objectFit: "cover",
                          }}
                        />
                      </center>
                    )}
                    <Form.Item label="ภาพปกกิจกรรม" name="image">
                      <Input
                        type={"file"}
                        placeholder="ระบุไฟล์ภาพปกกิจกรรม"
                        accept="image/jpeg, image/png"
                        onChange={onImageChange}
                      />
                    </Form.Item>
                    <Form.Item
                      label="หัวข้อกิจกรรม"
                      name="name"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุข้อกิจกรรม!",
                        },
                      ]}
                    >
                      <Input placeholder="ระบุหัวข้อกิจกรรม" />
                    </Form.Item>
                    <Form.Item
                      label="ลิ้งเข้าออนไลน์"
                      name="link"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุลิ้งเข้าออนไลน์!",
                        },
                      ]}
                    >
                      <Input placeholder="ระบุลิ้งเข้าออนไลน์" />
                    </Form.Item>
                    <Form.Item label="ช่องทางอื่น (ถ้ามี)" name="channel">
                      <Input placeholder="ระบุช่องทางอื่น (ถ้ามี) เช่น GoogleMeet, Zoom, Microsoft Team, FacebookLive" />
                    </Form.Item>
                    <Form.Item label="ไอดีห้อง (ถ้ามี)" name="roomId">
                      <Input placeholder="ระบุไอดีห้อง (ถ้ามี)" />
                    </Form.Item>
                    <Form.Item label="รหัสเข้าห้อง (ถ้ามี)" name="roomPassword">
                      <Input placeholder="ระบุรหัสเข้าห้อง (ถ้ามี)" />
                    </Form.Item>
                    <Form.Item
                      label="วันเวลาออนไลน์ เริ่มต้น - สิ้นสุด"
                      name="onlineTime"
                      required
                      rules={[
                        {
                          required: true,
                          message:
                            "กรุณาระบุวันเวลาออนไลน์ เริ่มต้น - สิ้นสุด!",
                        },
                      ]}
                    >
                      <RangePicker
                        placeholder={["วันเวลาเริ่มต้น", "วันเวลาสิ้นสุด"]}
                        showTime={{
                          format: "HH:mm",
                        }}
                        format="YYYY-MM-DD HH:mm"
                        onChange={onChangeOnlineTime}
                        onOk={onOkOnlineTime}
                      />
                    </Form.Item>
                    <Form.Item
                      label="ตั้งค่าวันเริ่มต้น-สิ้นสุดข่าว"
                      name="showTime"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุตั้งค่าวันเริ่มต้น-สิ้นสุดข่าว!",
                        },
                      ]}
                    >
                      <RangePicker
                        showTime={{
                          format: "HH:mm",
                        }}
                        placeholder={["วันเวลาเริ่มต้น", "วันเวลาสิ้นสุด"]}
                        format="YYYY-MM-DD HH:mm"
                        onChange={onChangeOnlineTime}
                        onOk={onOkOnlineTime}
                      />
                    </Form.Item>
                    <Form.Item
                      label="วิปัสสนาจารย์ 1"
                      name="teacher1_by"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุวิปัสสนาจารย์ 1!",
                        },
                      ]}
                    >
                      <Select placeholder="เลือกวิปัสสนาจารย์ 1">
                        {allTeacher.map((v) => (
                          <Select.Option key={v.id} value={v.id}>
                            {v.firstName} {v.nickname} {v.lastName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="วิปัสสนาจารย์ 2" name="teacher2_by">
                      <Select placeholder="เลือกวิปัสสนาจารย์ 2">
                        <Select.Option value={0}>
                          เลือกวิปัสสนาจารย์ 2
                        </Select.Option>
                        {allTeacher.map((v) => (
                          <Select.Option key={v.id} value={v.id}>
                            {v.firstName} {v.nickname} {v.lastName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="วิปัสสนาจารย์ 3" name="teacher3_by">
                      <Select placeholder="เลือกวิปัสสนาจารย์ 3">
                        <Select.Option value={0}>
                          เลือกวิปัสสนาจารย์ 3
                        </Select.Option>
                        {allTeacher.map((v) => (
                          <Select.Option key={v.id} value={v.id}>
                            {v.firstName} {v.nickname} {v.lastName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="วิปัสสนาจารย์ 4" name="teacher4_by">
                      <Select placeholder="เลือกวิปัสสนาจารย์ 4">
                        <Select.Option value={0}>
                          เลือกวิปัสสนาจารย์ 4
                        </Select.Option>
                        {allTeacher.map((v) => (
                          <Select.Option key={v.id} value={v.id}>
                            {v.firstName} {v.nickname} {v.lastName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="วิปัสสนาจารย์ 5" name="teacher5_by">
                      <Select placeholder="เลือกวิปัสสนาจารย์ 5">
                        <Select.Option value={0}>
                          เลือกวิปัสสนาจารย์ 5
                        </Select.Option>
                        {allTeacher.map((v) => (
                          <Select.Option key={v.id} value={v.id}>
                            {v.firstName} {v.nickname} {v.lastName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="รายละเอียดเพิ่มเติม" name="detail">
                      <TextArea
                        rows={4}
                        placeholder="ระบุรายละเอียดเพิ่มเติม"
                      />
                    </Form.Item>
                    <Form.Item
                      valuePropName="checked"
                      label="เปิด/ปิดการใช้งาน"
                      name="isActive"
                    >
                      <Switch
                        checkedChildren="เปิด "
                        unCheckedChildren="ปิด "
                        defaultChecked
                      />
                    </Form.Item>
                  </Form>
                </>
              </Modal>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default DharmaOnlineData;
