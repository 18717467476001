import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Input,
  Space,
  Modal,
  Form,
  Switch,
  Typography,
  Image,
  DatePicker,
} from "antd";
import {
  SearchOutlined,
  PlusCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { AdminChecker } from "../utils/AdminChecker";
import {
  getAllBanner,
  createBanner,
  updateBanner,
} from "../services/BannerService";
import { getAllDatas } from "../services/TeacherService";
import moment from "moment";

const { TextArea } = Input;
const { RangePicker } = DatePicker;

function Banner() {
  AdminChecker();
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);

  const [ImageFiles, setImageFiles] = useState(null);
  const [ImageShow, setImageShow] = useState(null);

  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [editId, setEditId] = useState(-1);
  const [editPassword, setEditPassword] = useState(-1);

  const [form] = Form.useForm();
  const [formEdit] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");

  useEffect(() => {
    // todo
    // get all User to checker
    initAllData();
  }, []);

  const initAllData = async () => {
    const res = await getAllBanner();
    if (!res._error) {
      setAllData(res.data);
    }
  };

  const handleSwitch = async (values, e) => {
    const formData = new FormData();
    formData.append("id", values.banner_id);
    formData.append("name", values.banner_name);
    formData.append("link", values.banner_link);
    formData.append("startShowTime", values.banner_startShowTime);
    formData.append("endShowTime", values.banner_endShowTime);
    formData.append("isActive", e);
    const res = await updateBanner(formData);
    var newData = res.data[0];
    if (!res._error) {
      const updateData = allData.map((item) => {
        if (item.banner_id === values.banner_id) {
          var userActive = false;
          if (e === true || e === 1) {
            userActive = true;
          }
          return { ...item, ...newData, banner_isActive: userActive };
        }
        return item;
      });
      setAllData(updateData);
    }
  };

  const openEdit = (data) => {
    setEditId(data.banner_id);
    setImageShow(data.banner_image);
    var userActive = false;
    if (data.banner_isActive === true || data.banner_isActive === 1) {
      userActive = true;
    }
    const values = {
      layout: "vertical",
      name: data.banner_name,
      link: data.banner_link === "null" ? "" : data.banner_link,
      showTime: [
        moment(data.banner_startShowTime),
        moment(data.banner_endShowTime),
      ],
      isActive: userActive,
    };
    formEdit.setFieldsValue(values);
    setIsModalEditVisible(true);
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            span: 6,
          },
          wrapperCol: {
            span: 18,
          },
        }
      : null;

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const renderAvatar = (avatar) => {
    if (avatar) {
      return (
        <Image
          style={{
            width: 200,
            height: 200 / 4,
            backgroundColor: "#CCCCCC",
            objectFit: "cover",
          }}
          src={avatar}
        />
      );
    }
    return (
      <div
        style={{
          width: 200,
          height: 200 / 4,
          backgroundColor: "#CCCCCC",
        }}
        icon={<UserOutlined />}
      />
    );
  };
  const columns = [
    {
      title: "ภาพแบนเนอร์",
      dataIndex: "banner_image",
      width: 220,
      render: (_, record) =>
        allData.length >= 1 ? renderAvatar(record.banner_image) : null,
    },
    {
      title: "รหัส",
      width: 150,
      dataIndex: "banner_id",
      key: "banner_id",
      ...getColumnSearchProps("banner_id"),
      sorter: (a, b) => a.banner_id - b.banner_id,
      sortOrder: sortedInfo.columnKey === "banner_id" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ชื่อ",
      dataIndex: "banner_name",
      key: "banner_name",
      ...getColumnSearchProps("banner_name"),
      sorter: (a, b) => a.banner_name.localeCompare(b.banner_name),
      sortOrder:
        sortedInfo.columnKey === "banner_name" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ลิ้ง",
      dataIndex: "banner_link",
      key: "banner_link",
      ...getColumnSearchProps("banner_link"),
      sorter: (a, b) => a.banner_link.localeCompare(b.banner_link),
      sortOrder:
        sortedInfo.columnKey === "banner_link" ? sortedInfo.order : null,
      ellipsis: true,
      render: (_, record) => {
        if (!record.banner_link || record.banner_link === "null") {
          return "-";
        }
        return (
          <a target="_blank" href={record.banner_link}>
            {record.banner_link}
          </a>
        );
      },
    },
    {
      title: "วันเวลาเริ่มแสดงข้อมูล",
      dataIndex: "banner_startShowTime",
      key: "banner_startShowTime",
      ...getColumnSearchProps("banner_startShowTime"),
      sorter: (a, b) =>
        a.banner_startShowTime.localeCompare(b.banner_startShowTime),
      sortOrder:
        sortedInfo.columnKey === "banner_startShowTime"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        moment(record.banner_startShowTime).format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "วันเวลาสิ้นสุดแสดงข้อมูล",
      dataIndex: "banner_endShowTime",
      key: "banner_endShowTime",
      ...getColumnSearchProps("banner_endShowTime"),
      sorter: (a, b) =>
        a.banner_endShowTime.localeCompare(b.banner_endShowTime),
      sortOrder:
        sortedInfo.columnKey === "banner_endShowTime" ? sortedInfo.order : null,
      ellipsis: true,
      render: (_, record) =>
        moment(record.banner_endShowTime).format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "สถานะการใช้งาน",
      dataIndex: "banner_isActive",
      sorter: (a, b) =>
        JSON.stringify(a.banner_isActive).localeCompare(
          JSON.stringify(b.banner_isActive)
        ),
      sortOrder:
        sortedInfo.columnKey === "banner_isActive" ? sortedInfo.order : null,
      ellipsis: true,
      width: 120,
      render: (_, record) =>
        allData.length >= 1 ? (
          <Switch
            checkedChildren="เปิด "
            unCheckedChildren="ปิด "
            checked={record.banner_isActive}
            onChange={(e) => handleSwitch(record, e)}
          />
        ) : null,
    },
    {
      title: "แก้ไข",
      dataIndex: "operation",
      fixed: "right",
      width: 120,
      render: (_, record) =>
        allData.length >= 1 ? (
          <>
            <Typography.Link
              onClick={() => openEdit(record)}
              style={{ marginLeft: 6 }}
            >
              {"แก้ไข"}
            </Typography.Link>
          </>
        ) : null,
    },
  ];

  const handleOk = () => {
    setLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        const formData = new FormData();
        formData.append("file", ImageFiles);
        formData.append("name", values.name);
        formData.append("link", values.link || "");
        formData.append("startShowTime", values.showTime[0]._d.toJSON());
        formData.append("endShowTime", values.showTime[1]._d.toJSON());
        formData.append("isActive", values.isActive);
        const res = await createBanner(formData);
        if (!res._error) {
          initAllData();
          setIsModalVisible(false);
          setImageFiles(null);
          setImageShow(null);
          form.resetFields();
        }
        setLoading(false);
      })
      .catch((info) => {
        setLoading(false);
      });
  };
  const handleEditOk = () => {
    setLoading(true);
    formEdit
      .validateFields()
      .then(async (values) => {
        const formData = new FormData();
        formData.append("file", ImageFiles);
        formData.append("id", editId);
        formData.append("name", values.name);
        formData.append("link", values.link || "");
        formData.append("startShowTime", values.showTime[0]._d.toJSON());
        formData.append("endShowTime", values.showTime[1]._d.toJSON());
        formData.append("isActive", values.isActive);
        const res = await updateBanner(formData);
        var newData = res.data[0];
        if (!res._error) {
          const updateData = allData.map((item) => {
            if (item.banner_id === editId) {
              return {
                ...item,
                ...newData,
              };
            }
            return item;
          });
          setAllData(updateData);
          setIsModalEditVisible(false);
          setImageFiles(null);
          setImageShow(null);
          formEdit.resetFields();
        }
        setLoading(false);
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageFiles(event.target.files[0]);
      setImageShow(URL.createObjectURL(event.target.files[0]));
    }
  };

  const onChangeOnlineTime = (value, dateString) => {
    // console.log("Selected Time: ", value);
    // console.log("Formatted Selected Time: ", dateString);
  };
  const onOkOnlineTime = (value) => {
    // console.log("onOk: ", value);
  };

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24}>
          <Row gutter={[24, 0]}>
            <Col xs={24} className="mb-24">
              <Card
                className="header-solid h-full ant-card-p-0"
                title={
                  <>
                    <Row
                      gutter={[24, 0]}
                      className="ant-row-flex ant-row-flex-middle"
                    >
                      <Col xs={24} md={12}>
                        <h6 className="font-semibold m-0">ข้อมูลแบนเนอร์</h6>
                      </Col>
                      <Col xs={24} md={12} className="d-flex">
                        <Button
                          onClick={() => setIsModalVisible(true)}
                          type="ghost"
                          icon={<PlusCircleOutlined />}
                        >
                          เพิ่มข้อมูลแบนเนอร์
                        </Button>
                      </Col>
                    </Row>
                  </>
                }
              >
                <Table
                  sticky
                  dataSource={allData}
                  columns={columns}
                  onChange={handleChange}
                  scroll={{ x: "max-content" }}
                  rowKey="dharma_online_id"
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "30"],
                  }}
                />
              </Card>
              <Modal
                title="เพิ่มข้อมูลแบนเนอร์"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={() => {
                  form.resetFields();
                  setIsModalVisible(false);
                  setImageFiles(null);
                  setImageShow(null);
                }}
                width={"80%"}
                confirmLoading={loading}
                closable={false}
                maskClosable={false}
                footer={[
                  <Button
                    icon={<CloseCircleOutlined />}
                    key="back"
                    onClick={() => {
                      form.resetFields();
                      setIsModalVisible(false);
                      setImageFiles(null);
                      setImageShow(null);
                    }}
                    disabled={loading}
                  >
                    ยกเลิก
                  </Button>,
                  <Button
                    icon={<CheckCircleOutlined />}
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={handleOk}
                  >
                    ยืนยันเพิ่มข้อมูล
                  </Button>,
                ]}
              >
                <>
                   <Form
                    {...formItemLayout}
                    layout={formLayout}
                    form={form}
                    initialValues={{
                      layout: "vertical",
                      isActive: true,
                    }}
                  >
                    {ImageShow !== null && (
                      <center>
                        <Image
                          src={ImageShow}
                          style={{
                            width: 320,
                            height: 320 / 4,
                            backgroundColor: "#CCCCCC",
                            objectFit: "cover",
                          }}
                        />
                      </center>
                    )}
                    <Form.Item
                      label="ภาพแบนเนอร์"
                      name="image"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาเลือกภาพแบนเนอร์!",
                        },
                      ]}
                      extra="ภาพแบนเนอร์จะแสดงสมบูรณ์ด้วยอัตราส่วน 4:1 "
                    >
                      <Input
                        type={"file"}
                        placeholder="ระบุไฟล์ภาพแบนเนอร์"
                        accept="image/jpeg, image/png"
                        onChange={onImageChange}
                      />
                    </Form.Item>
                    <Form.Item
                      label="ชื่อ"
                      name="name"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุชื่อ!",
                        },
                      ]}
                    >
                      <Input placeholder="ระบุชื่อ" />
                    </Form.Item>
                    <Form.Item label="ลิ้ง (ถ้ามี)" name="link">
                      <Input placeholder="ระบุลิ้ง (ถ้ามี)" />
                    </Form.Item>
                    <Form.Item
                      label="ตั้งค่าวันเริ่มต้น-สิ้นสุดข่าว"
                      name="showTime"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุตั้งค่าวันเริ่มต้น-สิ้นสุดข่าว!",
                        },
                      ]}
                    >
                      <RangePicker
                        showTime={{
                          format: "HH:mm",
                        }}
                        placeholder={["วันเวลาเริ่มต้น", "วันเวลาสิ้นสุด"]}
                        format="YYYY-MM-DD HH:mm"
                        onChange={onChangeOnlineTime}
                        onOk={onOkOnlineTime}
                      />
                    </Form.Item>
                    <Form.Item
                      valuePropName="checked"
                      label="เปิด/ปิดการใช้งาน"
                      name="isActive"
                    >
                      <Switch
                        checkedChildren="เปิด "
                        unCheckedChildren="ปิด "
                        defaultChecked
                      />
                    </Form.Item>
                  </Form>
                </>
              </Modal>

              <Modal
                title="แก้ไขข้อมูลแบนเนอร์"
                visible={isModalEditVisible}
                onOk={handleEditOk}
                onCancel={() => {
                  formEdit.resetFields();
                  setIsModalEditVisible(false);
                  setImageFiles(null);
                  setImageShow(null);
                }}
                width={"80%"}
                confirmLoading={loading}
                closable={false}
                maskClosable={false}
                footer={[
                  <Button
                    icon={<CloseCircleOutlined />}
                    key="back"
                    onClick={() => {
                      formEdit.resetFields();
                      setIsModalEditVisible(false);
                      setImageFiles(null);
                      setImageShow(null);
                    }}
                    disabled={loading}
                  >
                    ยกเลิก
                  </Button>,
                  <Button
                    icon={<CheckCircleOutlined />}
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={handleEditOk}
                  >
                    ยืนยันแก้ไขข้อมูล
                  </Button>,
                ]}
              >
                <>
                  <Form
                    {...formItemLayout}
                    layout={formLayout}
                    form={formEdit}
                    initialValues={{
                      layout: "vertical",
                      isActive: true,
                    }}
                  >
                    {ImageShow !== null && (
                      <center>
                        <Image
                          src={ImageShow}
                          style={{
                            width: 320,
                            height: 320 / 4,
                            backgroundColor: "#CCCCCC",
                            objectFit: "cover",
                          }}
                        />
                      </center>
                    )}
                    <Form.Item
                      label="ภาพแบนเนอร์"
                      name="image"
                      extra="ภาพแบนเนอร์จะแสดงสมบูรณ์ด้วยอัตราส่วน 4:1 "
                    >
                      <Input
                        type={"file"}
                        placeholder="ระบุไฟล์ภาพแบนเนอร์"
                        accept="image/jpeg, image/png"
                        onChange={onImageChange}
                      />
                    </Form.Item>
                    <Form.Item
                      label="ชื่อ"
                      name="name"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุชื่อ!",
                        },
                      ]}
                    >
                      <Input placeholder="ระบุชื่อ" />
                    </Form.Item>
                    <Form.Item label="ลิ้ง (ถ้ามี)" name="link">
                      <Input placeholder="ระบุลิ้ง (ถ้ามี)" />
                    </Form.Item>
                    <Form.Item
                      label="ตั้งค่าวันเริ่มต้น-สิ้นสุดข่าว"
                      name="showTime"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุตั้งค่าวันเริ่มต้น-สิ้นสุดข่าว!",
                        },
                      ]}
                    >
                      <RangePicker
                        showTime={{
                          format: "HH:mm",
                        }}
                        placeholder={["วันเวลาเริ่มต้น", "วันเวลาสิ้นสุด"]}
                        format="YYYY-MM-DD HH:mm"
                        onChange={onChangeOnlineTime}
                        onOk={onOkOnlineTime}
                      />
                    </Form.Item>

                    <Form.Item
                      valuePropName="checked"
                      label="เปิด/ปิดการใช้งาน"
                      name="isActive"
                    >
                      <Switch
                        checkedChildren="เปิด "
                        unCheckedChildren="ปิด "
                        defaultChecked
                      />
                    </Form.Item>
                  </Form>
                </>
              </Modal>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default Banner;
