import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Input,
  Space,
  Typography,
  Modal,
  Alert,
} from "antd";
import moment from "moment";
import { SearchOutlined, PlusCircleOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {
  findAllAdminCertificateByUserId,
  downloadAdminCertificateByUserId,
} from "../services/CertificateService";
import { getMe } from "../services/UsersServices";

function CertificateDetail({ userId, userName, fieldsLevel }) {
  const [loading, setLoading] = useState(false);
  const [allFields, setAllFields] = useState([]);
  const [userInfo, setUserInfo] = useState([]);

  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [registerData, setRegisterData] = useState([]);
  const [userRegisterData, setUserRegisterData] = useState([]);
  const [onYearName, setOnYearName] = useState("");
  const [onYearId, setOnYearId] = useState("");

  const searchInput = useRef(null);

  useEffect(() => {
    // todo
    // get all User to checker
    initAllFields();
  }, [userId]);

  const initAllFields = async () => {
    setLoading(true);
    console.log(userId);
    const res = await findAllAdminCertificateByUserId(userId);
    console.log(res);
    if (!res._error) {
      if (fieldsLevel === "ปริญญาตรี") {
        const filterCert = res.data.filter(
          (v) => v.type !== "30DAYS" && v.type !== "45DAYS"
        );
        setAllFields(filterCert);
      } else {
        const filterCert = res.data.filter(
          (v) => v.type === "30DAYS" || v.type === "45DAYS"
        );
        setAllFields(filterCert);
      }
    }
    setLoading(false);
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleDownloadCert = async (id) => {
    setLoading(true);
    await downloadAdminCertificateByUserId(userId, id);
    setLoading(false);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      width: 60,
      ellipsis: true,
      render: (_, record, index) =>
        allFields.length >= 1 ? <div>{index + 1}</div> : null,
    },
    {
      title: "ประเภทวุฒิบัตร",
      dataIndex: "type",
      key: "type",
      ...getColumnSearchProps("type"),
      sorter: (a, b) => a.type.localeCompare(b.type),
      sortOrder: sortedInfo.columnKey === "type" ? sortedInfo.order : null,
      ellipsis: true,
      render: (_, record) => {
        if (record.type === "ONYEAR") {
          return <div>{`ปฏิบัติธรรมประจำปี : ${record.dharmaOnyearName}`}</div>;
        } else if (record.type === "30DAYS") {
          return <div>{`วิปัสสนากรรมฐาน ครบ 30 วัน `}</div>;
        } else if (record.type === "45DAYS") {
          return <div>{`วิปัสสนากรรมฐาน ครบ 45 วัน `}</div>;
        } else if (record.type === "10DAYS_1") {
          return (
            <div>{`วิปัสสนากรรมฐาน ครบ 10 วัน เมื่อ ${moment(
              record.certificateDate
            ).format("DD-MM-YYYY")}`}</div>
          );
        } else if (record.type === "10DAYS_2") {
          return (
            <div>{`วิปัสสนากรรมฐาน ครบ 10 วัน เมื่อ ${moment(
              record.certificateDate
            ).format("DD-MM-YYYY")}`}</div>
          );
        } else if (record.type === "10DAYS_3") {
          return (
            <div>{`วิปัสสนากรรมฐาน ครบ 10 วัน เมื่อ ${moment(
              record.certificateDate
            ).format("DD-MM-YYYY")}`}</div>
          );
        } else if (record.type === "10DAYS_4") {
          return (
            <div>{`วิปัสสนากรรมฐาน ครบ 10 วัน เมื่อ ${moment(
              record.certificateDate
            ).format("DD-MM-YYYY")}`}</div>
          );
        }
        return <div>-</div>;
      },
    },
    {
      title: "วันที่อนุมัติผล",
      dataIndex: "certificateDate",
      key: "certificateDate",
      width: 160,
      ...getColumnSearchProps("certificateDate"),
      sorter: (a, b) => a.certificateDate.localeCompare(b.certificateDate),
      sortOrder:
        sortedInfo.columnKey === "certificateDate" ? sortedInfo.order : null,
      ellipsis: true,
      render: (_, record) => {
        return <div>{moment(record.certificateDate).format("DD-MM-YYYY")}</div>;
      },
    },
    {
      title: "ดาวน์โหลด",
      dataIndex: "dowload",
      key: "dowload",
      width: 160,
      ellipsis: true,
      render: (_, record) => {
        return (
          <Typography.Link onClick={() => handleDownloadCert(record.id)}>
            ดาวน์โหลด
          </Typography.Link>
        );
      },
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24}>
          <Row gutter={[24, 0]}>
            <Col xs={24} className="mb-24">
              <Card
                className="header-solid h-full ant-card-p-0"
                title={
                  <>
                    <Row
                      gutter={[24, 0]}
                      className="ant-row-flex ant-row-flex-middle"
                    >
                      <Col xs={24} md={12}>
                        <h6 className="font-semibold m-0">
                          รายการวุฒิบัตร {userName}
                        </h6>
                      </Col>
                    </Row>
                  </>
                }
              >
                <Table
                  sticky
                  dataSource={allFields}
                  columns={columns}
                  onChange={handleChange}
                  // scroll={{ x: 1200 }}
                  rowKey="id"
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "30"],
                  }}
                />

                <Modal
                  title={`ไม่สามารถดาวน์โหลดได้!`}
                  visible={isModalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  okText="เข้าใจแล้ว"
                  cancelText="ปิด"
                >
                  {userInfo[0] && (
                    <Alert
                      message={`ไม่สามารถดาวน์โหลดได้ เนื่องจากท่านมีจำนวนการซ่อมที่ต้องซ่อมก่อน จำนวน ${userInfo[0].users_numOfDharmaCompensate} วัน โดยจำนวนการซ่อมต้องน้อยกว่าเท่ากับ 0 จึงสามารถดาวน์โหลดได้ กรุณาลงทะเบียนปฏิบัติธรรมเพื่อแก้ไขจำนวนวันซ่อม`}
                      type="error"
                    />
                  )}
                </Modal>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default CertificateDetail;
