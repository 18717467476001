import { APPCONFIG } from "../config/app.config";
import { RunApiRefreshToken } from "../utils/RunApiRefreshToken";
import { RunApiRefreshTokenMultipart } from "../utils/RunApiRefreshTokenMultipart";
import { RunAPI } from "../utils/RunApi";

export const getAllDharmaOnyearPlublic = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onyear/public`;
  const res = await RunAPI("GET", url, {});
  return res;
};

export const getAllDharmaOnyearPlublicCount = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onyear/public-count`;
  const res = await RunAPI("GET", url, {});
  return res;
};

export const getAllDharmaOnyearWithResults = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onyear/user`;
  const res = await RunAPI("GET", url, {});
  return res;
};

export const getOneDharmaOnyearPlublicId = async (uuid) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onyear/public/${uuid}`;
  const res = await RunAPI("GET", url, {});
  return res;
};

export const userCheckRegister = async (dharmaOnyearId) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onyear/user-check-register/${dharmaOnyearId}`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};

export const userRegister = async (dharmaOnyearId) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onyear/user-register/${dharmaOnyearId}`;
  const res = await RunApiRefreshToken("POST", url, {});
  return res;
};

export const getAllDharmaOnyear = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onyear`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};

export const getConditionWithOnyearId = async (OnyearId) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onyear/condition/${OnyearId}`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};

export const getRegisterConditionWithOnyearId = async (OnyearId) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onyear/register-condition/${OnyearId}`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};

export const createDharmaOnyear = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onyear`;
  const res = await RunApiRefreshTokenMultipart("POST", url, data);
  return res;
};

export const updateDharmaOnyear = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onyear`;
  const res = await RunApiRefreshTokenMultipart("PUT", url, data);
  return res;
};

export const getRegisterUserData = async (OnyearId) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onyear/register-user-data/${OnyearId}`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};

export const getRegisterUserDataPublic = async (OnyearId) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onyear/register-user-data-public/${OnyearId}`;
  const res = await RunAPI("GET", url, {});
  return res;
};

export const removeOneRegisterUserData = async (dharma_onyear_register_id) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onyear/remove-register-user-data/${dharma_onyear_register_id}`;
  const res = await RunApiRefreshToken("PUT", url, {});
  return res;
};

export const addOneRegisterUserData = async (userId, OnyearId) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onyear/add-register-user-data/${userId}/${OnyearId}`;
  const res = await RunApiRefreshToken("POST", url, {});
  return res;
};

export const getRegisterOverall = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onyear/register-overall`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};

export const getReportOverall = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onyear/report-overall`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};

export const adminRegister = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onyear/admin-register`;
  const res = await RunApiRefreshToken("POST", url, data);
  return res;
};

export const startProcessing = async (OnyearId) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onyear/start-processing-result/${OnyearId}`;
  const res = await RunApiRefreshToken("POST", url, {});
  return res;
};

export const findAllResultReportChecker = async (OnyearId) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onyear/all-result-report-checker/${OnyearId}`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};

export const submitAllResultReportChecker = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onyear/submit-all-result-report-checker`;
  const res = await RunApiRefreshToken("PUT", url, data);
  return res;
};

export const findAllResultReportDiscussion = async (OnyearId) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onyear/all-result-report-discussion/${OnyearId}`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};

export const submitAllResultReportDiscussion = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onyear/submit-all-result-report-discussion`;
  const res = await RunApiRefreshToken("PUT", url, data);
  return res;
};

export const findAllResultsByUserId = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onyear/user-result`;
  const res = await RunApiRefreshToken("GET", url);
  return res;
};

export const checkUserCompensate = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onyear/user-compensate`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};

export const reportResultsByUserId = async (userCode) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onyear/report-user/${userCode}`;
  const res = await RunApiRefreshToken("GET", url);
  return res;
};

export const findRegisterDuplicationByYear = async (year) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onyear/report-user-register-duplication/${year}`;
  const res = await RunApiRefreshToken("GET", url);
  return res;
};

export const findUnRegisterByYear = async (year) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onyear/report-user-unregister/${year}`;
  const res = await RunApiRefreshToken("GET", url);
  return res;
};

export const downloadExcelRegister = async (OnyearId) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/dharma-onyear/download-excel-register/${OnyearId}`;
  const res = await RunApiRefreshToken(
    "DOWNLOAD",
    url,
    {},
    "dharmaOnyearRegister.xlsx"
  );
  return res;
};
