import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Input,
  Space,
  Modal,
  Form,
  Switch,
  Select,
  Typography,
  Image,
  DatePicker,
  Alert,
  Checkbox,
  Divider,
  Radio,
} from "antd";
import {
  SearchOutlined,
  EditOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {
  findAllResultsByUserId,
  userUpdateDharmaOnline,
} from "../../services/DharamaOnlineResultsService";
import moment from "moment";
import {
  walkRound,
  walkTime,
  sitRound,
  sitTime,
  speak,
  actions,
  solveProblem,
  progress,
} from "../../constants/dharmaOnline.constants";

const { TextArea } = Input;
const { Paragraph, Text, Title } = Typography;

function DharmaOnlineUserChecker() {
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);

  const [ImageFiles, setImageFiles] = useState(null);
  const [ImageShow, setImageShow] = useState(null);

  const [isDisableWalk, setIsDisableWalk] = useState(1);
  const [isDisableSit, setIsDisableSit] = useState(1);

  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState(-1);
  const [checkboxOnline, setCheckboxOnline] = useState([]);

  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");

  useEffect(() => {
    // todo
    // get all User to checker
    initAllData();
  }, []);

  const initAllData = async () => {
    const res = await findAllResultsByUserId();
    if (!res._error) {
      setAllData(res.data);
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            span: 6,
          },
          wrapperCol: {
            span: 18,
          },
        }
      : null;

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const renderStatus = (statusText, adminNote = "", datas) => {
    if (statusText === "รอตรวจสอบ") {
      return <Alert message="รอตรวจสอบ" type="warning" showIcon />;
    } else if (statusText === "อนุมัติ") {
      return (
        <>
          <Alert message="อนุมัติแล้ว" type="success" showIcon />
        </>
      );
    } else if (statusText === "ไม่อนุมัติ") {
      return (
        <>
          <Alert message="ไม่อนุมัติ" type="error" showIcon />
          {adminNote.trim().length > 0 && (
            <Alert
              style={{ whiteSpace: "pre-wrap" }}
              message={adminNote}
              type="error"
              showIcon
            />
          )}
          <Button
            onClick={() => openEdit(datas)}
            icon={<EditOutlined />}
            danger
            block
            type="primary"
            style={{ marginTop: 10 }}
          >
            แก้ไขรายงาน
          </Button>
        </>
      );
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const renderAvatar = (avatar) => {
    if (avatar) {
      return (
        <Image
          style={{
            width: 100,
            aspectRatio: "16/9",
            backgroundColor: "#CCCCCC",
            objectFit: "cover",
          }}
          src={avatar}
        />
      );
    }
    return (
      <div
        style={{
          width: 100,
          aspectRatio: "16/9",
          backgroundColor: "#CCCCCC",
        }}
        icon={<UserOutlined />}
      />
    );
  };
  const renderImageSent = (avatar) => {
    if (avatar) {
      return (
        <Image
          style={{
            width: 100,
            aspectRatio: "16/9",
            backgroundColor: "#CCCCCC",
            objectFit: "cover",
          }}
          src={avatar}
        />
      );
    }
    return (
      <div
        style={{
          width: 100,
          aspectRatio: "16/9",
          backgroundColor: "#CCCCCC",
        }}
        icon={<UserOutlined />}
      />
    );
  };
  const columns = [
    {
      title: "รหัสรายงาน",
      dataIndex: "dharma_online_results_id",
      width: 140,
      key: "dharma_online_results_id",
      ...getColumnSearchProps("dharma_online_results_id"),
      sorter: (a, b) => a.dharma_online_results_id - b.dharma_online_results_id,
      sortOrder:
        sortedInfo.columnKey === "dharma_online_results_id"
          ? sortedInfo.order
          : null,
      ellipsis: true,
    },
    {
      title: "ภาพปกกิจกรรม",
      dataIndex: "b.dharma_online_image",
      width: 140,
      render: (_, record) =>
        allData.length >= 1 ? renderAvatar(record.dharma_online_image) : null,
    },
    {
      title: "หัวข้อกิจกรรม",
      width: 220,
      dataIndex: "dharma_online_name",
      key: "dharma_online_name",
      ...getColumnSearchProps("dharma_online_name"),
      sorter: (a, b) =>
        a.dharma_online_name.localeCompare(b.dharma_online_name),
      sortOrder:
        sortedInfo.columnKey === "dharma_online_name" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "สถานะการตรวจสอบ",
      width: 240,
      dataIndex: "dharma_online_results_status",
      key: "dharma_online_results_status",
      ...getColumnSearchProps("dharma_online_results_status"),
      sorter: (a, b) =>
        a.dharma_online_results_status.localeCompare(
          b.dharma_online_results_status
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_online_results_status"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        renderStatus(
          record.dharma_online_results_status,
          record.dharma_online_results_admin_note,
          record
        ),
    },
    {
      title: "ภาพประกอบที่เข้าร่วม",
      dataIndex: "b.dharma_online_image",
      width: 140,
      render: (_, record) =>
        allData.length >= 1
          ? renderImageSent(record.dharma_online_results_image)
          : null,
    },
    {
      title: "วันเวลาที่รายงาน",
      width: 240,
      dataIndex: "dharma_online_results_created_dt",
      key: "dharma_online_results_created_dt",
      ...getColumnSearchProps("dharma_online_results_created_dt"),
      sorter: (a, b) =>
        a.dharma_online_results_created_dt.localeCompare(
          b.dharma_online_results_created_dt
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_online_results_created_dt"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        moment(record.dharma_online_results_created_dt)
          .add(543, "year")
          .format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "วันเวลาที่รายงานล่าสุด",
      width: 240,
      dataIndex: "dharma_online_results_updated_dt",
      key: "dharma_online_results_updated_dt",
      ...getColumnSearchProps("dharma_online_results_updated_dt"),
      sorter: (a, b) =>
        a.dharma_online_results_updated_dt.localeCompare(
          b.dharma_online_results_updated_dt
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_online_results_updated_dt"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        moment(record.dharma_online_results_updated_dt)
          .add(543, "year")
          .format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "วันเวลาเจ้าหน้าที่ตรวจสอบล่าสุด",
      width: 240,
      dataIndex: "dharma_online_results_admin_check_dt",
      key: "dharma_online_results_admin_check_dt",
      ...getColumnSearchProps("dharma_online_results_admin_check_dt"),
      sorter: (a, b) =>
        a.dharma_online_results_admin_check_dt.localeCompare(
          b.dharma_online_results_admin_check_dt
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_online_results_admin_check_dt"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        record.dharma_online_results_admin_check_dt
          ? moment(record.dharma_online_results_admin_check_dt)
              .add(543, "year")
              .format("DD-MM-YYYY HH:mm")
          : "-",
    },
  ];

  const openEdit = (data) => {
    // console.log(data);
    setCheckboxOnline([]);
    setEditId(data.dharma_online_results_id);
    setImageShow(data.dharma_online_results_image);
    setIsDisableWalk(data.dharma_online_results_walkRound);
    setIsDisableSit(data.dharma_online_results_sitRound);
    const actionsValue = data.dharma_online_results_actions;
    var actVal = undefined;
    if (actionsValue.trim() !== "") {
      actVal = actionsValue.trim().split(",");
      setCheckboxOnline(actVal);
    }
    const values = {
      walkRound: data.dharma_online_results_walkRound,
      walkTime: data.dharma_online_results_walkTime,
      sitRound: data.dharma_online_results_sitRound,
      sitTime: data.dharma_online_results_sitTime,
      speak: data.dharma_online_results_speak,
      actions: actVal,
      solveProblem: data.dharma_online_results_solveProblem,
      progress: data.dharma_online_results_progress,
      detail: data.dharma_online_results_detail,
    };
    // console.log(values);
    form.setFieldsValue(values);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        // console.log(values);
        var actionsValue = "";
        if (checkboxOnline && checkboxOnline.length > 0) {
          actionsValue = checkboxOnline.join();
        }
        const formData = new FormData();
        formData.append("file", ImageFiles);
        formData.append("id", editId);
        formData.append("walkRound", values.walkRound);
        formData.append("walkTime", values.walkTime);
        formData.append("sitRound", values.sitRound);
        formData.append("sitTime", values.sitTime);
        formData.append("speak", values.speak);
        formData.append("actions", actionsValue);
        formData.append("solveProblem", values.solveProblem);
        formData.append("progress", values.progress);
        formData.append("detail", values.detail || "");
        const res = await userUpdateDharmaOnline(formData);
        if (!res._error) {
          initAllData();
          setIsModalVisible(false);
          setImageFiles(null);
          setImageShow(null);
          setCheckboxOnline([]);
          form.resetFields();
        }
        setLoading(false);
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageFiles(event.target.files[0]);
      setImageShow(URL.createObjectURL(event.target.files[0]));
    }
  };

  const checkBoxHandler = (e) => {
    const valueReceive = e.target.value;
    const newData = checkboxOnline;
    const findValue = checkboxOnline.find(
      (element) => element === valueReceive.toString()
    );
    if (!findValue) {
      newData.push(valueReceive.toString());
      setCheckboxOnline(newData);
      // console.log(newData);
    } else {
      const update = checkboxOnline.filter(
        (element) => element !== valueReceive.toString()
      );
      setCheckboxOnline(update);
      // console.log(update);
    }
  };

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24}>
          <Row gutter={[24, 0]}>
            <Col xs={24} className="mb-24">
              <Card
                className="header-solid h-full ant-card-p-0"
                title={
                  <>
                    <Row
                      gutter={[24, 0]}
                      className="ant-row-flex ant-row-flex-middle"
                    >
                      <Col xs={24} md={12}>
                        <h6 className="font-semibold m-0">
                          ตรวจสอบผลรายงานปฏิบัติธรรมออนไลน์
                        </h6>
                      </Col>
                    </Row>
                  </>
                }
              >
                <Table
                  dataSource={allData}
                  columns={columns}
                  onChange={handleChange}
                  scroll={{ x: "max-content" }}
                  sticky
                  rowKey="dharma_online_results_id"
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "30"],
                  }}
                />
              </Card>
              <Modal
                title="รายงานผลการปฏิบัติธรรมออนไลน์"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={() => {
                  form.resetFields();
                  setIsModalVisible(false);
                  setImageFiles(null);
                  setImageShow(null);
                }}
                width={"80%"}
                confirmLoading={loading}
                closable={false}
                maskClosable={false}
                footer={[
                  <Button
                    icon={<CloseCircleOutlined />}
                    key="back"
                    onClick={() => {
                      form.resetFields();
                      setIsModalVisible(false);
                      setImageFiles(null);
                      setImageShow(null);
                    }}
                    disabled={loading}
                  >
                    ยกเลิก
                  </Button>,
                  <Button
                    icon={<CheckCircleOutlined />}
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={handleOk}
                  >
                    ยืนยันรายงานผลการปฏิบัติธรรมออนไลน์
                  </Button>,
                ]}
              >
                <>
                  <Form
                    layout={"vertical"}
                    form={form}
                    initialValues={{
                      layout: "vertical",
                    }}
                  >
                    {ImageShow !== null && (
                      <center>
                        <Image
                          src={ImageShow}
                          style={{
                            width: 320,
                            backgroundColor: "#CCCCCC",
                          }}
                        />
                      </center>
                    )}
                    <Form.Item
                      label="รูปภาพประกอบกิจกรรม"
                      name="image"
                      required={!ImageShow}
                      rules={[
                        {
                          required: !ImageShow,
                          message: "กรุณาเลือกภาพปกกิจกรรม!",
                        },
                      ]}
                    >
                      <Input
                        type={"file"}
                        placeholder="ระบุไฟล์ภาพปกกิจกรรม"
                        accept="image/jpeg, image/png"
                        onChange={onImageChange}
                      />
                    </Form.Item>
                    <Divider />
                    <Title level={5}>แบบบันทึกอารมย์กรรมฐานประจำวัน</Title>
                    <Form.Item
                      label="จำนวนรอบในการเดินจงกรม :"
                      name="walkRound"
                      required
                      onChange={(e) => {
                        if (e.target.value > 1) {
                          setIsDisableWalk(e.target.value);
                          form.setFieldsValue({ walkTime: 2 });
                        } else {
                          setIsDisableWalk(e.target.value);
                          form.setFieldsValue({ walkTime: 1 });
                        }
                      }}
                    >
                      <Radio.Group>
                        {walkRound.map((v) => (
                          <Radio key={v.id} value={v.id}>
                            {v.value}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item
                      label="เวลาเฉลี่ยในการเดินจงกรม :"
                      name="walkTime"
                      required
                    >
                      <Radio.Group
                        disabled={isDisableWalk === "1" || isDisableWalk === 1}
                      >
                        {walkTime.map((v) => (
                          <Radio
                            disabled={
                              v.id === 1 &&
                              (isDisableWalk !== "1" || isDisableWalk !== 1)
                            }
                            key={v.id}
                            value={v.id}
                          >
                            {v.value}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                    <Divider />
                    <Form.Item
                      label="จำนวนรอบในการนั่งสมาธิ :"
                      name="sitRound"
                      required
                      onChange={(e) => {
                        if (e.target.value > 1) {
                          setIsDisableSit(e.target.value);
                          form.setFieldsValue({ sitTime: 2 });
                        } else {
                          setIsDisableSit(e.target.value);
                          form.setFieldsValue({ sitTime: 1 });
                        }
                      }}
                    >
                      <Radio.Group>
                        {sitRound.map((v) => (
                          <Radio key={v.id} value={v.id}>
                            {v.value}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item
                      label="เวลาเฉลี่ยในการนั่งสมาธิ :"
                      name="sitTime"
                      required
                    >
                      <Radio.Group
                        disabled={isDisableSit === "1" || isDisableSit === 1}
                      >
                        {sitTime.map((v) => (
                          <Radio
                            disabled={
                              v.id === 1 &&
                              (isDisableSit !== "1" || isDisableSit !== 1)
                            }
                            key={v.id}
                            value={v.id}
                          >
                            {v.value}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                    <Divider />
                    <Form.Item label="การปิดวาจา :" name="speak" required>
                      <Radio.Group>
                        {speak.map((v) => (
                          <Radio key={v.id} value={v.id}>
                            {v.value}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                    <Divider />
                    <Form.Item
                      label="สภาวะการปฏิบัติประจำวัน : ท่านพบสภาวะอย่างไรบ้าง (ตอบได้มากกว่า 1 ข้อ)"
                      name="actions"
                    >
                      <Row gutter={[24, 0]}>
                        {actions.map((v) => (
                          <Col key={v.id} md={6} xs={12}>
                            <Checkbox
                              onChange={(e) => checkBoxHandler(e)}
                              value={v.id}
                              style={{ lineHeight: "32px" }}
                              defaultChecked={checkboxOnline.find(
                                (c) => c.toString() === v.id.toString()
                              )}
                            >
                              {v.value}
                            </Checkbox>
                          </Col>
                        ))}
                      </Row>
                    </Form.Item>
                    <Divider />
                    <Form.Item
                      label="เมื่อเกิดปัญหาหรือข้อสงสัย :"
                      name="solveProblem"
                      required
                    >
                      <Radio.Group>
                        {solveProblem.map((v) => (
                          <Radio key={v.id} value={v.id}>
                            {v.value}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                    <Divider />
                    <Form.Item
                      label="การปฏิบัติประจำวันมีความก้าวหน้าหรือไม่ :"
                      name="progress"
                      required
                    >
                      <Radio.Group>
                        {progress.map((v) => (
                          <Radio key={v.id} value={v.id}>
                            {v.value}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                    <Divider />
                    <Form.Item
                      label="สรุปการปฏิบัติธรรม/ข้อสงสัย/ข้อเสนอแนะ ของท่านในการปฏิบัติธรรมครั้งนี้"
                      name="detail"
                      rules={[
                        {
                          required: true,
                          message: "กรุณาสรุปการปฏิบัติธรรม/ข้อสงสัย/ข้อเสนอแนะ ของท่านในการปฏิบัติธรรมครั้งนี้!",
                        },
                      ]}
                    >
                      <TextArea rows={2} />
                    </Form.Item>
                  </Form>
                </>
              </Modal>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default DharmaOnlineUserChecker;
