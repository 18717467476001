import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Input,
  Space,
  Typography,
  Popconfirm,
  Modal,
  Alert,
} from "antd";

import { SearchOutlined, CheckCircleOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {
  getRegisterUserData,
  removeOneRegisterUserData,
  addOneRegisterUserData,
} from "../../../services/DharmaOnyearService";
import { getAllUsers } from "../../../services/UsersServices";

const { Search } = Input;

export default function DhmOnYearRegisterPb({ onYearId, userRegisterDatas }) {
  //console.log(userRegisterDatas);

  const [userRegisterData, setUserRegisterData] = useState(userRegisterDatas);
  const [allUsers, setAllUsers] = useState([]);
  const [srcUserSelectedId, setSrcUserSelectedId] = useState(0);
  const [srcUserSelectedCode, setSrcUserSelectedCode] = useState("");
  const [userFound, setUserFound] = useState("");
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const [isMaxSeats, setIsMaxSeats] = useState("");
  const [maxSeatsText, setMaxSeatsText] = useState("");

  const searchInput = useRef(null);

  useEffect(() => {
    // initAllUsers();
  }, []);

  const initAllUsers = async () => {
    // const res = await getAllUsers();
    // if (!res._error) {
    //   setAllUsers(res.data);
    // }
  };

  useEffect(() => {
    setUserRegisterData(userRegisterDatas);
  }, [userRegisterDatas]);

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  //   const addUserFinding = (key) => {
  //     setSrcUserSelectedCode(key);
  //     if (key.length > 0) {
  //       const find = allUsers.filter((v) => v.users_code === key);
  //       if (find.length > 0) {
  //         const data = find[0];
  //         const check = userRegisterData.filter(
  //           (c) => c.users_id === data.users_id
  //         );
  //         if (check.length > 0) {
  //           setUserFound(
  //             `${data.prefixs_name}${data.users_firstName} ${data.users_lastName} ${data.fields_level} ${data.fields_name} ได้ลงทะเบียนอยู่แล้ว`
  //           );
  //         } else {
  //           setUserFound(
  //             `${data.prefixs_name}${data.users_firstName} ${data.users_lastName} ${data.fields_level} ${data.fields_name}`
  //           );
  //           setSrcUserSelectedId(data.users_id);
  //         }
  //       } else {
  //         setUserFound("");
  //       }
  //     }
  //   };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleDelete = async (key) => {
    const remove = await removeOneRegisterUserData(key);
    if (!remove._error) {
      const newData = userRegisterData.filter(
        (item) => item.dharma_onyear_register_id !== key
      );
      setUserRegisterData(newData);
    }
  };

  const columns = [
    {
      title: "#",
      width: 100,
      dataIndex: "index",
      key: "index",
      sorter: (a, b) => a.index - b.index,
      sortOrder: sortedInfo.columnKey === "index" ? sortedInfo.order : null,
      ellipsis: true,
      render: (_, record, index) => index + 1,
    },
    {
      title: "รหัสนักศึกษา",
      dataIndex: "users_code",
      key: "users_code",
      ...getColumnSearchProps("users_code"),
      sorter: (a, b) => a.users_code - b.users_code,
      sortOrder:
        sortedInfo.columnKey === "users_code" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "คำนำหน้า",
      dataIndex: "prefixs_name",
      key: "prefixs_name",
      ...getColumnSearchProps("prefixs_name"),
      sorter: (a, b) => a.prefixs_name.localeCompare(b.prefixs_name),
      sortOrder:
        sortedInfo.columnKey === "prefixs_name" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ชื่อ",
      dataIndex: "users_firstName",
      key: "users_firstName",
      ...getColumnSearchProps("users_firstName"),
      sorter: (a, b) => a.users_firstName.localeCompare(b.users_firstName),
      sortOrder:
        sortedInfo.columnKey === "users_firstName" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ฉายา",
      dataIndex: "users_nickname",
      key: "users_nickname",
      ...getColumnSearchProps("users_nickname"),
      sorter: (a, b) => a.users_nickname.localeCompare(b.users_nickname),
      sortOrder:
        sortedInfo.columnKey === "users_nickname" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "นามสกุล",
      dataIndex: "users_lastName",
      key: "users_lastName",
      ...getColumnSearchProps("users_lastName"),
      sorter: (a, b) => a.users_lastName - b.users_lastName,
      sortOrder:
        sortedInfo.columnKey === "users_lastName" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ระดับ",
      dataIndex: "fields_level",
      key: "fields_level",
      ...getColumnSearchProps("fields_level"),
      sorter: (a, b) => a.fields_level - b.fields_level,
      sortOrder:
        sortedInfo.columnKey === "fields_level" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "สาขา",
      dataIndex: "fields_name",
      key: "fields_name",
      ...getColumnSearchProps("fields_name"),
      sorter: (a, b) => a.fields_name - b.fields_name,
      sortOrder:
        sortedInfo.columnKey === "fields_name" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ประเภท",
      dataIndex: "fields_type",
      key: "fields_type",
      ...getColumnSearchProps("fields_type"),
      sorter: (a, b) => a.fields_type - b.fields_type,
      sortOrder:
        sortedInfo.columnKey === "fields_type" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ประเภทนักศึกษา",
      dataIndex: "graduate_types_name",
      key: "graduate_types_name",
      ...getColumnSearchProps("graduate_types_name"),
      sorter: (a, b) => a.graduate_types_name - b.graduate_types_name,
      sortOrder:
        sortedInfo.columnKey === "graduate_types_name"
          ? sortedInfo.order
          : null,
      ellipsis: true,
    },
  ];

  return (
    <div>
      <Table
        sticky
        dataSource={userRegisterData}
        columns={columns}
        onChange={handleChange}
        scroll={{ x: 1800 }}
        rowKey="users_code"
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30"],
        }}
      />
      <Modal
        title={`แจ้งเตือน! เกิดข้อผิดพลาด!`}
        visible={isMaxSeats}
        onCancel={() => {
          setIsMaxSeats(false);
          setMaxSeatsText("");
        }}
        footer={[
          <Button
            icon={<CheckCircleOutlined />}
            key="submit"
            type="primary"
            onClick={() => {
              setIsMaxSeats(false);
              setMaxSeatsText("");
            }}
          >
            เข้าใจแล้ว
          </Button>,
        ]}
      >
        <Alert message={maxSeatsText} type="warning" />
      </Modal>
    </div>
  );
}
