import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Input,
  Space,
  Form,
  Switch,
  Select,
  Typography,
  Alert,
  Image,
} from "antd";
import { SearchOutlined, UserOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { SubAdminChecker } from "../../utils/AdminChecker";
import { findUnRegisterByYear } from "../../services/DharmaOnyearService";

import moment from "moment";
const { Search } = Input;
function DharmaOnyearUserUnRegister() {
  SubAdminChecker();
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [users, setUsers] = useState([]);
  const [unRegis, setUnRegis] = useState([]);

  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const onSearch = async (year) => {
    setLoading(true);
    const res = await findUnRegisterByYear(year);
    if (!res._error) {
      setUsers(res.data.users);
      setAllData(res.data.register);
      console.log(res.data.users);
      const users = [...res.data.users];
      const register = [...res.data.register];
      const unRegister = users.filter((v) => {
        // console.log(register.find((f) => f.user_id === v.id));
        if (!register.find((f) => f.user_id === v.id)) {
          return { ...v };
        }
      });
      setUnRegis(unRegister);
    }
    setLoading(false);
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      width: 100,
      key: "index",
      render: (_, record, index) => <div>{index + 1}</div>,
    },
    {
      title: "รหัสนักศึกษา",
      dataIndex: "code",
      width: 160,
      ...getColumnSearchProps("code"),
      sorter: (a, b) => a.code.localeCompare(b.code),
      sortOrder: sortedInfo.columnKey === "code" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "คำนำหน้า",
      width: 160,
      dataIndex: "prefixName",
      key: "prefixName",
      ...getColumnSearchProps("prefixName"),
      sorter: (a, b) => a.prefixName.localeCompare(b.prefixName),
      sortOrder: sortedInfo.columnKey === "prefixName" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ชื่อ",
      width: 160,
      dataIndex: "firstName",
      key: "firstName",
      ...getColumnSearchProps("firstName"),
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      sortOrder: sortedInfo.columnKey === "firstName" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ฉายา",
      width: 160,
      dataIndex: "nickname",
      key: "nickname",
      ...getColumnSearchProps("nickname"),
      sorter: (a, b) => a.nickname.localeCompare(b.nickname),
      sortOrder: sortedInfo.columnKey === "nickname" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "นามสกุล",
      width: 160,
      dataIndex: "lastName",
      key: "lastName",
      ...getColumnSearchProps("lastName"),
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
      sortOrder: sortedInfo.columnKey === "lastName" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ระดับ",
      dataIndex: "level",
      key: "level",
      ...getColumnSearchProps("level"),
      sorter: (a, b) => a.level.localeCompare(b.level),
      sortOrder: sortedInfo.columnKey === "level" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "สาขา",
      dataIndex: "fName",
      key: "fName",
      ...getColumnSearchProps("fName"),
      sorter: (a, b) => a.fName.localeCompare(b.fName),
      sortOrder: sortedInfo.columnKey === "fName" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ประเภท",
      dataIndex: "type",
      key: "type",
      ...getColumnSearchProps("type"),
      sorter: (a, b) => a.type.localeCompare(b.type),
      sortOrder: sortedInfo.columnKey === "type" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ประเภทนักศึกษา",
      dataIndex: "gName",
      key: "gName",
      ...getColumnSearchProps("gName"),
      sorter: (a, b) => a.gName.localeCompare(b.gName),
      sortOrder: sortedInfo.columnKey === "gName" ? sortedInfo.order : null,
      ellipsis: true,
    },
  ];

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24}>
          <Row gutter={[24, 0]}>
            <Col xs={24} className="mb-24">
              <Card
                className="header-solid h-full ant-card-p-0"
                title={
                  <>
                    <Row
                      gutter={[24, 0]}
                      className="ant-row-flex ant-row-flex-middle"
                    >
                      <Col xs={24} md={12}>
                        <h6 className="font-semibold m-0">
                          รายงานนิสิตที่ยังไม่ลงทะเบียนเข้าร่วมปฏิบัติธรรมประจำปี
                        </h6>
                      </Col>
                    </Row>
                  </>
                }
              >
                <Card size="small">
                  <Search
                    addonBefore="ระบุปี พ.ศ. "
                    enterButton="เริ่มค้นหา"
                    size="large"
                    loading={loading}
                    onSearch={onSearch}
                  />
                </Card>
                <Table
                  sticky
                  dataSource={unRegis}
                  columns={columns}
                  onChange={handleChange}
                  scroll={{ x: 1800 }}
                  rowKey="id"
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "30"],
                  }}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default DharmaOnyearUserUnRegister;
