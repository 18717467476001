import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Input,
  Space,
  Typography,
  Modal,
} from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import { SearchOutlined, PlusCircleOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { SubAdminChecker } from "../../utils/AdminChecker";
import {
  getRegisterOverall,
  getRegisterUserData,
} from "../../services/DharmaOnyearService";
import DhmOnYearRegister from "./components/DhmOnYearRegister";

function DharmaOnYearChecker() {
  SubAdminChecker();
  const [loading, setLoading] = useState(false);
  const [allFields, setAllFields] = useState([]);

  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [registerData, setRegisterData] = useState([]);
  const [userRegisterData, setUserRegisterData] = useState([]);
  const [onYearName, setOnYearName] = useState("");
  const [onYearId, setOnYearId] = useState("");

  const searchInput = useRef(null);

  useEffect(() => {
    // todo
    // get all User to checker
    initAllFields();
  }, []);

  const initAllFields = async () => {
    const res = await getRegisterOverall();
    if (!res._error) {
      setAllFields(res.data);
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const openData = async (data) => {
    // console.log("openData", data);
    setOnYearName(data.name);
    setOnYearId(data.id);
    const res = await getRegisterUserData(data.id);
    if (!res._error) {
      setUserRegisterData(res.data);
      setIsModalVisible(true);
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 120,
      ...getColumnSearchProps("id"),
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === "id" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ชื่อกิจกรรมปฏิบัติธรรมประจำปี",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "วันที่เริ่มกิจกรรม",
      dataIndex: "startOnyearTime",
      key: "startOnyearTime",
      ...getColumnSearchProps("startOnyearTime"),
      sorter: (a, b) => a.startOnyearTime.localeCompare(b.startOnyearTime),
      sortOrder:
        sortedInfo.columnKey === "startOnyearTime" ? sortedInfo.order : null,
      ellipsis: true,
      render: (_, record) =>
        moment(record.startOnyearTime).format("DD-MM-YYYY"),
    },
    {
      title: "วันที่สิ้นสุดกิจกรรม",
      dataIndex: "endOnyearTime",
      key: "endOnyearTime",
      ...getColumnSearchProps("endOnyearTime"),
      sorter: (a, b) => a.endOnyearTime.localeCompare(b.endOnyearTime),
      sortOrder:
        sortedInfo.columnKey === "endOnyearTime" ? sortedInfo.order : null,
      ellipsis: true,
      render: (_, record) => moment(record.endOnyearTime).format("DD-MM-YYYY"),
    },
    {
      title: "จำนวนผู้ลงทะเบียนแล้ว",
      dataIndex: "sumAll",
      key: "sumAll",
      ...getColumnSearchProps("sumAll"),
      sorter: (a, b) => a.sumAll - b.sumAll,
      sortOrder: sortedInfo.columnKey === "sumAll" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "รายงาน",
      dataIndex: "report",
      key: "report",
      fixed: "right",
      width: 120,
      render: (_, record) =>
        allFields.length >= 1 ? (
          <>
            <Link
              to={{
                pathname: "/dharma-onyear/dharma-onyear-result-checker",
                state: {
                  onYearId_p: record.id,
                  onYearName_p: record.name,
                  isStartReport_p: record.isStartReport,
                  isDiscussion_p: record.isDiscussion,
                },
              }}
            >
              {"รายงาน"}
            </Link>
          </>
        ) : null,
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24}>
          <Row gutter={[24, 0]}>
            <Col xs={24} className="mb-24">
              <Card
                className="header-solid h-full ant-card-p-0"
                title={
                  <>
                    <Row
                      gutter={[24, 0]}
                      className="ant-row-flex ant-row-flex-middle"
                    >
                      <Col xs={24} md={12}>
                        <h6 className="font-semibold m-0">
                          ตรวจสอบผลเข้าร่วมปฏิบัติธรรมประจำปี
                        </h6>
                      </Col>
                    </Row>
                  </>
                }
              >
                <Table
                  sticky
                  dataSource={allFields}
                  columns={columns}
                  onChange={handleChange}
                  scroll={{ x: 1200 }}
                  rowKey="id"
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "30"],
                  }}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default DharmaOnYearChecker;
