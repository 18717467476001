import React, { useEffect, useState } from "react";
import {
  Carousel,
  List,
  Row,
  Col,
  Card,
  Typography,
  Tag,
  Image,
  Button,
  Alert,
  Modal,
  Form,
  Input,
  Radio,
  Checkbox,
  Divider,
} from "antd";
import {
  FieldTimeOutlined,
  InfoCircleOutlined,
  DashboardOutlined,
  FileSearchOutlined,
  EditOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  getAllDharmaOnyearPlublicCount,
  getRegisterUserDataPublic,
} from "../services/DharmaOnyearService";
import DhmOnYearRegister from "../pages/DharmaOnYear/components/DhmOnYearRegister";
import DhmOnYearRegisterPb from "../pages/DharmaOnYear/components/DhmOnYearRegisterPb";

const { Paragraph, Text, Title } = Typography;

export default function OnYearHome() {
  const [loading, setLoading] = useState(false);
  const [allDharmaOnyear, setAllDharmaOnyear] = useState([]);
  const [allDharmaOnyearCount, setAllDharmaOnyearCount] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userRegisterData, setUserRegisterData] = useState([]);
  const [onYearName, setOnYearName] = useState("");
  const [onYearId, setOnYearId] = useState("");

  useEffect(() => {
    initAllData();
  }, []);

  const initAllData = async () => {
    setLoading(true);
    const res = await getAllDharmaOnyearPlublicCount();
    if (!res._error) {
      setAllDharmaOnyear(res.data.dharma);
      setAllDharmaOnyearCount(res.data.regisCount);
    }
    setLoading(false);
  };

  const renderStartEnd = (start, end) => {
    const startTime = moment(start).add(543, "year").format("DD/MM/YYYY HH:mm");
    const endTime = moment(end).add(543, "year").format("DD/MM/YYYY HH:mm");
    return (
      <>
        <FieldTimeOutlined /> {startTime} ถึง {endTime}
      </>
    );
  };
  const renderReadMore = (uuidLink) => {
    const url = `/home/dharma-onyear-detail?uuidLink=${uuidLink}`;
    return (
      <>
        <InfoCircleOutlined /> <Link to={url}>อ่านเพิ่มเติม</Link>
      </>
    );
  };

  const renderCount = (dId) => {
    const findData = allDharmaOnyearCount.find((v) => v.dharmaOnyearId === dId);
    if (findData) {
      return findData.count;
    }
    return 0;
  };

  const openData = async (id, name) => {
    setOnYearName(name);
    setOnYearId(id);
    const res = await getRegisterUserDataPublic(id);
    if (!res._error) {
      setUserRegisterData(res.data);
      setIsModalVisible(true);
    }
  };

  const renderDetail = (items) => {
    return (
      <>
        <DashboardOutlined /> {"จำนวนที่ลงทะเบียน/รองรับ : "}
        {"("}
        {renderCount(items.dharma_onyear_id)}
        {"/"}
        {items.rooms_maxSeats}
        {")"}
        <Typography.Link
          onClick={() =>
            openData(items.dharma_onyear_id, items.dharma_onyear_name)
          }
          style={{ marginLeft: 6 }}
        >
          ตรวจสอบรายชื่อ <FileSearchOutlined />
        </Typography.Link>
      </>
    );
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  if (allDharmaOnyear.length <= 0) {
    return <></>;
  }
  return (
    <Card className="header-solid h-full ant-card-p-0">
      <List
        itemLayout="vertical"
        size="small"
        header={
          <h3>
            <b>ปฏิบัติธรรมประจำปี</b>
          </h3>
        }
        pagination={{
          onChange: (page) => {},
          pageSize: 10,
        }}
        dataSource={allDharmaOnyear}
        renderItem={(item, index) => (
          <List.Item
            key={item.dharma_onyear_id}
            extra={
              <Image
                style={{
                  width: 320,
                  height: 180,
                  backgroundColor: "#CCCCCC",
                  objectFit: "cover",
                  borderRadius: 8,
                }}
                width={320}
                alt={item.dharma_onyear_name}
                src={item.dharma_onyear_image}
              />
            }
          >
            <List.Item.Meta
              title={
                <Paragraph
                  ellipsis={{
                    rows: 2,
                    expandable: false,
                  }}
                >
                  <Link
                    to={`/home/dharma-onyear-detail?uuidLink=${item.dharma_onyear_uuid}`}
                  >
                    {item.dharma_onyear_name}
                  </Link>
                </Paragraph>
              }
              description={
                <>
                  <Paragraph
                    ellipsis={{
                      rows: 2,
                      expandable: false,
                    }}
                  >
                    {item.rooms_name}
                  </Paragraph>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Tag>
                      {renderStartEnd(
                        item.dharma_onyear_startOnyearTime,
                        item.dharma_onyear_endOnyearTime
                      )}
                    </Tag>
                    <Tag style={{ marginTop: 8 }}>{renderDetail(item)}</Tag>
                    <Tag style={{ marginTop: 8 }}>
                      {renderReadMore(item.dharma_onyear_uuid)}
                    </Tag>
                  </div>
                </>
              }
            />
          </List.Item>
        )}
      />
      <Modal
        title={`การลงทะเบียน : ${onYearName}`}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width="80%"
      >
        <DhmOnYearRegisterPb
          onYearId={onYearId}
          userRegisterDatas={userRegisterData}
        />
      </Modal>
    </Card>
  );
}
