import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Input,
  Space,
  Modal,
  Form,
  Switch,
  Select,
  Typography,
  Image,
  DatePicker,
  Alert,
  Checkbox,
  Divider,
  Radio,
  Popover,
} from "antd";
import {
  SearchOutlined,
  EditOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  UserOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {
  findAllResultsByUserId,
  userUpdateDharmaOnsite,
  userSendReportDharmaOnsite,
  generatePDFApproveGo,
  generatePDFResult,
} from "../../services/DharamaOnsiteResultsService";
import moment from "moment";

const { TextArea } = Input;
const { Paragraph, Text, Title } = Typography;
const { RangePicker } = DatePicker;

function DharmaOnsiteUserChecker() {
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);

  const [ImageFiles, setImageFiles] = useState(null);
  const [ImageShow, setImageShow] = useState(null);
  const [file, setFile] = useState(null);

  const [isDisableWalk, setIsDisableWalk] = useState(1);
  const [isDisableSit, setIsDisableSit] = useState(1);

  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const [isModalVisibleGo, setIsModalVisibleGo] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState(-1);
  const [eventName, setEventName] = useState(null);
  const [roomName, setRoomName] = useState(null);
  const [eventStartEnd, setEeventStartEnd] = useState(null);
  const [eventStart, setEeventStart] = useState(new Date());
  const [eventEnd, setEeventEnd] = useState(new Date());
  const [userPdfFile, setUserPdfFile] = useState(null);
  const [adminNote, setAdminNote] = useState(null);
  const [adminNoteGo, setAdminNoteGo] = useState(null);
  const [eventStartEndGo, setEeventStartEndGo] = useState(null);

  const [checkboxOnsite, setCheckboxOnsite] = useState([]);

  const [form] = Form.useForm();
  const [formGo] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");

  useEffect(() => {
    // todo
    // get all User to checker
    initAllData();
  }, []);

  const initAllData = async () => {
    const res = await findAllResultsByUserId();
    if (!res._error) {
      setAllData(res.data);
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const onChangeOnlineTime = (value, dateString) => {
    // console.log("Selected Time: ", value);
    // console.log("Formatted Selected Time: ", dateString);
  };
  const onOkOnlineTime = (value) => {
    // console.log("onOk: ", value);
  };

  const disabledDate = (current) => {
    const nowTime = new Date().getTime();
    const startEvent = new Date(eventStart).getTime();
    if (nowTime < startEvent) {
      const setDisabledDate =
        (current && current < moment(eventStart).hour(0).minute(0)) ||
        (current && current > moment(eventEnd).hour(23).minute(59));
      return setDisabledDate;
    } else {
      const setDisabledDate =
        (current && current < moment(nowTime).hour(0).minute(0)) ||
        (current && current > moment(eventEnd).hour(23).minute(59));
      return setDisabledDate;
    }
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            span: 6,
          },
          wrapperCol: {
            span: 18,
          },
        }
      : null;

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const renderStatusgo = (statusText, adminNote = "", datas) => {
    if (statusText === "รอตรวจสอบ") {
      return <Alert message="รอตรวจสอบ" type="warning" showIcon />;
    } else if (statusText === "อนุมัติ") {
      return (
        <div style={{ width: 200, display: "flex", flexDirection: "column" }}>
          <Alert message="อนุมัติแล้ว" type="success" showIcon />
          <Button
            size="small"
            onClick={() =>
              generatePDFApproveGo("approve", datas.dharma_onsite_results_uuid)
            }
            download
            block
            icon={<DownloadOutlined />}
          >
            หนังสือส่งตัว
          </Button>
          <Button
            size="small"
            onClick={() =>
              generatePDFResult("approve", datas.dharma_onsite_results_uuid)
            }
            download
            block
            icon={<DownloadOutlined />}
          >
            บฑ.21
          </Button>
        </div>
      );
    } else if (statusText === "ไม่อนุมัติ") {
      return (
        <>
          <Alert message="ไม่อนุมัติ" type="error" showIcon />
          {adminNote.trim().length > 0 && (
            <Alert
              style={{ whiteSpace: "pre-wrap" }}
              message={adminNote}
              type="error"
              showIcon
            />
          )}
          <Button
            onClick={() => openEdit(datas)}
            icon={<EditOutlined />}
            danger
            block
            type="primary"
            style={{ marginTop: 10 }}
          >
            แก้ไขคำขออนุมัติ
          </Button>
        </>
      );
    }
  };

  const renderStatus = (statusText, adminNote = "", datas) => {
    if (statusText === "รอดำเนินการ") {
      const dateNow = new Date();
      const eventApvEnd = new Date(datas.dharma_onsite_results_user_endgo_dt);
      const content =
        "รายงานได้หลังจาก " +
        moment(datas.dharma_onsite_results_user_endgo_dt)
          .add(543, "year")
          .format("DD/MM/YYYY HH:mm");
      let isDisable = true;
      if (dateNow >= eventApvEnd) {
        isDisable = false;
      }
      return (
        <>
          <Alert message="รอการรายงาน" type="warning" showIcon />
          {datas.dharma_onsite_results_statusgo === "อนุมัติ" && (
            <Popover content={content} trigger="hover">
              <Button
                onClick={() => openReport(datas)}
                icon={<EditOutlined />}
                block
                type="primary"
                disabled={isDisable}
                style={{ marginTop: 10 }}
              >
                รายงานการเข้าร่วม
              </Button>
            </Popover>
          )}
        </>
      );
    } else if (statusText === "รอตรวจสอบ") {
      return <Alert message="รอตรวจสอบ" type="warning" showIcon />;
    } else if (statusText === "อนุมัติ") {
      return (
        <>
          <Alert message="อนุมัติแล้ว" type="success" showIcon />
        </>
      );
    } else if (statusText === "ไม่อนุมัติ") {
      return (
        <>
          <Alert message="ไม่อนุมัติ" type="error" showIcon />
          {adminNote.trim().length > 0 && (
            <Alert
              style={{ whiteSpace: "pre-wrap" }}
              message={adminNote}
              type="error"
              showIcon
            />
          )}
          <Button
            onClick={() => openReport(datas)}
            icon={<EditOutlined />}
            danger
            block
            type="primary"
            style={{ marginTop: 10 }}
          >
            แก้ไขรายงาน
          </Button>
        </>
      );
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const renderAvatar = (avatar) => {
    if (avatar) {
      return (
        <Image
          style={{
            width: 100,
            aspectRatio: "16/9",
            backgroundColor: "#CCCCCC",
            objectFit: "cover",
          }}
          src={avatar}
        />
      );
    }
    return (
      <div
        style={{
          width: 100,
          aspectRatio: "16/9",
          backgroundColor: "#CCCCCC",
        }}
        icon={<UserOutlined />}
      />
    );
  };
  const renderImageSent = (avatar) => {
    if (avatar) {
      return (
        <Image
          style={{
            width: 100,
            aspectRatio: "16/9",
            backgroundColor: "#CCCCCC",
            objectFit: "cover",
          }}
          src={avatar}
        />
      );
    }
    return "-";
  };
  const columns = [
    {
      title: "รหัส",
      dataIndex: "dharma_onsite_results_id",
      width: 100,
      key: "dharma_onsite_results_id",
      ...getColumnSearchProps("dharma_onsite_results_id"),
      sorter: (a, b) => a.dharma_onsite_results_id - b.dharma_onsite_results_id,
      sortOrder:
        sortedInfo.columnKey === "dharma_onsite_results_id"
          ? sortedInfo.order
          : null,
      ellipsis: true,
    },
    {
      title: "ภาพปกกิจกรรม",
      dataIndex: "b.dharma_onsite_image",
      width: 140,
      render: (_, record) =>
        allData.length >= 1 ? renderAvatar(record.dharma_onsite_image) : null,
    },
    {
      title: "หัวข้อกิจกรรม",
      width: 220,
      dataIndex: "dharma_onsite_name",
      key: "dharma_onsite_name",
      ...getColumnSearchProps("dharma_onsite_name"),
      sorter: (a, b) =>
        a.dharma_onsite_name.localeCompare(b.dharma_onsite_name),
      sortOrder:
        sortedInfo.columnKey === "dharma_onsite_name" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "วันที่ขอเข้าร่วม",
      width: 220,
      dataIndex: "dharma_onsite_results_user_startgo_dt",
      key: "dharma_onsite_results_user_startgo_dt",
      ...getColumnSearchProps("dharma_onsite_results_user_startgo_dt"),
      sorter: (a, b) =>
        a.dharma_onsite_name.localeCompare(
          b.dharma_onsite_results_user_startgo_dt
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_onsite_results_user_startgo_dt"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        moment(record.dharma_onsite_results_user_startgo_dt)
          .add(543, "year")
          .format("DD-MM-YYYY HH:mm") +
        " - " +
        moment(record.dharma_onsite_results_user_endgo_dt)
          .add(543, "year")
          .format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "สถานะการอนุมัติเข้าร่วม",
      width: 240,
      dataIndex: "dharma_onsite_results_statusgo",
      key: "dharma_onsite_results_statusgo",
      ...getColumnSearchProps("dharma_onsite_results_statusgo"),
      sorter: (a, b) =>
        a.dharma_onsite_results_statusgo.localeCompare(
          b.dharma_onsite_results_statusgo
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_onsite_results_statusgo"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        renderStatusgo(
          record.dharma_onsite_results_statusgo,
          record.dharma_onsite_results_admin_checkgo_note,
          record
        ),
    },
    {
      title: "สถานะการตรวจสอบรายงาน",
      width: 240,
      dataIndex: "dharma_onsite_results_status",
      key: "dharma_onsite_results_status",
      ...getColumnSearchProps("dharma_onsite_results_status"),
      sorter: (a, b) =>
        a.dharma_onsite_results_status.localeCompare(
          b.dharma_onsite_results_status
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_onsite_results_status"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        renderStatus(
          record.dharma_onsite_results_status,
          record.dharma_onsite_results_admin_note,
          record
        ),
    },
    {
      title: "ภาพประกอบที่เข้าร่วม",
      dataIndex: "b.dharma_onsite_image",
      width: 140,
      render: (_, record) =>
        allData.length >= 1
          ? renderImageSent(record.dharma_onsite_results_image)
          : null,
    },
    {
      title: "วันเวลาที่สร้างเรื่องขออนุมัติ",
      width: 240,
      dataIndex: "dharma_onsite_results_created_dt",
      key: "dharma_onsite_results_created_dt",
      ...getColumnSearchProps("dharma_onsite_results_created_dt"),
      sorter: (a, b) =>
        a.dharma_onsite_results_created_dt.localeCompare(
          b.dharma_onsite_results_created_dt
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_onsite_results_created_dt"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        moment(record.dharma_onsite_results_created_dt)
          .add(543, "year")
          .format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "วันเวลาที่อัปเดตล่าสุด",
      width: 240,
      dataIndex: "dharma_onsite_results_updated_dt",
      key: "dharma_onsite_results_updated_dt",
      ...getColumnSearchProps("dharma_onsite_results_updated_dt"),
      sorter: (a, b) =>
        a.dharma_onsite_results_updated_dt.localeCompare(
          b.dharma_onsite_results_updated_dt
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_onsite_results_updated_dt"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        moment(record.dharma_onsite_results_updated_dt)
          .add(543, "year")
          .format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "เจ้าหน้าที่อนุมัติคำขอล่าสุด",
      width: 240,
      dataIndex: "dharma_onsite_results_admin_checkgo_dt",
      key: "dharma_onsite_results_admin_checkgo_dt",
      ...getColumnSearchProps("dharma_onsite_results_admin_checkgo_dt"),
      sorter: (a, b) =>
        a.dharma_onsite_results_admin_checkgo_dt.localeCompare(
          b.dharma_onsite_results_admin_checkgo_dt
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_onsite_results_admin_checkgo_dt"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        record.dharma_onsite_results_admin_checkgo_dt
          ? moment(record.dharma_onsite_results_admin_checkgo_dt)
              .add(543, "year")
              .format("DD-MM-YYYY HH:mm")
          : "-",
    },
    {
      title: "เจ้าหน้าที่ตรวจรายงานล่าสุด",
      width: 240,
      dataIndex: "dharma_onsite_results_admin_check_dt",
      key: "dharma_onsite_results_admin_check_dt",
      ...getColumnSearchProps("dharma_onsite_results_admin_check_dt"),
      sorter: (a, b) =>
        a.dharma_onsite_results_admin_check_dt.localeCompare(
          b.dharma_onsite_results_admin_check_dt
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_onsite_results_admin_check_dt"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        record.dharma_onsite_results_admin_check_dt
          ? moment(record.dharma_onsite_results_admin_check_dt)
              .add(543, "year")
              .format("DD-MM-YYYY HH:mm")
          : "-",
    },
  ];

  const openEdit = (data) => {
    setEventName(data.dharma_onsite_name);
    setRoomName(data.rooms_name);
    setEeventStartEnd(
      moment(data.dharma_onsite_startOnsiteTime)
        .add(543, "year")
        .format("DD/MM/YYYY HH:mm") +
        " - " +
        moment(data.dharma_onsite_endOnsiteTime)
          .add(543, "year")
          .format("DD/MM/YYYY HH:mm")
    );
    setEeventStart(data.dharma_onsite_startOnsiteTime);
    setEeventEnd(data.dharma_onsite_endOnsiteTime);
    setEditId(data.dharma_onsite_results_id);
    setAdminNoteGo(data.dharma_onsite_results_admin_checkgo_note);

    const values = {
      goTime: [
        moment(data.dharma_onsite_results_user_startgo_dt),
        moment(data.dharma_onsite_results_user_endgo_dt),
      ],
    };
    formGo.setFieldsValue(values);
    setIsModalVisibleGo(true);
  };

  const openReport = (data) => {
    setUserPdfFile(data.dharma_onsite_results_reportPdfFile);
    setEventName(data.dharma_onsite_name);
    setRoomName(data.rooms_name);
    setEeventStartEnd(
      moment(data.dharma_onsite_startOnsiteTime)
        .add(543, "year")
        .format("DD/MM/YYYY HH:mm") +
        " - " +
        moment(data.dharma_onsite_endOnsiteTime)
          .add(543, "year")
          .format("DD/MM/YYYY HH:mm")
    );
    setEeventStartEndGo(
      moment(data.dharma_onsite_results_user_startgo_dt)
        .add(543, "year")
        .format("DD/MM/YYYY HH:mm") +
        " - " +
        moment(data.dharma_onsite_results_user_endgo_dt)
          .add(543, "year")
          .format("DD/MM/YYYY HH:mm")
    );
    setEeventStart(data.dharma_onsite_startOnsiteTime);
    setEeventEnd(data.dharma_onsite_endOnsiteTime);
    setEditId(data.dharma_onsite_results_id);
    setAdminNote(data.dharma_onsite_results_admin_note);

    const values = {
      goTime: [
        moment(data.dharma_onsite_results_user_startgo_dt),
        moment(data.dharma_onsite_results_user_endgo_dt),
      ],
    };
    form.setFieldsValue(values);
    setIsModalVisible(true);
  };

  const handleOkGo = () => {
    setLoading(true);
    formGo
      .validateFields()
      .then(async (values) => {
        const data = {
          id: editId,
          user_startgo_dt: values.goTime[0]._d.toJSON(),
          user_endgo_dt: values.goTime[1]._d.toJSON(),
        };
        const res = await userUpdateDharmaOnsite(data);
        if (!res._error) {
          initAllData();
          setIsModalVisibleGo(false);
          form.resetFields();
        }
        setLoading(false);
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  const handleOk = () => {
    setLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("id", editId);
        formData.append("detail", values.detail || "");
        const res = await userSendReportDharmaOnsite(formData);
        if (!res._error) {
          initAllData();
          setIsModalVisible(false);
          setImageFiles(null);
          setImageShow(null);
          setCheckboxOnsite([]);
          form.resetFields();
        }
        setLoading(false);
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  const onFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24}>
          <Row gutter={[24, 0]}>
            <Col xs={24} className="mb-24">
              <Card
                className="header-solid h-full ant-card-p-0"
                title={
                  <>
                    <Row
                      gutter={[24, 0]}
                      className="ant-row-flex ant-row-flex-middle"
                    >
                      <Col xs={24} md={12}>
                        <h6 className="font-semibold m-0">
                          ตรวจสอบผลรายงานปฏิบัติธรรมออนไลน์
                        </h6>
                      </Col>
                    </Row>
                  </>
                }
              >
                <Table
                  dataSource={allData}
                  columns={columns}
                  onChange={handleChange}
                  scroll={{ x: "max-content" }}
                  sticky
                  rowKey="dharma_onsite_results_id"
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "30"],
                  }}
                />
              </Card>
              <Modal
                title="แก้ไขเรื่องขออนุมติเข้าร่วมกิจกรรมปฏิบัติธรรมในสถานที่"
                visible={isModalVisibleGo}
                onOk={handleOkGo}
                onCancel={() => {
                  formGo.resetFields();
                  setIsModalVisibleGo(false);
                }}
                width={"80%"}
                centered
                confirmLoading={loading}
                closable={false}
                maskClosable={false}
                footer={[
                  <Button
                    icon={<CloseCircleOutlined />}
                    key="back"
                    onClick={() => {
                      formGo.resetFields();
                      setIsModalVisibleGo(false);
                    }}
                    disabled={loading}
                  >
                    ยกเลิก
                  </Button>,
                  <Button
                    icon={<CheckCircleOutlined />}
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={handleOkGo}
                  >
                    ยืนยันส่งเรื่องขออนุมติเข้าร่วมกิจกรรม
                  </Button>,
                ]}
              >
                <>
                  <Form
                    {...formItemLayout}
                    layout={formLayout}
                    form={formGo}
                    initialValues={{
                      layout: "vertical",
                      isActive: true,
                    }}
                  >
                    <div
                      style={{ flexDirection: "column", display: "flex" }}
                    ></div>
                    <Form.Item label="กิจกรรม" name="dharma_onsite_name">
                      <Text strong>{eventName}</Text>
                    </Form.Item>
                    <Form.Item label="ชื่อสถานที่" name="rooms_name">
                      <Text>{roomName}</Text>
                    </Form.Item>
                    <Form.Item
                      label="วันที่เวลาของกิจกรรม"
                      name="dharma_onsite_startOnsiteTime"
                    >
                      <Text>{eventStartEnd}</Text>
                    </Form.Item>
                    <Form.Item
                      label="วันเริ่มต้น-สิ้นสุดที่ขออนุมัติ"
                      name="goTime"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุวันเริ่มต้น-สิ้นสุดที่ขออนุมัติ!",
                        },
                      ]}
                      extra={
                        "วันเริ่มต้น-สิ้นสุดที่ขออนุมัติต้องอยู่ในช่วงระยะเวลาของกิจกรรม"
                      }
                    >
                      <RangePicker
                        disabledDate={disabledDate}
                        showTime={{
                          hideDisabledOptions: true,
                          format: "HH:mm",
                        }}
                        placeholder={["วันเวลาเริ่มต้น", "วันเวลาสิ้นสุด"]}
                        format="YYYY-MM-DD HH:mm"
                        onChange={onChangeOnlineTime}
                        onOk={onOkOnlineTime}
                      />
                    </Form.Item>
                    {adminNoteGo && adminNoteGo.trim().length > 0 && (
                      <Alert
                        style={{ whiteSpace: "pre-wrap" }}
                        message={adminNoteGo}
                        type="error"
                        showIcon
                      />
                    )}
                  </Form>
                </>
              </Modal>
              <Modal
                title="รายงานผลการปฏิบัติธรรมในสถานที่"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={() => {
                  form.resetFields();
                  setIsModalVisible(false);
                  setImageFiles(null);
                  setImageShow(null);
                }}
                width={"80%"}
                confirmLoading={loading}
                closable={false}
                maskClosable={false}
                centered
                footer={[
                  <Button
                    icon={<CloseCircleOutlined />}
                    key="back"
                    onClick={() => {
                      form.resetFields();
                      setIsModalVisible(false);
                      setImageFiles(null);
                      setImageShow(null);
                    }}
                    disabled={loading}
                  >
                    ยกเลิก
                  </Button>,
                  <Button
                    icon={<CheckCircleOutlined />}
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={handleOk}
                  >
                    ยืนยันรายงานผลการปฏิบัติธรรมในสถานที่
                  </Button>,
                ]}
              >
                <>
                  <Form
                    layout={"vertical"}
                    form={form}
                    initialValues={{
                      layout: "vertical",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div>กิจกรรม: {eventName}</div>
                      <div>ชื่อสถานที่: {roomName}</div>
                      <div>วันที่เวลาของกิจกรรม: {eventStartEnd}</div>
                      <div>วันที่เวลาที่ขอเข้าร่วม: {eventStartEndGo}</div>
                    </div>
                    <Form.Item
                      label="ไฟล์รายงานการเข้าร่วม (.pdf)"
                      name="report_file"
                      required
                      extra={
                        !userPdfFile ? null : (
                          <a href={userPdfFile} target={"_blank"}>
                            ไฟล์ล่าสุด : {userPdfFile}
                          </a>
                        )
                      }
                      rules={[
                        {
                          required: true,
                          message: "กรุณาเลือกไฟล์รายงานการเข้าร่วม (.pdf)!",
                        },
                      ]}
                    >
                      <Input
                        type={"file"}
                        placeholder="ระบุไฟล์รายงานการเข้าร่วม (.pdf)"
                        accept="application/pdf"
                        onChange={onFileChange}
                      />
                    </Form.Item>

                    {adminNote && adminNote.trim().length > 0 && (
                      <Alert
                        style={{ whiteSpace: "pre-wrap" }}
                        message={adminNote}
                        type="error"
                        showIcon
                      />
                    )}
                  </Form>
                </>
              </Modal>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default DharmaOnsiteUserChecker;
