import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Input,
  Space,
  Modal,
  Form,
  Switch,
  Select,
  Typography,
  Image,
  DatePicker,
  Checkbox,
  Divider,
  Alert,
} from "antd";
import {
  SearchOutlined,
  PlusCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { SubAdminChecker } from "../../utils/AdminChecker";
import {
  getAllDharmaOnyear,
  getConditionWithOnyearId,
  getRegisterConditionWithOnyearId,
  createDharmaOnyear,
  updateDharmaOnyear,
  adminRegister,
  getRegisterUserData,
} from "../../services/DharmaOnyearService";
import { getAllDatas } from "../../services/TeacherService";
import { getAllRoomsActive } from "../../services/RoomsService";
import { getAllFieldsActive } from "../../services/FieldsService";
import { getAllGraduateTypes } from "../../services/GraduateTypesService";
import { getAllSecretary } from "../../services/AdminsService";
import { getAllAssessment } from "../../services/AssessmentService";
import moment from "moment";
import RoomSelect from "../DharmaOnsite/components/RoomSelect";

const { TextArea } = Input;
const { RangePicker } = DatePicker;

function DharmaOnyearData() {
  SubAdminChecker();
  const [loading, setLoading] = useState(false);
  const [isModalVisibleFilterRequired, setIsModalVisibleFilterRequired] =
    useState(false);
  const [allData, setAllData] = useState([]);
  const [allTeacher, setAllTeacher] = useState([]);
  const [allRooms, setAllRooms] = useState([]);
  const [allFields, setAllFields] = useState([]);
  const [allAssessment, setAllAssessment] = useState([]);
  const [allGraduateTypes, setAllGraduateTypes] = useState([]);
  const [allSecretary, setAllSecretary] = useState([]);
  const [mapData, setMapData] = useState([]);
  const [mapDataEdit, setMapDataEdit] = useState([]);
  const [mapDataRegister, setMapDataRegister] = useState([]);

  const [isStartReport, setIsStartReport] = useState(false);

  const [isMaxSeats, setIsMaxSeats] = useState("");
  const [maxSeatsText, setMaxSeatsText] = useState("");

  const [ImageFiles, setImageFiles] = useState(null);
  const [ImageShow, setImageShow] = useState(null);
  const [selectRoom, setSelectRoom] = useState(null);

  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const [isOpenRoomSelect, setIsOpenRoomSelect] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [isModalRegisterVisible, setIsModalRegisterVisible] = useState(false);
  const [editId, setEditId] = useState(-1);
  const [registerId, setRegisterId] = useState(-1);
  const [editPassword, setEditPassword] = useState(-1);

  const [isRegisterCompleted, setIsRegisterCompleted] = useState(false);
  const [isModalConfrim, setIsModalConfrim] = useState(false);

  const [form] = Form.useForm();
  const [formEdit] = Form.useForm();
  const [formRegister] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");

  useEffect(() => {
    // todo
    // get all User to checker
    initAllData();
  }, []);

  const initAllData = async () => {
    const res = await getAllDharmaOnyear();
    if (!res._error) {
      setAllData(res.data);
    }
    const teachers = await getAllDatas();
    if (!teachers._error) {
      setAllTeacher(teachers.data);
    }
    const rooms = await getAllRoomsActive();
    if (!rooms._error) {
      setAllRooms(rooms.data);
    }
    const fields = await getAllFieldsActive();
    if (!fields._error) {
      setAllFields(fields.data);
    }
    const graduateTypes = await getAllGraduateTypes();
    if (!graduateTypes._error) {
      setAllGraduateTypes(graduateTypes.data);
    }
    const secretary = await getAllSecretary();
    if (!secretary._error) {
      setAllSecretary(secretary.data);
    }
    const assessment = await getAllAssessment();
    if (!assessment._error) {
      setAllAssessment(assessment.data);
    }
    await mapConditionData(fields.data, graduateTypes.data);
  };

  const mapConditionData = async (fields, graduateTypes) => {
    const mapGraduateTypes = graduateTypes.map((v) => {
      return {
        id: v.id,
        name: v.name,
        isGraduateTypesCheck: false,
      };
    });
    const mapYearCondition = [1, 2, 3, 4].map((v) => {
      return {
        year: v,
        isYearCheck: false,
        graduateTypes: mapGraduateTypes,
      };
    });
    const mapData = fields.map((v) => {
      return {
        fields: {
          id: v.id,
          level: v.level,
          name: v.name,
          type: v.type,
          isFieldsCheck: false,
        },
        conditions: mapYearCondition,
      };
    });
    setMapData(mapData);
    setMapDataRegister(mapData);
  };

  const handleSwitch = async (values, e) => {
    const formData = new FormData();
    formData.append("id", values.dharma_onyear_id);
    formData.append("name", values.dharma_onyear_name);
    formData.append("roomId", values.dharma_onyear_roomId);
    formData.append("detail", values.dharma_onyear_detail);
    formData.append("startOnyearTime", values.dharma_onyear_startOnyearTime);
    formData.append("endOnyearTime", values.dharma_onyear_endOnyearTime);
    formData.append("startShowTime", values.dharma_onyear_startShowTime);
    formData.append("endShowTime", values.dharma_onyear_endShowTime);
    formData.append("teacher1_by", values.dharma_onyear_teacher1_by);
    formData.append("teacher2_by", values.dharma_onyear_teacher2_by);
    formData.append("teacher3_by", values.dharma_onyear_teacher3_by);
    formData.append("teacher4_by", values.dharma_onyear_teacher4_by);
    formData.append("teacher5_by", values.dharma_onyear_teacher5_by);
    formData.append("isActive", e);
    formData.append("secretary_by", values.dharma_onyear_secretary_by);
    formData.append("assessmentId", values.dharma_onyear_assessmentId);
    const res = await updateDharmaOnyear(formData);
    var newData = res.data[0];
    if (!res._error) {
      const updateData = allData.map((item) => {
        if (item.dharma_onyear_id === values.dharma_onyear_id) {
          var userActive = false;
          if (e === true || e === 1) {
            userActive = true;
          }
          return { ...item, ...newData, dharma_onyear_isActive: userActive };
        }
        return item;
      });
      // console.log(updateData);
      setAllData(updateData);
    }
  };

  const openEdit = async (data) => {
    setEditId(data.dharma_onyear_id);
    setImageShow(data.dharma_onyear_image);
    setSelectRoom(data.dharma_onyear_roomId);

    const conditionApi = await getConditionWithOnyearId(data.dharma_onyear_id);
    if (!conditionApi._error) {
      const conditionApiData = conditionApi.data;
      const mapDataEdits = allFields.map((v) => {
        let obj = conditionApiData.find((o) => o.fieldsId === v.id);
        if (obj) {
          return {
            fields: {
              id: v.id,
              level: v.level,
              name: v.name,
              type: v.type,
              isFieldsCheck: true,
            },
            conditions: [1, 2, 3, 4].map((v2) => {
              let obj2 = conditionApiData.find(
                (o2) => o2.fieldsId === v.id && o2.educateYear === v2.toString()
              );
              return {
                year: v2,
                isYearCheck: obj2 ? true : false,
                graduateTypes: allGraduateTypes.map((v3) => {
                  let obj3 = conditionApiData.find(
                    (o3) =>
                      o3.fieldsId === v.id &&
                      o3.educateYear === v2.toString() &&
                      o3.graduateTypesId === v3.id
                  );
                  return {
                    id: v3.id,
                    name: v3.name,
                    isGraduateTypesCheck: obj3 ? true : false,
                  };
                }),
              };
            }),
          };
        } else {
          return {
            fields: {
              id: v.id,
              level: v.level,
              name: v.name,
              type: v.type,
              isFieldsCheck: false,
            },
            conditions: [1, 2, 3, 4].map((v2) => {
              return {
                year: v2,
                isYearCheck: false,
                graduateTypes: allGraduateTypes.map((v3) => {
                  return {
                    id: v3.id,
                    name: v3.name,
                    isGraduateTypesCheck: false,
                  };
                }),
              };
            }),
          };
        }
      });
      // console.log("mapDataEdit =>", mapDataEdits);
      // console.log("conditionApiData", conditionApiData);
      setMapDataEdit(mapDataEdits);
    }

    var userActive = false;
    if (
      data.dharma_onyear_isActive === true ||
      data.dharma_onyear_isActive === 1
    ) {
      userActive = true;
    }
    const values = {
      layout: "vertical",
      name: data.dharma_onyear_name,
      detail: data.dharma_onyear_detail,
      roomId: data.dharma_onyear_roomId,
      onyearTime: [
        moment(data.dharma_onyear_startOnyearTime),
        moment(data.dharma_onyear_endOnyearTime),
      ],
      showTime: [
        moment(data.dharma_onyear_startShowTime),
        moment(data.dharma_onyear_endShowTime),
      ],
      teacher1_by: data.dharma_onyear_teacher1_by,
      teacher2_by: data.dharma_onyear_teacher2_by,
      teacher3_by: data.dharma_onyear_teacher3_by,
      teacher4_by: data.dharma_onyear_teacher4_by,
      teacher5_by: data.dharma_onyear_teacher5_by,
      isActive: userActive,
      secretary: data.dharma_onyear_secretary_by,
      assessment: data.dharma_onyear_assessmentId,
    };
    // console.log(values);
    formEdit.setFieldsValue(values);
    setIsModalEditVisible(true);
  };

  const openRegister = async (data) => {
    setRegisterId(data.dharma_onyear_id);
    setImageShow(data.dharma_onyear_image);
    setIsStartReport(data.dharma_onyear_isStartReport);

    const isReg = await getRegisterUserData(data.dharma_onyear_id);
    if (!isReg._error) {
      // console.log("isReg", isReg.data.length);
      setIsRegisterCompleted(isReg.data.length > 0);
    }

    const conditionApi = await getRegisterConditionWithOnyearId(
      data.dharma_onyear_id
    );
    if (!conditionApi._error) {
      const conditionApiData = conditionApi.data;
      const mapDataEdits = allFields.map((v) => {
        let obj = conditionApiData.find((o) => o.fieldsId === v.id);
        if (obj) {
          return {
            fields: {
              id: v.id,
              level: v.level,
              name: v.name,
              type: v.type,
              isFieldsCheck: true,
            },
            conditions: [1, 2, 3, 4].map((v2) => {
              let obj2 = conditionApiData.find(
                (o2) => o2.fieldsId === v.id && o2.educateYear === v2.toString()
              );
              return {
                year: v2,
                isYearCheck: obj2 ? true : false,
                graduateTypes: allGraduateTypes.map((v3) => {
                  let obj3 = conditionApiData.find(
                    (o3) =>
                      o3.fieldsId === v.id &&
                      o3.educateYear === v2.toString() &&
                      o3.graduateTypesId === v3.id
                  );
                  return {
                    id: v3.id,
                    name: v3.name,
                    isGraduateTypesCheck: obj3 ? true : false,
                  };
                }),
              };
            }),
          };
        } else {
          return {
            fields: {
              id: v.id,
              level: v.level,
              name: v.name,
              type: v.type,
              isFieldsCheck: false,
            },
            conditions: [1, 2, 3, 4].map((v2) => {
              return {
                year: v2,
                isYearCheck: false,
                graduateTypes: allGraduateTypes.map((v3) => {
                  return {
                    id: v3.id,
                    name: v3.name,
                    isGraduateTypesCheck: false,
                  };
                }),
              };
            }),
          };
        }
      });
      // console.log("mapDataEdit =>", mapDataEdits);
      // console.log("conditionApiData", conditionApiData);
      setMapData(mapDataEdits);
    }

    const values = {
      layout: "vertical",
      name: data.dharma_onyear_name,
      onyearTime: [
        moment(data.dharma_onyear_startOnyearTime),
        moment(data.dharma_onyear_endOnyearTime),
      ],
    };
    // console.log(values);
    formRegister.setFieldsValue(values);
    setIsModalRegisterVisible(true);
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            span: 6,
          },
          wrapperCol: {
            span: 18,
          },
        }
      : null;

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const renderAvatar = (avatar) => {
    if (avatar) {
      return (
        <Image
          style={{
            width: 160,
            height: 90,
            backgroundColor: "#CCCCCC",
            objectFit: "cover",
          }}
          src={avatar}
        />
      );
    }
    return (
      <div
        style={{
          width: 160,
          height: 90,
          backgroundColor: "#CCCCCC",
        }}
        icon={<UserOutlined />}
      />
    );
  };
  const columns = [
    {
      title: "ภาพปกกิจกรรม",
      dataIndex: "b.dharma_onyear_image",
      width: 200,
      render: (_, record) =>
        allData.length >= 1 ? renderAvatar(record.dharma_onyear_image) : null,
    },
    {
      title: "รหัส",
      width: 150,
      dataIndex: "dharma_onyear_id",
      key: "dharma_onyear_id",
      ...getColumnSearchProps("dharma_onyear_id"),
      sorter: (a, b) => a.dharma_onyear_id - b.dharma_onyear_id,
      sortOrder:
        sortedInfo.columnKey === "dharma_onyear_id" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "หัวข้อกิจกรรม",
      dataIndex: "dharma_onyear_name",
      key: "dharma_onyear_name",
      ...getColumnSearchProps("dharma_onyear_name"),
      sorter: (a, b) => a.prefixs_name.localeCompare(b.prefixs_name),
      sortOrder:
        sortedInfo.columnKey === "dharma_onyear_name" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "สถานที่",
      dataIndex: "rooms_name",
      key: "rooms_name",
      ...getColumnSearchProps("rooms_name"),
      sorter: (a, b) => a.rooms_name.localeCompare(b.rooms_name),
      sortOrder:
        sortedInfo.columnKey === "rooms_name" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "วันเวลาเริ่ม",
      dataIndex: "dharma_onyear_startOnyearTime",
      key: "dharma_onyear_startOnyearTime",
      ...getColumnSearchProps("dharma_onyear_startOnyearTime"),
      sorter: (a, b) =>
        a.dharma_onyear_startOnyearTime.localeCompare(
          b.dharma_onyear_startOnyearTime
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_onyear_startOnyearTime"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        moment(record.dharma_onyear_startOnyearTime).format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "วันเวลาสิ้นสุด",
      dataIndex: "dharma_onyear_endOnyearTime",
      key: "dharma_onyear_endOnyearTime",
      ...getColumnSearchProps("dharma_onyear_endOnyearTime"),
      sorter: (a, b) =>
        a.dharma_onyear_endOnyearTime.localeCompare(
          b.dharma_onyear_endOnyearTime
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_onyear_endOnyearTime"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        moment(record.dharma_onyear_endOnyearTime).format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "วันเวลาเริ่มแสดงข้อมูล",
      dataIndex: "dharma_onyear_startShowTime",
      key: "dharma_onyear_startShowTime",
      ...getColumnSearchProps("dharma_onyear_startShowTime"),
      sorter: (a, b) =>
        a.dharma_onyear_startShowTime.localeCompare(
          b.dharma_onyear_startShowTime
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_onyear_startShowTime"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        moment(record.dharma_onyear_startShowTime).format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "วันเวลาสิ้นสุดแสดงข้อมูล",
      dataIndex: "dharma_onyear_endShowTime",
      key: "dharma_onyear_endShowTime",
      ...getColumnSearchProps("dharma_onyear_endShowTime"),
      sorter: (a, b) =>
        a.dharma_onyear_endShowTime.localeCompare(b.dharma_onyear_endShowTime),
      sortOrder:
        sortedInfo.columnKey === "dharma_onyear_endShowTime"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (_, record) =>
        moment(record.dharma_onyear_endShowTime).format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "เลขาประจำกิจกรรม",
      dataIndex: "admins_firstName",
      key: "admins_firstName",
      ...getColumnSearchProps("admins_firstName"),
      sorter: (a, b) => a.admins_firstName.localeCompare(b.admins_firstName),
      sortOrder:
        sortedInfo.columnKey === "admins_firstName" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "วิปัสสนาจารย์ 1",
      dataIndex: "teachers1_firstName",
      key: "teachers1_firstName",
      ...getColumnSearchProps("teachers1_firstName"),
      sorter: (a, b) =>
        a.teachers1_firstName.localeCompare(b.teachers1_firstName),
      sortOrder:
        sortedInfo.columnKey === "teachers1_firstName"
          ? sortedInfo.order
          : null,
      ellipsis: true,
    },
    {
      title: "วิปัสสนาจารย์ 2",
      dataIndex: "teachers2_firstName",
      key: "teachers2_firstName",
      ...getColumnSearchProps("teachers2_firstName"),
      sorter: (a, b) =>
        a.teachers2_firstName.localeCompare(b.teachers2_firstName),
      sortOrder:
        sortedInfo.columnKey === "teachers2_firstName"
          ? sortedInfo.order
          : null,
      ellipsis: true,
    },
    {
      title: "วิปัสสนาจารย์ 3",
      dataIndex: "teachers3_firstName",
      key: "teachers3_firstName",
      ...getColumnSearchProps("teachers3_firstName"),
      sorter: (a, b) =>
        a.teachers3_firstName.localeCompare(b.teachers3_firstName),
      sortOrder:
        sortedInfo.columnKey === "teachers3_firstName"
          ? sortedInfo.order
          : null,
      ellipsis: true,
    },
    {
      title: "วิปัสสนาจารย์ 4",
      dataIndex: "teachers4_firstName",
      key: "teachers4_firstName",
      ...getColumnSearchProps("teachers4_firstName"),
      sorter: (a, b) =>
        a.teachers4_firstName.localeCompare(b.teachers4_firstName),
      sortOrder:
        sortedInfo.columnKey === "teachers4_firstName"
          ? sortedInfo.order
          : null,
      ellipsis: true,
    },
    {
      title: "วิปัสสนาจารย์ 5",
      dataIndex: "teachers5_firstName",
      key: "teachers5_firstName",
      ...getColumnSearchProps("teachers5_firstName"),
      sorter: (a, b) =>
        a.teachers5_firstName.localeCompare(b.teachers5_firstName),
      sortOrder:
        sortedInfo.columnKey === "teachers5_firstName"
          ? sortedInfo.order
          : null,
      ellipsis: true,
    },
    {
      title: "แบบประเมิน",
      dataIndex: "assessment_name",
      key: "assessment_name",
      ...getColumnSearchProps("assessment_name"),
      sorter: (a, b) => a.assessment_name.localeCompare(b.assessment_name),
      sortOrder:
        sortedInfo.columnKey === "assessment_name" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "สถานะการใช้งาน",
      dataIndex: "dharma_onyear_isActive",
      sorter: (a, b) =>
        JSON.stringify(a.dharma_onyear_isActive).localeCompare(
          JSON.stringify(b.dharma_onyear_isActive)
        ),
      sortOrder:
        sortedInfo.columnKey === "dharma_onyear_isActive"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      width: 120,
      render: (_, record) =>
        allData.length >= 1 ? (
          <Switch
            checkedChildren="เปิด "
            unCheckedChildren="ปิด "
            checked={record.dharma_onyear_isActive}
            onChange={(e) => handleSwitch(record, e)}
          />
        ) : null,
    },
    {
      title: "ลงทะเบียนแบบเจาะจง",
      dataIndex: "operation",
      fixed: "right",
      width: 120,
      render: (_, record) =>
        allData.length >= 1 ? (
          <>
            <Typography.Link
              onClick={() => openRegister(record)}
              style={{ marginLeft: 6 }}
            >
              {"ลงทะเบียน"}
            </Typography.Link>
          </>
        ) : null,
    },
    {
      title: "แก้ไข",
      dataIndex: "operation",
      fixed: "right",
      width: 120,
      render: (_, record) =>
        allData.length >= 1 ? (
          <>
            <Typography.Link
              onClick={() => openEdit(record)}
              style={{ marginLeft: 6 }}
            >
              {"แก้ไข"}
            </Typography.Link>
          </>
        ) : null,
    },
  ];

  const handleOk = () => {
    const checkFileds = mapData.filter((v) => v.fields.isFieldsCheck === true);
    if (checkFileds.length <= 0) {
      setIsModalVisibleFilterRequired(true);
      return false;
    }
    setLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        const formData = new FormData();
        formData.append("file", ImageFiles);
        formData.append("name", values.name);
        formData.append("roomId", values.roomId);
        formData.append("detail", values.detail || "");
        formData.append("startOnyearTime", values.onyearTime[0]._d.toJSON());
        formData.append("endOnyearTime", values.onyearTime[1]._d.toJSON());
        formData.append("startShowTime", values.showTime[0]._d.toJSON());
        formData.append("endShowTime", values.showTime[1]._d.toJSON());
        formData.append("teacher1_by", values.teacher1_by);
        formData.append("teacher2_by", values.teacher2_by || 0);
        formData.append("teacher3_by", values.teacher3_by || 0);
        formData.append("teacher4_by", values.teacher4_by || 0);
        formData.append("teacher5_by", values.teacher5_by || 0);
        formData.append("isActive", values.isActive);
        formData.append("secretary_by", values.secretary);
        formData.append("assessmentId", values.assessment);
        formData.append("conditions", JSON.stringify(mapData));
        const res = await createDharmaOnyear(formData);
        if (!res._error) {
          initAllData();
          setIsModalVisible(false);
          setImageFiles(null);
          setImageShow(null);
          form.resetFields();
        }
        mapConditionData(allFields, allGraduateTypes);
        setLoading(false);
      })
      .catch((info) => {
        setLoading(false);
      });
  };
  const handleEditOk = () => {
    const checkFileds = mapDataEdit.filter(
      (v) => v.fields.isFieldsCheck === true
    );
    if (checkFileds.length <= 0) {
      setIsModalVisibleFilterRequired(true);
      return false;
    }
    setLoading(true);
    formEdit
      .validateFields()
      .then(async (values) => {
        const formData = new FormData();
        formData.append("file", ImageFiles);
        formData.append("id", editId);
        formData.append("name", values.name);
        formData.append("roomId", values.roomId);
        formData.append("detail", values.detail || "");
        formData.append("startOnyearTime", values.onyearTime[0]._d.toJSON());
        formData.append("endOnyearTime", values.onyearTime[1]._d.toJSON());
        formData.append("startShowTime", values.showTime[0]._d.toJSON());
        formData.append("endShowTime", values.showTime[1]._d.toJSON());
        formData.append("teacher1_by", values.teacher1_by);
        formData.append("teacher2_by", values.teacher2_by || 0);
        formData.append("teacher3_by", values.teacher3_by || 0);
        formData.append("teacher4_by", values.teacher4_by || 0);
        formData.append("teacher5_by", values.teacher5_by || 0);
        formData.append("isActive", values.isActive);
        formData.append("secretary_by", values.secretary);
        formData.append("assessmentId", values.assessment);
        formData.append("conditions", JSON.stringify(mapDataEdit));
        const res = await updateDharmaOnyear(formData);
        var newData = res.data[0];
        if (!res._error) {
          const updateData = allData.map((item) => {
            if (item.dharma_onyear_id === editId) {
              return {
                ...item,
                ...newData,
              };
            }
            return item;
          });
          setAllData(updateData);
          setIsModalEditVisible(false);
          setImageFiles(null);
          setImageShow(null);
          formEdit.resetFields();
        }
        setLoading(false);
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  const handleRegisterOk = async () => {
    if (isRegisterCompleted) {
      setIsModalConfrim(true);
      return;
    } else {
      await handleSubmit();
    }
  };

  const handleSubmit = async () => {
    setIsModalConfrim(false);
    const checkFileds = mapData.filter((v) => v.fields.isFieldsCheck === true);
    if (checkFileds.length <= 0) {
      setIsModalVisibleFilterRequired(true);
      return false;
    }
    setLoading(true);
    const data = { id: registerId, conditions: mapData };
    const res = await adminRegister(data);
    if (!res._error) {
      if (res.code === "00100") {
        setIsMaxSeats(true);
        setMaxSeatsText(res.message);
        setLoading(false);
        return;
      } else {
        formRegister.resetFields();
        setIsModalRegisterVisible(false);
        mapConditionData(allFields, allGraduateTypes);
        setLoading(false);
      }
    }
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageFiles(event.target.files[0]);
      setImageShow(URL.createObjectURL(event.target.files[0]));
    }
  };

  const onChangeOnyearTime = (value, dateString) => {
    // console.log("Selected Time: ", value);
    // console.log("Formatted Selected Time: ", dateString);
  };
  const onOkOnyearTime = (value) => {
    // console.log("onOk: ", value);
  };

  const handleSelectRoom = (data) => {
    const values = {
      roomId: data.rooms_id,
    };
    // console.log(values);
    form.setFieldsValue(values);
    formEdit.setFieldsValue(values);
    setSelectRoom(data.rooms_id);
  };
  const handleSelectRoomOption = (e) => {
    // console.log(e);
    const values = {
      roomId: e,
    };
    form.setFieldsValue(values);
    formEdit.setFieldsValue(values);
    setSelectRoom(e);
  };

  const onSelectFiled = (e, fieldId) => {
    const isCheck = e.target.checked;
    const mapGraduateTypes = allGraduateTypes.map((v) => {
      return {
        id: v.id,
        name: v.name,
        isGraduateTypesCheck: isCheck,
      };
    });
    const mapYearCondition = [1, 2, 3, 4].map((v) => {
      return {
        year: v,
        isYearCheck: isCheck,
        graduateTypes: mapGraduateTypes,
      };
    });
    const newMapData = mapData.map((v) => {
      if (v.fields.id === fieldId) {
        return {
          fields: {
            id: v.fields.id,
            level: v.fields.level,
            name: v.fields.name,
            type: v.fields.type,
            isFieldsCheck: isCheck,
          },
          conditions: mapYearCondition,
        };
      } else {
        return v;
      }
    });
    setMapData(newMapData);
  };

  const onSelectYear = (e, fieldId, year) => {
    const isCheck = e.target.checked;

    const isFieldsCheck = (data) => {
      var isFieldsCheck = false;
      if (!isCheck) {
        const checkYear = data.conditions.filter(
          ({ isYearCheck }) => isYearCheck === true
        );
        if (checkYear.length > 1) {
          isFieldsCheck = true;
        }
      }
      return isFieldsCheck;
    };

    const mapGraduateTypes = allGraduateTypes.map((v) => {
      return {
        id: v.id,
        name: v.name,
        isGraduateTypesCheck: isCheck,
      };
    });
    const newMapData = mapData.map((objData, index) => {
      if (objData.fields.id === fieldId) {
        return {
          fields: {
            id: objData.fields.id,
            level: objData.fields.level,
            name: objData.fields.name,
            type: objData.fields.type,
            isFieldsCheck: isCheck ? isCheck : isFieldsCheck(objData),
          },
          conditions: objData.conditions.map((v) => {
            if (v.year === year) {
              return {
                year: v.year,
                isYearCheck: isCheck,
                graduateTypes: mapGraduateTypes,
              };
            } else {
              return v;
            }
          }),
        };
      } else {
        return objData;
      }
    });
    setMapData(newMapData);
  };

  const onSelectGraduateTypes = (e, fieldId, year, graduateId) => {
    const isCheck = e.target.checked;
    const isFieldsCheck = (data) => {
      var sum = 0;
      if (!isCheck) {
        for (let j = 0; j < data.conditions.length; j++) {
          const graduateTypes = data.conditions[j].graduateTypes;
          for (let i = 0; i < graduateTypes.length; i++) {
            if (graduateTypes[i].isGraduateTypesCheck === true) {
              sum++;
            }
          }
        }
      }
      return sum > 1 ? true : false;
    };
    const isYearCheck = (data) => {
      var isGraduateTypesCheck = false;
      if (!isCheck) {
        const checkGraduateTypesCheck = data.filter(
          ({ isGraduateTypesCheck }) => isGraduateTypesCheck === true
        );
        if (checkGraduateTypesCheck.length > 1) {
          isGraduateTypesCheck = true;
        }
      }
      return isGraduateTypesCheck;
    };
    const newMapData = mapData.map((objData, index) => {
      if (objData.fields.id === fieldId) {
        return {
          fields: {
            id: objData.fields.id,
            level: objData.fields.level,
            name: objData.fields.name,
            type: objData.fields.type,
            isFieldsCheck: isCheck ? isCheck : isFieldsCheck(objData),
          },
          conditions: objData.conditions.map((v) => {
            if (v.year === year) {
              return {
                year: v.year,
                isYearCheck: isCheck ? isCheck : isYearCheck(v.graduateTypes),
                graduateTypes: v.graduateTypes.map((g) => {
                  if (g.id === graduateId) {
                    return {
                      id: g.id,
                      name: g.name,
                      isGraduateTypesCheck: isCheck,
                    };
                  }
                  return g;
                }),
              };
            } else {
              return v;
            }
          }),
        };
      } else {
        return objData;
      }
    });
    setMapData(newMapData);
  };

  // Edit

  const onSelectFiledEdit = (e, fieldId) => {
    const isCheck = e.target.checked;
    const mapGraduateTypes = allGraduateTypes.map((v) => {
      return {
        id: v.id,
        name: v.name,
        isGraduateTypesCheck: isCheck,
      };
    });
    const mapYearCondition = [1, 2, 3, 4].map((v) => {
      return {
        year: v,
        isYearCheck: isCheck,
        graduateTypes: mapGraduateTypes,
      };
    });
    const newMapData = mapDataEdit.map((v) => {
      if (v.fields.id === fieldId) {
        return {
          fields: {
            id: v.fields.id,
            level: v.fields.level,
            name: v.fields.name,
            type: v.fields.type,
            isFieldsCheck: isCheck,
          },
          conditions: mapYearCondition,
        };
      } else {
        return v;
      }
    });
    setMapDataEdit(newMapData);
  };

  const onSelectYearEdit = (e, fieldId, year) => {
    const isCheck = e.target.checked;

    const isFieldsCheck = (data) => {
      var isFieldsCheck = false;
      if (!isCheck) {
        const checkYear = data.conditions.filter(
          ({ isYearCheck }) => isYearCheck === true
        );
        if (checkYear.length > 1) {
          isFieldsCheck = true;
        }
      }
      return isFieldsCheck;
    };

    const mapGraduateTypes = allGraduateTypes.map((v) => {
      return {
        id: v.id,
        name: v.name,
        isGraduateTypesCheck: isCheck,
      };
    });
    const newMapData = mapDataEdit.map((objData, index) => {
      if (objData.fields.id === fieldId) {
        return {
          fields: {
            id: objData.fields.id,
            level: objData.fields.level,
            name: objData.fields.name,
            type: objData.fields.type,
            isFieldsCheck: isCheck ? isCheck : isFieldsCheck(objData),
          },
          conditions: objData.conditions.map((v) => {
            if (v.year === year) {
              return {
                year: v.year,
                isYearCheck: isCheck,
                graduateTypes: mapGraduateTypes,
              };
            } else {
              return v;
            }
          }),
        };
      } else {
        return objData;
      }
    });
    setMapDataEdit(newMapData);
  };

  const onSelectGraduateTypesEdit = (e, fieldId, year, graduateId) => {
    const isCheck = e.target.checked;
    const isFieldsCheck = (data) => {
      var sum = 0;
      if (!isCheck) {
        for (let j = 0; j < data.conditions.length; j++) {
          const graduateTypes = data.conditions[j].graduateTypes;
          for (let i = 0; i < graduateTypes.length; i++) {
            if (graduateTypes[i].isGraduateTypesCheck === true) {
              sum++;
            }
          }
        }
      }
      return sum > 1 ? true : false;
    };
    const isYearCheck = (data) => {
      var isGraduateTypesCheck = false;
      if (!isCheck) {
        const checkGraduateTypesCheck = data.filter(
          ({ isGraduateTypesCheck }) => isGraduateTypesCheck === true
        );
        if (checkGraduateTypesCheck.length > 1) {
          isGraduateTypesCheck = true;
        }
      }
      return isGraduateTypesCheck;
    };
    const newMapData = mapDataEdit.map((objData, index) => {
      if (objData.fields.id === fieldId) {
        return {
          fields: {
            id: objData.fields.id,
            level: objData.fields.level,
            name: objData.fields.name,
            type: objData.fields.type,
            isFieldsCheck: isCheck ? isCheck : isFieldsCheck(objData),
          },
          conditions: objData.conditions.map((v) => {
            if (v.year === year) {
              return {
                year: v.year,
                isYearCheck: isCheck ? isCheck : isYearCheck(v.graduateTypes),
                graduateTypes: v.graduateTypes.map((g) => {
                  if (g.id === graduateId) {
                    return {
                      id: g.id,
                      name: g.name,
                      isGraduateTypesCheck: isCheck,
                    };
                  }
                  return g;
                }),
              };
            } else {
              return v;
            }
          }),
        };
      } else {
        return objData;
      }
    });
    setMapDataEdit(newMapData);
  };

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24}>
          <Row gutter={[24, 0]}>
            <Col xs={24} className="mb-24">
              <Card
                className="header-solid h-full ant-card-p-0"
                title={
                  <>
                    <Row
                      gutter={[24, 0]}
                      className="ant-row-flex ant-row-flex-middle"
                    >
                      <Col xs={24} md={12}>
                        <h6 className="font-semibold m-0">
                          ข้อมูลปฏิบัติธรรมประจำปี
                        </h6>
                      </Col>
                      <Col xs={24} md={12} className="d-flex">
                        <Button
                          onClick={() => setIsModalVisible(true)}
                          type="ghost"
                          icon={<PlusCircleOutlined />}
                        >
                          เพิ่มข้อมูลปฏิบัติธรรมประจำปี
                        </Button>
                      </Col>
                    </Row>
                  </>
                }
              >
                <Table
                  sticky
                  dataSource={allData}
                  columns={columns}
                  onChange={handleChange}
                  scroll={{ x: 4800 }}
                  rowKey="dharma_onyear_id"
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "30"],
                  }}
                />
              </Card>
              <Modal
                title="เพิ่มข้อมูลปฏิบัติธรรมประจำปี"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={() => {
                  form.resetFields();
                  setIsModalVisible(false);
                  setImageFiles(null);
                  setImageShow(null);
                  setSelectRoom(null);
                  mapConditionData(allFields, allGraduateTypes);
                }}
                width={"80%"}
                confirmLoading={loading}
                closable={false}
                maskClosable={false}
                footer={[
                  <Button
                    icon={<CloseCircleOutlined />}
                    key="back"
                    onClick={() => {
                      form.resetFields();
                      setIsModalVisible(false);
                      setImageFiles(null);
                      setImageShow(null);
                      setSelectRoom(null);
                      mapConditionData(allFields, allGraduateTypes);
                    }}
                    disabled={loading}
                  >
                    ยกเลิก
                  </Button>,
                  <Button
                    icon={<CheckCircleOutlined />}
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={handleOk}
                  >
                    ยืนยันเพิ่มข้อมูล
                  </Button>,
                ]}
              >
                <>
                  <Form
                    {...formItemLayout}
                    layout={formLayout}
                    form={form}
                    initialValues={{
                      layout: "vertical",
                      conditions: ["ปริญญาตรี", "ปริญญาโท", "ปริญญาเอก"],
                      isActive: true,
                    }}
                  >
                    {ImageShow !== null && (
                      <center>
                        <Image
                          src={ImageShow}
                          style={{
                            width: 320,
                            height: 180,
                            backgroundColor: "#CCCCCC",
                            objectFit: "cover",
                          }}
                        />
                      </center>
                    )}
                    <Form.Item
                      label="ภาพปกกิจกรรม"
                      name="image"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาเลือกภาพปกกิจกรรม!",
                        },
                      ]}
                      extra="ภาพปกกิจกรรมจะแสดงสมบูรณ์ด้วยอัตราส่วน 16:9 "
                    >
                      <Input
                        type={"file"}
                        placeholder="ระบุไฟล์ภาพปกกิจกรรม"
                        accept="image/jpeg, image/png"
                        onChange={onImageChange}
                      />
                    </Form.Item>
                    <Form.Item
                      label="หัวข้อกิจกรรม"
                      name="name"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุข้อกิจกรรม!",
                        },
                      ]}
                    >
                      <Input placeholder="ระบุหัวข้อกิจกรรม" />
                    </Form.Item>
                    <Form.Item
                      label="วันเวลา เริ่มต้น - สิ้นสุด"
                      name="onyearTime"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุวันเวลา เริ่มต้น - สิ้นสุด!",
                        },
                      ]}
                    >
                      <RangePicker
                        placeholder={["วันเวลาเริ่มต้น", "วันเวลาสิ้นสุด"]}
                        showTime={{
                          format: "HH:mm",
                        }}
                        format="YYYY-MM-DD HH:mm"
                        onChange={onChangeOnyearTime}
                        onOk={onOkOnyearTime}
                      />
                    </Form.Item>
                    <Form.Item
                      label="ตั้งค่าวันเริ่มต้น-สิ้นสุดข่าว"
                      name="showTime"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุตั้งค่าวันเริ่มต้น-สิ้นสุดข่าว!",
                        },
                      ]}
                    >
                      <RangePicker
                        showTime={{
                          format: "HH:mm",
                        }}
                        placeholder={["วันเวลาเริ่มต้น", "วันเวลาสิ้นสุด"]}
                        format="YYYY-MM-DD HH:mm"
                        onChange={onChangeOnyearTime}
                        onOk={onOkOnyearTime}
                      />
                    </Form.Item>
                    <Form.Item
                      label="เลือกสถานที่"
                      name="roomId"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุเลือกสถานที่!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="เลือกเลือกสถานที่"
                        onChange={handleSelectRoomOption}
                        value={selectRoom}
                      >
                        {allRooms.map((v) => (
                          <Select.Option key={v.rooms_id} value={v.rooms_id}>
                            {v.rooms_name}
                          </Select.Option>
                        ))}
                      </Select>
                      <Button
                        size="small"
                        onClick={() => setIsOpenRoomSelect(true)}
                        block
                      >
                        หรือคลิกเลือกสถานที่แบบดูรายละเอียด
                      </Button>
                    </Form.Item>
                    <Form.Item
                      label="เลขาประจำกิจกรรม"
                      name="secretary"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุเลขาประจำกิจกรรม!",
                        },
                      ]}
                    >
                      <Select placeholder="เลือกเลขาประจำกิจกรรม">
                        {allSecretary.map((v) => (
                          <Select.Option key={v.id} value={v.id}>
                            {v.firstName} {v.nickname} {v.lastName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="วิปัสสนาจารย์ 1"
                      name="teacher1_by"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุวิปัสสนาจารย์ 1!",
                        },
                      ]}
                    >
                      <Select placeholder="เลือกวิปัสสนาจารย์ 1">
                        {allTeacher.map((v) => (
                          <Select.Option key={v.id} value={v.id}>
                            {v.firstName} {v.nickname} {v.lastName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="วิปัสสนาจารย์ 2" name="teacher2_by">
                      <Select placeholder="เลือกวิปัสสนาจารย์ 2">
                        {allTeacher.map((v) => (
                          <Select.Option key={v.id} value={v.id}>
                            {v.firstName} {v.nickname} {v.lastName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="วิปัสสนาจารย์ 3" name="teacher3_by">
                      <Select placeholder="เลือกวิปัสสนาจารย์ 3">
                        {allTeacher.map((v) => (
                          <Select.Option key={v.id} value={v.id}>
                            {v.firstName} {v.nickname} {v.lastName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="วิปัสสนาจารย์ 4" name="teacher4_by">
                      <Select placeholder="เลือกวิปัสสนาจารย์ 4">
                        {allTeacher.map((v) => (
                          <Select.Option key={v.id} value={v.id}>
                            {v.firstName} {v.nickname} {v.lastName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="วิปัสสนาจารย์ 5" name="teacher5_by">
                      <Select placeholder="เลือกวิปัสสนาจารย์ 5">
                        {allTeacher.map((v) => (
                          <Select.Option key={v.id} value={v.id}>
                            {v.firstName} {v.nickname} {v.lastName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="เงื่อนไขตัวกรอง" name="filter">
                      {mapData.map(({ fields, conditions }, index) => (
                        <div key={index}>
                          <div>
                            <Checkbox
                              onChange={(e) => onSelectFiled(e, fields.id)}
                              checked={fields.isFieldsCheck}
                            >
                              ระดับ: {fields.level}, สาขา: {fields.name},
                              ประเภท: {fields.type}
                            </Checkbox>
                          </div>
                          {conditions.map(
                            ({ year, isYearCheck, graduateTypes }, index) => (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  paddingLeft: 24,
                                }}
                              >
                                <div style={{ width: 80 }}>
                                  <Checkbox
                                    onChange={(e) =>
                                      onSelectYear(e, fields.id, year)
                                    }
                                    checked={isYearCheck}
                                  >
                                    ชั้นปี {year}
                                  </Checkbox>
                                </div>
                                <div style={{ marginRight: 10 }}>
                                  ประเภทบัณฑิต:{" "}
                                </div>
                                {graduateTypes.map(
                                  (
                                    { name, id, isGraduateTypesCheck },
                                    index
                                  ) => (
                                    <div key={index}>
                                      <Checkbox
                                        onChange={(e) =>
                                          onSelectGraduateTypes(
                                            e,
                                            fields.id,
                                            year,
                                            id
                                          )
                                        }
                                        checked={isGraduateTypesCheck}
                                      >
                                        {name}
                                      </Checkbox>
                                    </div>
                                  )
                                )}
                              </div>
                            )
                          )}
                          <Divider style={{ margin: 4 }} />
                        </div>
                      ))}
                    </Form.Item>
                    <Form.Item
                      label="แบบประเมิน"
                      name="assessment"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุแบบประเมิน!",
                        },
                      ]}
                    >
                      <Select placeholder="เลือกแบบประเมิน">
                        {allAssessment.map((v) => (
                          <Select.Option
                            key={v.assessment_id}
                            value={v.assessment_id}
                          >
                            {moment(v.assessment_created_dt)
                              .add(543, "year")
                              .format("DD/MM/YYYY")}
                            {" : "}
                            {v.assessment_name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="รายละเอียดเพิ่มเติม" name="detail">
                      <TextArea
                        rows={4}
                        placeholder="ระบุรายละเอียดเพิ่มเติม"
                      />
                    </Form.Item>
                    <Form.Item
                      valuePropName="checked"
                      label="เปิด/ปิดการใช้งาน"
                      name="isActive"
                    >
                      <Switch
                        checkedChildren="เปิด "
                        unCheckedChildren="ปิด "
                        defaultChecked
                      />
                    </Form.Item>
                  </Form>
                </>
              </Modal>

              <Modal
                title="แก้ไขข้อมูลปฏิบัติธรรมประจำปี"
                visible={isModalEditVisible}
                onOk={handleEditOk}
                onCancel={() => {
                  formEdit.resetFields();
                  setIsModalEditVisible(false);
                  setImageFiles(null);
                  setImageShow(null);
                  setSelectRoom(null);
                }}
                width={"80%"}
                confirmLoading={loading}
                closable={false}
                maskClosable={false}
                footer={[
                  <Button
                    icon={<CloseCircleOutlined />}
                    key="back"
                    onClick={() => {
                      formEdit.resetFields();
                      setIsModalEditVisible(false);
                      setImageFiles(null);
                      setImageShow(null);
                      setSelectRoom(null);
                    }}
                    disabled={loading}
                  >
                    ยกเลิก
                  </Button>,
                  <Button
                    icon={<CheckCircleOutlined />}
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={handleEditOk}
                  >
                    ยืนยันแก้ไขข้อมูล
                  </Button>,
                ]}
              >
                <>
                  <Form
                    {...formItemLayout}
                    layout={formLayout}
                    form={formEdit}
                    initialValues={{
                      layout: "vertical",
                      role: "นิสิต",
                      isActive: true,
                    }}
                  >
                    {ImageShow !== null && (
                      <center>
                        <Image
                          src={ImageShow}
                          style={{
                            width: 320,
                            height: 180,
                            backgroundColor: "#CCCCCC",
                            objectFit: "cover",
                          }}
                        />
                      </center>
                    )}
                    <Form.Item label="ภาพปกกิจกรรม" name="image">
                      <Input
                        type={"file"}
                        placeholder="ระบุไฟล์ภาพปกกิจกรรม"
                        accept="image/jpeg, image/png"
                        onChange={onImageChange}
                      />
                    </Form.Item>
                    <Form.Item
                      label="หัวข้อกิจกรรม"
                      name="name"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุข้อกิจกรรม!",
                        },
                      ]}
                    >
                      <Input placeholder="ระบุหัวข้อกิจกรรม" />
                    </Form.Item>
                    <Form.Item
                      label="วันเวลา เริ่มต้น - สิ้นสุด"
                      name="onyearTime"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุวันเวลา เริ่มต้น - สิ้นสุด!",
                        },
                      ]}
                    >
                      <RangePicker
                        placeholder={["วันเวลาเริ่มต้น", "วันเวลาสิ้นสุด"]}
                        showTime={{
                          format: "HH:mm",
                        }}
                        format="YYYY-MM-DD HH:mm"
                        onChange={onChangeOnyearTime}
                        onOk={onOkOnyearTime}
                      />
                    </Form.Item>
                    <Form.Item
                      label="ตั้งค่าวันเริ่มต้น-สิ้นสุดข่าว"
                      name="showTime"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุตั้งค่าวันเริ่มต้น-สิ้นสุดข่าว!",
                        },
                      ]}
                    >
                      <RangePicker
                        showTime={{
                          format: "HH:mm",
                        }}
                        placeholder={["วันเวลาเริ่มต้น", "วันเวลาสิ้นสุด"]}
                        format="YYYY-MM-DD HH:mm"
                        onChange={onChangeOnyearTime}
                        onOk={onOkOnyearTime}
                      />
                    </Form.Item>
                    <Form.Item
                      label="เลือกสถานที่"
                      name="roomId"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุเลือกสถานที่!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="เลือกเลือกสถานที่"
                        onChange={handleSelectRoomOption}
                        value={selectRoom}
                      >
                        {allRooms.map((v) => (
                          <Select.Option key={v.rooms_id} value={v.rooms_id}>
                            {v.rooms_name}
                          </Select.Option>
                        ))}
                      </Select>
                      <Button
                        size="small"
                        onClick={() => setIsOpenRoomSelect(true)}
                        block
                      >
                        หรือคลิกเลือกสถานที่แบบดูรายละเอียด
                      </Button>
                    </Form.Item>
                    <Form.Item
                      label="เลขาประจำกิจกรรม"
                      name="secretary"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุเลขาประจำกิจกรรม!",
                        },
                      ]}
                    >
                      <Select placeholder="เลือกเลขาประจำกิจกรรม">
                        {allSecretary.map((v) => (
                          <Select.Option key={v.id} value={v.id}>
                            {v.firstName} {v.nickname} {v.lastName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="วิปัสสนาจารย์ 1"
                      name="teacher1_by"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุวิปัสสนาจารย์ 1!",
                        },
                      ]}
                    >
                      <Select placeholder="เลือกวิปัสสนาจารย์ 1">
                        {allTeacher.map((v) => (
                          <Select.Option key={v.id} value={v.id}>
                            {v.firstName} {v.nickname} {v.lastName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="วิปัสสนาจารย์ 2" name="teacher2_by">
                      <Select placeholder="เลือกวิปัสสนาจารย์ 2">
                        <Select.Option value={0}>
                          เลือกวิปัสสนาจารย์ 2
                        </Select.Option>
                        {allTeacher.map((v) => (
                          <Select.Option key={v.id} value={v.id}>
                            {v.firstName} {v.nickname} {v.lastName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="วิปัสสนาจารย์ 3" name="teacher3_by">
                      <Select placeholder="เลือกวิปัสสนาจารย์ 3">
                        <Select.Option value={0}>
                          เลือกวิปัสสนาจารย์ 3
                        </Select.Option>
                        {allTeacher.map((v) => (
                          <Select.Option key={v.id} value={v.id}>
                            {v.firstName} {v.nickname} {v.lastName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="วิปัสสนาจารย์ 4" name="teacher4_by">
                      <Select placeholder="เลือกวิปัสสนาจารย์ 4">
                        <Select.Option value={0}>
                          เลือกวิปัสสนาจารย์ 4
                        </Select.Option>
                        {allTeacher.map((v) => (
                          <Select.Option key={v.id} value={v.id}>
                            {v.firstName} {v.nickname} {v.lastName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="วิปัสสนาจารย์ 5" name="teacher5_by">
                      <Select placeholder="เลือกวิปัสสนาจารย์ 5">
                        <Select.Option value={0}>
                          เลือกวิปัสสนาจารย์ 5
                        </Select.Option>
                        {allTeacher.map((v) => (
                          <Select.Option key={v.id} value={v.id}>
                            {v.firstName} {v.nickname} {v.lastName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="เงื่อนไขตัวกรอง" name="filter">
                      {mapDataEdit.map(({ fields, conditions }, index) => (
                        <div key={index}>
                          <div>
                            <Checkbox
                              onChange={(e) => onSelectFiledEdit(e, fields.id)}
                              checked={fields.isFieldsCheck}
                            >
                              ระดับ: {fields.level}, สาขา: {fields.name},
                              ประเภท: {fields.type}
                            </Checkbox>
                          </div>
                          {conditions.map(
                            ({ year, isYearCheck, graduateTypes }, index) => (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  paddingLeft: 24,
                                }}
                              >
                                <div style={{ width: 80 }}>
                                  <Checkbox
                                    onChange={(e) =>
                                      onSelectYearEdit(e, fields.id, year)
                                    }
                                    checked={isYearCheck}
                                  >
                                    ชั้นปี {year}
                                  </Checkbox>
                                </div>
                                <div style={{ marginRight: 10 }}>
                                  ประเภทบัณฑิต:{" "}
                                </div>
                                {graduateTypes.map(
                                  (
                                    { name, id, isGraduateTypesCheck },
                                    index
                                  ) => (
                                    <div key={index}>
                                      <Checkbox
                                        onChange={(e) =>
                                          onSelectGraduateTypesEdit(
                                            e,
                                            fields.id,
                                            year,
                                            id
                                          )
                                        }
                                        checked={isGraduateTypesCheck}
                                      >
                                        {name}
                                      </Checkbox>
                                    </div>
                                  )
                                )}
                              </div>
                            )
                          )}
                          <Divider style={{ margin: 4 }} />
                        </div>
                      ))}
                    </Form.Item>
                    <Form.Item
                      label="แบบประเมิน"
                      name="assessment"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุแบบประเมิน!",
                        },
                      ]}
                    >
                      <Select placeholder="เลือกแบบประเมิน">
                        {allAssessment.map((v) => (
                          <Select.Option
                            key={v.assessment_id}
                            value={v.assessment_id}
                          >
                            {moment(v.assessment_created_dt)
                              .add(543, "year")
                              .format("DD/MM/YYYY")}
                            {" : "}
                            {v.assessment_name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="รายละเอียดเพิ่มเติม" name="detail">
                      <TextArea
                        rows={4}
                        placeholder="ระบุรายละเอียดเพิ่มเติม"
                      />
                    </Form.Item>
                    <Form.Item
                      valuePropName="checked"
                      label="เปิด/ปิดการใช้งาน"
                      name="isActive"
                    >
                      <Switch
                        checkedChildren="เปิด "
                        unCheckedChildren="ปิด "
                        defaultChecked
                      />
                    </Form.Item>
                  </Form>
                </>
              </Modal>
              <RoomSelect
                isOpen={isOpenRoomSelect}
                onClose={() => setIsOpenRoomSelect(false)}
                onSubmit={(data) => handleSelectRoom(data)}
              />
              <Modal
                title="กรุณาระบุเงื่อนไข"
                visible={isModalVisibleFilterRequired}
                onOk={() => setIsModalVisibleFilterRequired(false)}
                onCancel={() => setIsModalVisibleFilterRequired(false)}
                zIndex={99999}
                footer={[
                  <Button
                    key="ok"
                    onClick={() => setIsModalVisibleFilterRequired(false)}
                  >
                    ตกลง
                  </Button>,
                ]}
              >
                <p>กรุณาระบุเงื่อนไขตัวกรองกิจกรรม!</p>
              </Modal>

              <Modal
                title="ลงทะเบียนปฏิบัติธรรมประจำปีแบบเจาะจง"
                visible={isModalRegisterVisible}
                onOk={handleRegisterOk}
                onCancel={() => {
                  formRegister.resetFields();
                  setIsModalRegisterVisible(false);
                  setImageFiles(null);
                  mapConditionData(allFields, allGraduateTypes);
                }}
                width={"80%"}
                confirmLoading={loading}
                closable={false}
                maskClosable={false}
                footer={[
                  <Button
                    icon={<CloseCircleOutlined />}
                    key="back"
                    onClick={() => {
                      formRegister.resetFields();
                      setIsModalRegisterVisible(false);
                      setImageFiles(null);
                      mapConditionData(allFields, allGraduateTypes);
                    }}
                    disabled={loading}
                  >
                    ยกเลิก
                  </Button>,
                  <Button
                    icon={<CheckCircleOutlined />}
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={handleRegisterOk}
                    disabled={isStartReport}
                  >
                    ยืนยันลงทะเบียน
                  </Button>,
                ]}
              >
                <>
                  <Form
                    {...formItemLayout}
                    layout={formLayout}
                    form={formRegister}
                    initialValues={{
                      layout: "vertical",
                      role: "นิสิต",
                      isActive: true,
                    }}
                  >
                    {ImageShow !== null && (
                      <center>
                        <Image
                          src={ImageShow}
                          style={{
                            width: 320,
                            height: 180,
                            backgroundColor: "#CCCCCC",
                            objectFit: "cover",
                          }}
                        />
                      </center>
                    )}
                    <Form.Item label="หัวข้อกิจกรรม" name="name" disabled>
                      <Input placeholder="ระบุหัวข้อกิจกรรม" disabled />
                    </Form.Item>
                    <Form.Item
                      label="วันเวลา เริ่มต้น - สิ้นสุด"
                      name="onyearTime"
                      disabled
                    >
                      <RangePicker
                        placeholder={["วันเวลาเริ่มต้น", "วันเวลาสิ้นสุด"]}
                        showTime={{
                          format: "HH:mm",
                        }}
                        format="YYYY-MM-DD HH:mm"
                        onChange={onChangeOnyearTime}
                        onOk={onOkOnyearTime}
                        disabled
                      />
                    </Form.Item>

                    {!!isStartReport && (
                      <div>
                        <Alert
                          message="กิจกรรมนี้เริ่มการรายงานผลปฏิบัติธรมมแล้ว ไม่สามารถลงทะเบียนแบบกลุ่มได้"
                          type="warning"
                        />
                      </div>
                    )}
                    {!isStartReport && (
                      <Form.Item label="เงื่อนไขตัวกรอง" name="filter">
                        {mapData.map(({ fields, conditions }, index) => (
                          <div key={index}>
                            <div>
                              <Checkbox
                                onChange={(e) => onSelectFiled(e, fields.id)}
                                checked={fields.isFieldsCheck}
                              >
                                ระดับ: {fields.level}, สาขา: {fields.name},
                                ประเภท: {fields.type}
                              </Checkbox>
                            </div>
                            {conditions.map(
                              ({ year, isYearCheck, graduateTypes }, index) => (
                                <div
                                  key={index}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    paddingLeft: 24,
                                  }}
                                >
                                  <div style={{ width: 80 }}>
                                    <Checkbox
                                      onChange={(e) =>
                                        onSelectYear(e, fields.id, year)
                                      }
                                      checked={isYearCheck}
                                    >
                                      ชั้นปี {year}
                                    </Checkbox>
                                  </div>
                                  <div style={{ marginRight: 10 }}>
                                    ประเภทบัณฑิต:{" "}
                                  </div>
                                  {graduateTypes.map(
                                    (
                                      { name, id, isGraduateTypesCheck },
                                      index
                                    ) => (
                                      <div key={index}>
                                        <Checkbox
                                          onChange={(e) =>
                                            onSelectGraduateTypes(
                                              e,
                                              fields.id,
                                              year,
                                              id
                                            )
                                          }
                                          checked={isGraduateTypesCheck}
                                        >
                                          {name}
                                        </Checkbox>
                                      </div>
                                    )
                                  )}
                                </div>
                              )
                            )}
                            <Divider style={{ margin: 4 }} />
                          </div>
                        ))}
                      </Form.Item>
                    )}
                  </Form>
                </>
              </Modal>
              <RoomSelect
                isOpen={isOpenRoomSelect}
                onClose={() => setIsOpenRoomSelect(false)}
                onSubmit={(data) => handleSelectRoom(data)}
              />
              <Modal
                title="กรุณาระบุเงื่อนไข"
                visible={isModalVisibleFilterRequired}
                onOk={() => setIsModalVisibleFilterRequired(false)}
                onCancel={() => setIsModalVisibleFilterRequired(false)}
                zIndex={99999}
                footer={[
                  <Button
                    key="ok"
                    onClick={() => setIsModalVisibleFilterRequired(false)}
                  >
                    ตกลง
                  </Button>,
                ]}
              >
                <p>กรุณาระบุเงื่อนไขตัวกรองกิจกรรม!</p>
              </Modal>
              <Modal
                title="ท่านต้องการยืนยัน? "
                visible={isModalConfrim}
                onOk={handleSubmit}
                onCancel={() => setIsModalConfrim(false)}
                centered
                footer={[
                  <Button
                    icon={<CheckCircleOutlined />}
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={handleSubmit}
                  >
                    ยืนยัน
                  </Button>,
                  <Button
                    icon={<CloseCircleOutlined />}
                    key="back"
                    onClick={() => {
                      setIsModalConfrim(false);
                    }}
                    disabled={loading}
                  >
                    ยกเลิก
                  </Button>,
                ]}
              >
                <p>
                  กิจกรรมนี้ได้ลงทะเบียนแบบกลุ่มเรียบร้อยไปแล้ว
                  หากยืนยันจะเป็นการลงทะเบียนกลุ่มแบบใหม่
                  ข้อมูลเก่าที่เคยลงทะเบียนแล้วในกิจกรรมนี้จะถูกลบ
                  และแทนที่ด้วยข้อมูลใหม่ที่เลือก ท่านแน่ใจในการยืนยันหรือไม่?
                </p>
              </Modal>

              <Modal
                title={`แจ้งเตือน! เกิดข้อผิดพลาด!`}
                visible={isMaxSeats}
                onCancel={() => {
                  setIsMaxSeats(false);
                  setMaxSeatsText("");
                }}
                footer={[
                  <Button
                    icon={<CheckCircleOutlined />}
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={() => {
                      setIsMaxSeats(false);
                      setMaxSeatsText("");
                    }}
                  >
                    เข้าใจแล้ว
                  </Button>,
                ]}
              >
                <Alert message={maxSeatsText} type="warning" />
              </Modal>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default DharmaOnyearData;
