import { APPCONFIG } from "../config/app.config";
import { RunApiRefreshToken } from "../utils/RunApiRefreshToken";

export const findAllCertificateByUserId = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/certificate/user`;
  const res = await RunApiRefreshToken("GET", url);
  return res;
};

export const findAllAdminCertificateByUserId = async (userId) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/certificate/user/${userId}`;
  const res = await RunApiRefreshToken("GET", url);
  return res;
};

export const downloadCertificateByUserId = async (certId) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/certificate/download/${certId}`;
  const res = await RunApiRefreshToken("DOWNLOAD", url);
  return res;
};

export const downloadAdminCertificateByUserId = async (userId, certId) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/certificate/download/admin/${userId}/${certId}`;
  const res = await RunApiRefreshToken("DOWNLOAD", url);
  return res;
};
