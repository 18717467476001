import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Input,
  Space,
  Modal,
  Form,
  Switch,
  Select,
  Typography,
  Avatar,
  Image,
} from "antd";
import {
  SearchOutlined,
  PlusCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  UserOutlined,
  ImportOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { AdminChecker } from "../utils/AdminChecker";
import {
  getAllUsers,
  createDatas,
  updateDatas,
} from "../services/UsersServices";
import { getAllPrefixs } from "../services/PrefixsService";
import { getAllGraduateTypes } from "../services/GraduateTypesService";
import { getAllFields } from "../services/FieldsService";
import { Link } from "react-router-dom";
import { getAllDatas } from "../services/TeacherService";

function Users() {
  AdminChecker();
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [allPrefixs, setAllPrefixs] = useState([]);
  const [allGraduateTypes, setAllGraduateTypes] = useState([]);
  const [allField, setAllField] = useState([]);
  const [allTeacher, setAllTeacher] = useState([]);

  const [ImageFiles, setImageFiles] = useState(null);
  const [ImageShow, setImageShow] = useState(null);

  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [editId, setEditId] = useState(-1);
  const [editPassword, setEditPassword] = useState(-1);

  const [form] = Form.useForm();
  const [formEdit] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");

  useEffect(() => {
    // todo
    // get all User to checker
    initAllData();
  }, []);

  const initAllData = async () => {
    const res = await getAllUsers();
    if (!res._error) {
      setAllData(res.data);
    }
    const prefix = await getAllPrefixs();
    if (!prefix._error) {
      setAllPrefixs(prefix.data);
    }
    const graduateTypes = await getAllGraduateTypes();
    if (!graduateTypes._error) {
      setAllGraduateTypes(graduateTypes.data);
    }
    const field = await getAllFields();
    if (!field._error) {
      setAllField(field.data);
    }
    const teachers = await getAllDatas();
    if (!teachers._error) {
      setAllTeacher(teachers.data);
    }
  };

  const handleSwitch = async (values, e) => {
    const formData = new FormData();
    formData.append("id", values.users_id);
    formData.append("prefixId", values.users_prefixId);
    formData.append("firstName", values.users_firstName);
    formData.append("nickname", values.users_nickname || "");
    formData.append("lastName", values.users_lastName || "");
    formData.append("mobile", values.users_mobile || "");
    formData.append("email", values.users_email || "");
    formData.append("password", values.users_password);
    formData.append("isChangePassword", false);
    formData.append("graduateTypeId", values.users_graduateTypeId);
    formData.append("fieldId", values.users_fieldId);
    formData.append("educateYear", values.users_educateYear);
    formData.append("numOfDharmaMust", values.users_numOfDharmaMust);
    formData.append("numOfDharma", values.users_numOfDharma);
    formData.append(
      "numOfDharmaCompensate",
      values.users_numOfDharmaCompensate
    );
    formData.append("status", values.users_status);
    formData.append("isActive", e);
    formData.append("advisorId", values.users_advisorId);
    formData.append("address", values.users_address);
    formData.append("road", values.users_road);
    formData.append("subdistrict", values.users_subdistrict);
    formData.append("district", values.users_district);
    formData.append("province", values.users_province);
    formData.append("postcode", values.users_postcode);

    const res = await updateDatas(formData);
    var newData = res.data[0];
    if (!res._error) {
      const updateData = allData.map((item) => {
        if (item.users_id === values.users_id) {
          var userActive = false;
          if (e === true || e === 1) {
            userActive = true;
          }
          return { ...item, ...newData, users_isActive: userActive };
        }
        return item;
      });
      // console.log(updateData);
      setAllData(updateData);
    }
  };

  const openEdit = (data) => {
    setEditId(data.users_id);
    setEditPassword(data.users_password);
    setImageShow(data.users_avatar);
    var userActive = false;
    if (data.users_isActive === true || data.users_isActive === 1) {
      userActive = true;
    }
    const values = {
      layout: "vertical",
      code: data.users_code,
      prefix: data.users_prefixId,
      firstName: data.users_firstName,
      nickname: data.users_nickname,
      lastName: data.users_lastName,
      mobile: data.users_mobile,
      email: data.users_email,
      username: data.users_username,
      password: data.users_password,
      isChangePassword: false,
      graduateType: data.users_graduateTypeId,
      field: data.users_fieldId,
      educateYear: data.users_educateYear,
      numOfDharmaMust: data.users_numOfDharmaMust,
      numOfDharma: data.users_numOfDharma,
      numOfDharmaCompensate: data.users_numOfDharmaCompensate,
      status: data.users_status,
      isActive: userActive,
      advisorId: data.users_advisorId,
      address: data.users_address,
      road: data.users_road,
      subdistrict: data.users_subdistrict,
      district: data.users_district,
      province: data.users_province,
      postcode: data.users_postcode,
    };

    formEdit.setFieldsValue(values);
    setIsModalEditVisible(true);
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            span: 6,
          },
          wrapperCol: {
            span: 18,
          },
        }
      : null;

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const renderAvatar = (avatar) => {
    if (avatar) {
      return (
        <Avatar
          style={{
            backgroundColor: "#CCCCCC",
          }}
          src={avatar}
        />
      );
    }
    return (
      <Avatar
        style={{
          backgroundColor: "#CCCCCC",
        }}
        icon={<UserOutlined />}
      />
    );
  };
  const columns = [
    {
      title: "โปรไฟล์",
      dataIndex: "users_avatar",
      width: 80,
      render: (_, record) =>
        allData.length >= 1 ? renderAvatar(record.users_avatar) : null,
    },
    {
      title: "รหัสนักศึกษา",
      dataIndex: "users_code",
      key: "users_code",
      ...getColumnSearchProps("users_code"),
      sorter: (a, b) => a.users_code - b.users_code,
      sortOrder:
        sortedInfo.columnKey === "users_code" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "คำนำหน้า",
      dataIndex: "prefixs_name",
      key: "prefixs_name",
      ...getColumnSearchProps("prefixs_name"),
      sorter: (a, b) => a.prefixs_name.localeCompare(b.prefixs_name),
      sortOrder:
        sortedInfo.columnKey === "prefixs_name" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ชื่อ",
      dataIndex: "users_firstName",
      key: "users_firstName",
      ...getColumnSearchProps("users_firstName"),
      sorter: (a, b) => a.users_firstName.localeCompare(b.users_firstName),
      sortOrder:
        sortedInfo.columnKey === "users_firstName" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ฉายา",
      dataIndex: "users_nickname",
      key: "nickname",
      ...getColumnSearchProps("users_nickname"),
      sorter: (a, b) => a.users_nickname.localeCompare(b.users_nickname),
      sortOrder:
        sortedInfo.columnKey === "users_nickname" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "นามสกุล",
      dataIndex: "users_lastName",
      key: "users_lastName",
      ...getColumnSearchProps("users_lastName"),
      sorter: (a, b) => a.users_lastName.localeCompare(b.users_lastName),
      sortOrder:
        sortedInfo.columnKey === "users_lastName" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "เบอร์โทร",
      dataIndex: "users_mobile",
      key: "users_mobile",
      ...getColumnSearchProps("users_mobile"),
      sorter: (a, b) => a.users_mobile.localeCompare(b.users_mobile),
      sortOrder:
        sortedInfo.columnKey === "users_mobile" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ประเภทบัณฑิต",
      dataIndex: "graduate_types_name",
      key: "graduate_types_name",
      ...getColumnSearchProps("graduate_types_name"),
      sorter: (a, b) =>
        a.graduate_types_name.localeCompare(b.graduate_types_name),
      sortOrder:
        sortedInfo.columnKey === "graduate_types_name"
          ? sortedInfo.order
          : null,
      ellipsis: true,
    },
    {
      title: "สาขา",
      dataIndex: "fields_name",
      key: "fields_name",
      ...getColumnSearchProps("fields_name"),
      sorter: (a, b) => a.fields_name.localeCompare(b.fields_name),
      sortOrder:
        sortedInfo.columnKey === "fields_name" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ปีการศึกษาที่เข้า",
      dataIndex: "users_educateYear",
      key: "users_educateYear",
      ...getColumnSearchProps("users_educateYear"),
      sorter: (a, b) => a.users_educateYear.localeCompare(b.users_educateYear),
      sortOrder:
        sortedInfo.columnKey === "users_educateYear" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "จำนวนวันปฏิบัติธรรมที่คาดหวัง",
      dataIndex: "users_numOfDharmaMust",
      key: "users_numOfDharmaMust",
      ...getColumnSearchProps("users_numOfDharmaMust"),
      sorter: (a, b) =>
        a.users_numOfDharmaMust.localeCompare(b.users_numOfDharmaMust),
      sortOrder:
        sortedInfo.columnKey === "users_numOfDharmaMust"
          ? sortedInfo.order
          : null,
      ellipsis: true,
    },
    {
      title: "จำนวนวันปฏิบัติธรรมที่สำเร็จ",
      dataIndex: "users_numOfDharma",
      key: "users_numOfDharma",
      ...getColumnSearchProps("users_numOfDharma"),
      sorter: (a, b) => a.users_numOfDharma.localeCompare(b.users_numOfDharma),
      sortOrder:
        sortedInfo.columnKey === "users_numOfDharma" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "จำนวนวันปฏิบัติธรรมที่ต้องซ่อม",
      dataIndex: "users_numOfDharmaCompensate",
      key: "users_numOfDharmaCompensate",
      ...getColumnSearchProps("users_numOfDharmaCompensate"),
      sorter: (a, b) =>
        a.users_numOfDharmaCompensate.localeCompare(
          b.users_numOfDharmaCompensate
        ),
      sortOrder:
        sortedInfo.columnKey === "users_numOfDharmaCompensate"
          ? sortedInfo.order
          : null,
      ellipsis: true,
    },
    {
      title: "สถานะ",
      dataIndex: "users_status",
      key: "users_status",
      ...getColumnSearchProps("users_status"),
      sorter: (a, b) => a.users_status.localeCompare(b.users_status),
      sortOrder:
        sortedInfo.columnKey === "users_status" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "ที่ปรึกษา",
      dataIndex: "teachers_firstName",
      key: "teachers_firstName",
      ...getColumnSearchProps("teachers_firstName"),
      sorter: (a, b) =>
        a.teachers_firstName.localeCompare(b.teachers_firstName),
      sortOrder:
        sortedInfo.columnKey === "teachers_firstName" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "สถานะการใช้งาน",
      dataIndex: "users_isActive",
      sorter: (a, b) =>
        JSON.stringify(a.users_isActive).localeCompare(
          JSON.stringify(b.users_isActive)
        ),
      sortOrder:
        sortedInfo.columnKey === "users_isActive" ? sortedInfo.order : null,
      ellipsis: true,
      width: 120,
      render: (_, record) =>
        allData.length >= 1 ? (
          <Switch
            checkedChildren="เปิด "
            unCheckedChildren="ปิด "
            checked={record.users_isActive}
            onChange={(e) => handleSwitch(record, e)}
          />
        ) : null,
    },
    {
      title: "แก้ไข",
      dataIndex: "operation",
      fixed: "right",
      width: 120,
      render: (_, record) =>
        allData.length >= 1 ? (
          <>
            <Typography.Link
              onClick={() => openEdit(record)}
              style={{ marginLeft: 6 }}
            >
              {"แก้ไข"}
            </Typography.Link>
          </>
        ) : null,
    },
  ];

  const handleOk = () => {
    setLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        const formData = new FormData();
        formData.append("file", ImageFiles);
        formData.append("code", values.code);
        formData.append("prefixId", values.prefix);
        formData.append("firstName", values.firstName);
        formData.append("nickname", values.nickname || "");
        formData.append("lastName", values.lastName || "");
        formData.append("mobile", values.mobile || "");
        formData.append("email", values.email || "");
        formData.append("username", values.username);
        formData.append("password", values.password);
        formData.append("graduateTypeId", values.graduateType);
        formData.append("fieldId", values.field);
        formData.append("educateYear", values.educateYear);
        formData.append("numOfDharmaMust", values.numOfDharmaMust);
        formData.append("numOfDharma", values.numOfDharma);
        formData.append("numOfDharmaCompensate", values.numOfDharmaCompensate);
        formData.append("status", values.status);
        formData.append("isActive", values.isActive);
        formData.append("advisorId", values.advisorId);
        formData.append("address", values.address);
        formData.append("road", values.road);
        formData.append("subdistrict", values.subdistrict);
        formData.append("district", values.district);
        formData.append("province", values.province);
        formData.append("postcode", values.postcode);
        const res = await createDatas(formData);
        if (!res._error) {
          initAllData();
          setIsModalVisible(false);
          setImageFiles(null);
          setImageShow(null);
          form.resetFields();
        }
        setLoading(false);
      })
      .catch((info) => {
        setLoading(false);
      });
  };
  const handleEditOk = () => {
    setLoading(true);
    formEdit
      .validateFields()
      .then(async (values) => {
        var usePassword = values.password;
        var checkIsChangePassword = false;
        if (editPassword !== values.password) {
          usePassword = values.password;
          checkIsChangePassword = true;
        }
        const formData = new FormData();
        formData.append("file", ImageFiles);
        formData.append("id", editId);
        formData.append("prefixId", values.prefix);
        formData.append("firstName", values.firstName);
        formData.append("nickname", values.nickname || "");
        formData.append("lastName", values.lastName || "");
        formData.append("mobile", values.mobile || "");
        formData.append("email", values.email || "");
        formData.append("password", usePassword);
        formData.append("isChangePassword", checkIsChangePassword);
        formData.append("graduateTypeId", values.graduateType);
        formData.append("fieldId", values.field);
        formData.append("educateYear", values.educateYear);
        formData.append("numOfDharmaMust", values.numOfDharmaMust);
        formData.append("numOfDharma", values.numOfDharma);
        formData.append("numOfDharmaCompensate", values.numOfDharmaCompensate);
        formData.append("status", values.status);
        formData.append("isActive", values.isActive);
        formData.append("advisorId", values.advisorId);
        formData.append("address", values.address);
        formData.append("road", values.road);
        formData.append("subdistrict", values.subdistrict);
        formData.append("district", values.district);
        formData.append("province", values.province);
        formData.append("postcode", values.postcode);
        const res = await updateDatas(formData);
        var newData = res.data[0];

        if (!res._error) {
          const updateData = allData.map((item) => {
            if (item.users_id === editId) {
              return {
                ...item,
                ...newData,
              };
            }
            return item;
          });
          setAllData(updateData);
          setIsModalEditVisible(false);
          setImageFiles(null);
          setImageShow(null);
          formEdit.resetFields();
        }
        setLoading(false);
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  const checkDuplicateCode = (rule, value, callback) => {
    const checker = allData.filter((v) => v.code === value);
    if (checker.length > 0) {
      callback(` รหัสนักศึกษา ${value} มีการใช้งานแล้ว!`);
    }
    return Promise.resolve();
  };
  const checkDuplicateUsername = (rule, value, callback) => {
    const checker = allData.filter((v) => v.username === value);
    if (checker.length > 0) {
      callback(` username ${value} มีการใช้งานแล้ว!`);
    }
    return Promise.resolve();
  };
  const checkDuplicateEditCode = (rule, value, callback) => {
    const checker = allData.filter((v) => v.code === value);
    if (checker.length > 1) {
      callback(` รหัสนักศึกษา ${value} มีการใช้งานแล้ว!`);
    }
    return Promise.resolve();
  };
  const checkDuplicateEditUsername = (rule, value, callback) => {
    const checker = allData.filter((v) => v.username === value);
    if (checker.length > 1) {
      callback(` username ${value} มีการใช้งานแล้ว!`);
    }
    return Promise.resolve();
  };
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageFiles(event.target.files[0]);
      setImageShow(URL.createObjectURL(event.target.files[0]));
    }
  };

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24}>
          <Row gutter={[24, 0]}>
            <Col xs={24} className="mb-24">
              <Card
                className="header-solid h-full ant-card-p-0"
                title={
                  <>
                    <Row
                      gutter={[24, 0]}
                      className="ant-row-flex ant-row-flex-middle"
                    >
                      <Col xs={24} md={12}>
                        <h6 className="font-semibold m-0">ข้อมูลนิสิต</h6>
                      </Col>
                      <Col xs={24} md={12} className="d-flex">
                        <Link to={"/basic-data/users/import"}>
                          <Button type="ghost" icon={<ImportOutlined />}>
                            นำเข้าข้อมูลนิสิต
                          </Button>
                        </Link>
                        <Button
                          onClick={() => setIsModalVisible(true)}
                          type="ghost"
                          icon={<PlusCircleOutlined />}
                        >
                          เพิ่มข้อมูลนิสิต
                        </Button>
                      </Col>
                    </Row>
                  </>
                }
              >
                <Table
                  sticky
                  dataSource={allData}
                  columns={columns}
                  onChange={handleChange}
                  scroll={{ x: 3800 }}
                  rowKey="users_id"
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "30"],
                  }}
                />
              </Card>
              <Modal
                title="เพิ่มข้อมูลนิสิต"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={() => {
                  form.resetFields();
                  setIsModalVisible(false);
                  setImageFiles(null);
                  setImageShow(null);
                }}
                width={"80%"}
                confirmLoading={loading}
                closable={false}
                maskClosable={false}
                footer={[
                  <Button
                    icon={<CloseCircleOutlined />}
                    key="back"
                    onClick={() => {
                      form.resetFields();
                      setIsModalVisible(false);
                      setImageFiles(null);
                      setImageShow(null);
                    }}
                    disabled={loading}
                  >
                    ยกเลิก
                  </Button>,
                  <Button
                    icon={<CheckCircleOutlined />}
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={handleOk}
                  >
                    ยืนยันเพิ่มข้อมูล
                  </Button>,
                ]}
              >
                <>
                  <Form
                    {...formItemLayout}
                    layout={formLayout}
                    form={form}
                    initialValues={{
                      layout: "vertical",
                      prefix: 1,
                      graduateType: 1,
                      field: 1,
                      status: "ปกติ",
                      isActive: true,
                    }}
                  >
                    {ImageShow !== null && (
                      <center>
                        <Avatar
                          shape={"square"}
                          size={140}
                          style={{
                            alignSelf: "center",
                            backgroundColor: "#cccccc",
                            marginBottom: 10,
                            borderRadius: 10,
                          }}
                          src={
                            <Image
                              src={ImageShow}
                              style={{
                                width: 140,
                                height: 140,
                                objectFit: "cover",
                              }}
                            />
                          }
                        />
                      </center>
                    )}
                    <Form.Item label="โปรไฟล์" name="avatar">
                      <Input
                        type={"file"}
                        placeholder="ระบุไฟล์รูปภาพ"
                        accept="image/jpeg, image/png"
                        onChange={onImageChange}
                      />
                    </Form.Item>
                    <Form.Item
                      label="รหัสนักศึกษา"
                      name="code"
                      required
                      rules={[
                        {
                          required: true,
                          pattern: new RegExp(/^[0-9]*$/),
                          message:
                            "กรุณาระบุรหัสนักศึกษา! และต้องเป็นตัวเลขเท่านั้น!",
                        },
                        {
                          validator: checkDuplicateCode,
                        },
                      ]}
                    >
                      <Input
                        placeholder="ระบุรหัสนักศึกษา"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                      />
                    </Form.Item>
                    <Form.Item label="คำนำหน้า" name="prefix" required>
                      <Select>
                        {allPrefixs.map((v) => (
                          <Select.Option key={v.id} value={v.id}>
                            {v.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="ชื่อ"
                      name="firstName"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุชื่อ!",
                        },
                      ]}
                    >
                      <Input placeholder="ระบุชื่อ" />
                    </Form.Item>
                    <Form.Item label="ฉายา" name="nickname">
                      <Input placeholder="ระบุฉายา" />
                    </Form.Item>
                    <Form.Item label="นามสกุล" name="lastName">
                      <Input placeholder="ระบุนามสกุล" />
                    </Form.Item>
                    <Form.Item label="เบอร์มือถือ" name="mobile">
                      <Input
                        placeholder="ระบุเบอร์มือถือ"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                      />
                    </Form.Item>
                    <Form.Item label="อีเมล" name="email">
                      <Input placeholder="ระบุอีเมล" />
                    </Form.Item>
                    <Form.Item
                      label="ชื่อผู้ใช้งาน"
                      name="username"
                      required
                      rules={[
                        {
                          required: true,
                          pattern: new RegExp(/^[0-9]*$/),
                          message:
                            "กรุณาระบุชื่อผู้ใช้งาน! และต้องเป็นตัวเลขเท่านั้น!",
                        },
                        {
                          validator: checkDuplicateUsername,
                        },
                      ]}
                    >
                      <Input
                        placeholder="ระบุชื่อผู้ใช้งาน"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                      />
                    </Form.Item>
                    <Form.Item
                      label="รหัสผ่าน"
                      name="password"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุรหัสผ่าน!",
                        },
                      ]}
                    >
                      <Input placeholder="ระบุรหัสผ่าน" type="password" />
                    </Form.Item>
                    <Form.Item
                      label="ประเภทบัณฑิต"
                      name="graduateType"
                      required
                    >
                      <Select>
                        {allGraduateTypes.map((v) => (
                          <Select.Option key={v.id} value={v.id}>
                            {v.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="สาขา" name="field" required>
                      <Select>
                        {allField.map((v) => (
                          <Select.Option key={v.id} value={v.id}>
                            {v.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="ปีการศึกษาที่เข้า"
                      name="educateYear"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุปีการศึกษาที่เข้า!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="ระบุปีการศึกษาที่เข้า เช่น 2564"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                      />
                    </Form.Item>
                    <Form.Item
                      label="จำนวนวันปฏิบัติธรรมที่คาดหวัง"
                      name="numOfDharmaMust"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุจำนวนวันปฏิบัติธรรมที่คาดหวัง!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="ระบุจำนวนวันปฏิบัติธรรมที่คาดหวัง"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                      />
                    </Form.Item>
                    <Form.Item
                      label="จำนวนวันปฏิบัติธรรมที่สำเร็จ"
                      name="numOfDharma"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุจำนวนวันปฏิบัติธรรมที่สำเร็จ!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="ระบุจำนวนวันปฏิบัติธรรมที่สำเร็จ"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                      />
                    </Form.Item>
                    <Form.Item
                      label="จำนวนวันปฏิบัติธรรมที่ต้องซ่อม"
                      name="numOfDharmaCompensate"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุจำนวนวันปฏิบัติธรรมที่ต้องซ่อม!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="ระบุจำนวนวันปฏิบัติธรรมที่ต้องซ่อม"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                      />
                    </Form.Item>
                    <Form.Item label="สถานะ" name="status" required>
                      <Select>
                        <Select.Option value="ปกติ">ปกติ</Select.Option>
                        <Select.Option value="พักการศึกษา">
                          พักการศึกษา
                        </Select.Option>
                        <Select.Option value="ลาออก">ลาออก</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="ที่ปรึกษา"
                      name="advisorId"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาเลือกที่ปรึกษา!",
                        },
                      ]}
                    >
                      <Select placeholder="เลือกที่ปรึกษา">
                        {allTeacher.map((v) => (
                          <Select.Option key={v.id} value={v.id}>
                            {v.firstName} {v.nickname} {v.lastName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="ที่อยู่" name="address">
                      <Input placeholder="ระบุที่อยู่" />
                    </Form.Item>
                    <Form.Item label="ถนน" name="road">
                      <Input placeholder="ระบุถนน" />
                    </Form.Item>
                    <Form.Item label="แขวง/ตำบล" name="subdistrict">
                      <Input placeholder="ระบุแขวง/ตำบล" />
                    </Form.Item>
                    <Form.Item label="เขต/อำเภอ" name="district">
                      <Input placeholder="ระบุเขต/อำเภอ" />
                    </Form.Item>
                    <Form.Item label="จังหวัด" name="province">
                      <Input placeholder="ระบุจังหวัด" />
                    </Form.Item>
                    <Form.Item label="รหัสไปรษณีย์" name="postcode">
                      <Input placeholder="ระบุรหัสไปรษณีย์" />
                    </Form.Item>
                    <Form.Item
                      valuePropName="checked"
                      label="เปิด/ปิดการใช้งาน"
                      name="isActive"
                    >
                      <Switch
                        checkedChildren="เปิด "
                        unCheckedChildren="ปิด "
                        defaultChecked
                      />
                    </Form.Item>
                  </Form>
                </>
              </Modal>

              <Modal
                title="แก้ไขข้อมูลนิสิต"
                visible={isModalEditVisible}
                onOk={handleEditOk}
                onCancel={() => {
                  formEdit.resetFields();
                  setIsModalEditVisible(false);
                  setImageFiles(null);
                  setImageShow(null);
                }}
                width={"80%"}
                confirmLoading={loading}
                closable={false}
                maskClosable={false}
                footer={[
                  <Button
                    icon={<CloseCircleOutlined />}
                    key="back"
                    onClick={() => {
                      formEdit.resetFields();
                      setIsModalEditVisible(false);
                      setImageFiles(null);
                      setImageShow(null);
                    }}
                    disabled={loading}
                  >
                    ยกเลิก
                  </Button>,
                  <Button
                    icon={<CheckCircleOutlined />}
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={handleEditOk}
                  >
                    ยืนยันแก้ไขข้อมูล
                  </Button>,
                ]}
              >
                <>
                  <Form
                    {...formItemLayout}
                    layout={formLayout}
                    form={formEdit}
                    initialValues={{
                      layout: "vertical",
                      role: "นิสิต",
                      isActive: true,
                    }}
                  >
                    {ImageShow !== null && (
                      <center>
                        <Avatar
                          shape={"square"}
                          size={140}
                          style={{
                            alignSelf: "center",
                            backgroundColor: "#cccccc",
                            marginBottom: 10,
                            borderRadius: 10,
                          }}
                          src={
                            <Image
                              src={ImageShow}
                              style={{
                                width: 140,
                                height: 140,
                                objectFit: "cover",
                              }}
                            />
                          }
                        />
                      </center>
                    )}
                    <Form.Item label="โปรไฟล์" name="avatar">
                      <Input
                        type={"file"}
                        placeholder="ระบุไฟล์รูปภาพ"
                        accept="image/jpeg, image/png"
                        onChange={onImageChange}
                      />
                    </Form.Item>
                    <Form.Item
                      label="รหัสนักศึกษา"
                      name="code"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุรหัสนักศึกษา!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="ระบุรหัสนักศึกษา"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        disabled
                      />
                    </Form.Item>
                    <Form.Item label="คำนำหน้า" name="prefix" required>
                      <Select>
                        {allPrefixs.map((v) => (
                          <Select.Option key={v.id} value={v.id}>
                            {v.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="ชื่อ"
                      name="firstName"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุชื่อ!",
                        },
                      ]}
                    >
                      <Input placeholder="ระบุชื่อ" />
                    </Form.Item>
                    <Form.Item label="ฉายา" name="nickname">
                      <Input placeholder="ระบุฉายา" />
                    </Form.Item>
                    <Form.Item label="นามสกุล" name="lastName">
                      <Input placeholder="ระบุนามสกุล" />
                    </Form.Item>
                    <Form.Item label="เบอร์มือถือ" name="mobile">
                      <Input
                        placeholder="ระบุเบอร์มือถือ"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                      />
                    </Form.Item>
                    <Form.Item label="อีเมล" name="email">
                      <Input placeholder="ระบุอีเมล" />
                    </Form.Item>
                    <Form.Item label="ชื่อผู้ใช้งาน" name="username" required>
                      <Input
                        placeholder="ระบุชื่อผู้ใช้งาน"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        disabled
                      />
                    </Form.Item>
                    <Form.Item
                      label="รหัสผ่าน"
                      name="password"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุรหัสผ่าน!",
                        },
                      ]}
                    >
                      <Input placeholder="ระบุรหัสผ่าน" type="password" />
                    </Form.Item>
                    <Form.Item
                      label="ประเภทบัณฑิต"
                      name="graduateType"
                      required
                    >
                      <Select>
                        {allGraduateTypes.map((v) => (
                          <Select.Option key={v.id} value={v.id}>
                            {v.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="สาขา" name="field" required>
                      <Select>
                        {allField.map((v) => (
                          <Select.Option key={v.id} value={v.id}>
                            {v.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="ปีการศึกษาที่เข้า"
                      name="educateYear"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุปีการศึกษาที่เข้า!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="ระบุปีการศึกษาที่เข้า เช่น 2564"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                      />
                    </Form.Item>
                    <Form.Item
                      label="จำนวนวันปฏิบัติธรรมที่คาดหวัง"
                      name="numOfDharmaMust"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุจำนวนวันปฏิบัติธรรมที่คาดหวัง!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="ระบุจำนวนวันปฏิบัติธรรมที่คาดหวัง"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                      />
                    </Form.Item>
                    <Form.Item
                      label="จำนวนวันปฏิบัติธรรมที่สำเร็จ"
                      name="numOfDharma"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุจำนวนวันปฏิบัติธรรมที่สำเร็จ!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="ระบุจำนวนวันปฏิบัติธรรมที่สำเร็จ"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                      />
                    </Form.Item>
                    <Form.Item
                      label="จำนวนวันปฏิบัติธรรมที่ต้องซ่อม"
                      name="numOfDharmaCompensate"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุจำนวนวันปฏิบัติธรรมที่ต้องซ่อม!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="ระบุจำนวนวันปฏิบัติธรรมที่ต้องซ่อม"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                      />
                    </Form.Item>
                    <Form.Item label="สถานะ" name="status" required>
                      <Select>
                        <Select.Option value="ปกติ">ปกติ</Select.Option>
                        <Select.Option value="พักการศึกษา">
                          พักการศึกษา
                        </Select.Option>
                        <Select.Option value="ลาออก">ลาออก</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="ที่ปรึกษา"
                      name="advisorId"
                      required
                      rules={[
                        {
                          required: true,
                          message: "กรุณาเลือกที่ปรึกษา!",
                        },
                      ]}
                    >
                      <Select placeholder="เลือกที่ปรึกษา">
                        {allTeacher.map((v) => (
                          <Select.Option key={v.id} value={v.id}>
                            {v.firstName} {v.nickname} {v.lastName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="ที่อยู่" name="address">
                      <Input placeholder="ระบุที่อยู่" />
                    </Form.Item>
                    <Form.Item label="ถนน" name="road">
                      <Input placeholder="ระบุถนน" />
                    </Form.Item>
                    <Form.Item label="แขวง/ตำบล" name="subdistrict">
                      <Input placeholder="ระบุแขวง/ตำบล" />
                    </Form.Item>
                    <Form.Item label="เขต/อำเภอ" name="district">
                      <Input placeholder="ระบุเขต/อำเภอ" />
                    </Form.Item>
                    <Form.Item label="จังหวัด" name="province">
                      <Input placeholder="ระบุจังหวัด" />
                    </Form.Item>
                    <Form.Item label="รหัสไปรษณีย์" name="postcode">
                      <Input placeholder="ระบุรหัสไปรษณีย์" />
                    </Form.Item>
                    <Form.Item
                      valuePropName="checked"
                      label="เปิด/ปิดการใช้งาน"
                      name="isActive"
                    >
                      <Switch
                        checkedChildren="เปิด "
                        unCheckedChildren="ปิด "
                        defaultChecked
                      />
                    </Form.Item>
                  </Form>
                </>
              </Modal>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default Users;
