import React from "react";
import { Row, Col, Card, List, Badge } from "antd";
import { useSelector } from "react-redux";
import { ReadOutlined, UserOutlined } from "@ant-design/icons";
import { SubAdminChecker } from "../../utils/AdminChecker";

function DharmaOnYearMain() {
  SubAdminChecker();

  const userRole = localStorage.getItem("@userRole");
  const onsiteGoCount = useSelector(
    (state) => state.notification.onsiteGoCount
  );
  const onsiteRsCount = useSelector(
    (state) => state.notification.onsiteRsCount
  );

  const dataForAdmin = [
    {
      title: "ข้อมูลปฏิบัติธรรมประจำปี",
      description: "เพิ่ม แก้ไข จัดการข้อมูลปฏิบัติธรรมประจำปี",
      icon: <ReadOutlined />,
      link: "/dharma-onyear/dharma-onyear-data",
      count: 0,
    },
    {
      title: "ข้อมูลการลงทะเบียนเข้าร่วมปฏิบัติธรรมประจำปี",
      description: "ตรวจสอบข้อมูลการลงทะเบียนเข้าร่วมปฏิบัติธรรมประจำปี",
      icon: <ReadOutlined />,
      link: "/dharma-onyear/dharma-onyear-register",
      count: 0,
    },
    {
      title: "ตรวจสอบรายงานผลเข้าร่วมปฏิบัติธรรมประจำปี",
      description: "ตรวจสอบรายงานผลเข้าร่วมปฏิบัติธรรมประจำปี",
      icon: <ReadOutlined />,
      link: "/dharma-onyear/dharma-onyear-checker",
      count: 0,
    },
    {
      title: "ประชุมสรุปผลปฏิบัติธรรมประจำปี",
      description:
        "ประชุม ตรวจสอบ แก้ไข ยืนยัน ผลประชุมสรุปผลปฏิบัติธรรมประจำปี",
      icon: <ReadOutlined />,
      link: "/dharma-onyear/dharma-onyear-discussion",
      count: 0,
    },
    {
      title: "รายงานประวัติปฏิบัติธรรมประจำปีรายบุคคุล",
      description: "ดู รายงานประวัติปฏิบัติธรรมประจำปีรายบุคคุล",
      icon: <ReadOutlined />,
      link: "/dharma-onyear/dharma-onyear-report-user",
      count: 0,
    },
    {
      title: "รายงานสถานะนิสิตลงทะเบียนซ้ำซ้อน",
      description: "ดู รายงานสถานะนิสิตลงทะเบียนซ้ำซ้อน",
      icon: <ReadOutlined />,
      link: "/dharma-onyear/dharma-onyear-report-register-duplication",
      count: 0,
    },
    {
      title: "รายงานนิสิตที่ยังไม่ลงทะเบียนเข้าร่วมปฏิบัติธรรมประจำปี",
      description:
        "ดู รายงานรายงานนิสิตที่ยังไม่ลงทะเบียนเข้าร่วมปฏิบัติธรรมประจำปี",
      icon: <ReadOutlined />,
      link: "/dharma-onyear/dharma-onyear-report-unregister",
      count: 0,
    },
  ];

  const dataForSubAdmin = [
    {
      title: "ข้อมูลปฏิบัติธรรมประจำปี",
      description: "เพิ่ม แก้ไข จัดการข้อมูลปฏิบัติธรรมประจำปี",
      icon: <ReadOutlined />,
      link: "/dharma-onyear/dharma-onyear-data",
      count: 0,
    },
    {
      title: "ข้อมูลการลงทะเบียนเข้าร่วมปฏิบัติธรรมประจำปี",
      description: "ตรวจสอบข้อมูลการลงทะเบียนเข้าร่วมปฏิบัติธรรมประจำปี",
      icon: <ReadOutlined />,
      link: "/dharma-onyear/dharma-onyear-register",
      count: 0,
    },
    {
      title: "ตรวจสอบรายงานผลเข้าร่วมปฏิบัติธรรมประจำปี",
      description: "ตรวจสอบรายงานผลเข้าร่วมปฏิบัติธรรมประจำปี",
      icon: <ReadOutlined />,
      link: "/dharma-onyear/dharma-onyear-checker",
      count: 0,
    },
    {
      title: "ประชุมสรุปผลปฏิบัติธรรมประจำปี",
      description:
        "ประชุม ตรวจสอบ แก้ไข ยืนยัน ผลประชุมสรุปผลปฏิบัติธรรมประจำปี",
      icon: <ReadOutlined />,
      link: "/dharma-onyear/dharma-onyear-discussion",
      count: 0,
    },
    {
      title: "รายงานประวัติปฏิบัติธรรมประจำปีรายบุคคุล",
      description: "ดู รายงานประวัติปฏิบัติธรรมประจำปีรายบุคคุล",
      icon: <ReadOutlined />,
      link: "/dharma-onyear/dharma-onyear-report-user",
      count: 0,
    },
    {
      title: "รายงานสถานะนิสิตลงทะเบียนซ้ำซ้อน",
      description: "ดู รายงานสถานะนิสิตลงทะเบียนซ้ำซ้อน",
      icon: <ReadOutlined />,
      link: "/dharma-onyear/dharma-onyear-report-register-duplication",
      count: 0,
    },
    {
      title: "รายงานนิสิตที่ยังไม่ลงทะเบียนเข้าร่วมปฏิบัติธรรมประจำปี",
      description:
        "ดู รายงานรายงานนิสิตที่ยังไม่ลงทะเบียนเข้าร่วมปฏิบัติธรรมประจำปี",
      icon: <ReadOutlined />,
      link: "/dharma-onyear/dharma-onyear-report-unregister",
      count: 0,
    },
  ];
  var data = [];
  if (userRole === "admin") {
    data = dataForAdmin;
  } else {
    data = dataForSubAdmin;
  }

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24}>
          <Row gutter={[24, 0]}>
            <Col xs={24} className="mb-24">
              <Card
                className="header-solid h-full ant-card-p-0"
                title={
                  <>
                    <Row
                      gutter={[24, 0]}
                      className="ant-row-flex ant-row-flex-middle"
                    >
                      <Col xs={24} md={12}>
                        <h6 className="font-semibold m-0">
                          ปฏิบัติธรรมประจำปี
                        </h6>
                      </Col>
                    </Row>
                  </>
                }
              >
                <List
                  itemLayout="horizontal"
                  dataSource={data}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={
                          <Badge size="small" count={item.count || 0}>
                            {item.icon}
                          </Badge>
                        }
                        title={<a href={item.link}>{item.title}</a>}
                        description={item.description}
                      />
                    </List.Item>
                  )}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default DharmaOnYearMain;
