import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Input,
  Space,
  Typography,
  Modal,
  Alert,
} from "antd";
import {
  SearchOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { SubAdminChecker } from "../../utils/AdminChecker";
import {
  getRegisterOverall,
  getRegisterUserData,
  downloadExcelRegister,
} from "../../services/DharmaOnyearService";
import DhmOnYearRegister from "./components/DhmOnYearRegister";

const { Column, ColumnGroup } = Table;

function DharmaOnYearRegister() {
  SubAdminChecker();
  const [loading, setLoading] = useState(false);
  const [allFields, setAllFields] = useState([]);

  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [registerData, setRegisterData] = useState([]);
  const [userRegisterData, setUserRegisterData] = useState([]);
  const [onYearName, setOnYearName] = useState("");
  const [onYearId, setOnYearId] = useState("");

  const searchInput = useRef(null);

  useEffect(() => {
    // todo
    // get all User to checker
    initAllFields();
  }, []);

  const initAllFields = async () => {
    const res = await getRegisterOverall();
    if (!res._error) {
      setAllFields(res.data);
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const openData = async (data) => {
    setOnYearName(data.name);
    setOnYearId(data.id);
    const res = await getRegisterUserData(data.id);
    if (!res._error) {
      setUserRegisterData(res.data);
      setIsModalVisible(true);
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 80,
      ellipsis: true,
    },
    {
      title: "ชื่อกิจกรรม",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "จำนวนที่เปิดรับ",
      dataIndex: "maxSeats",
      key: "maxSeats",
      width: 160,
      ...getColumnSearchProps("maxSeats"),
      sorter: (a, b) => a.maxSeats - b.maxSeats,
      sortOrder: sortedInfo.columnKey === "maxSeats" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "บรรพชิต",
      dataIndex: "sumType_Id1",
      key: "sumType_Id1",
      width: 120,
      ellipsis: true,
    },
    {
      title: "คฤหัสถ์ชาย",
      dataIndex: "sumType_Id2",
      key: "sumType_Id2",
      width: 120,
      ellipsis: true,
    },
    {
      title: "คฤหัสถ์หญิง",
      dataIndex: "sumType_Id3",
      key: "sumType_Id3",
      width: 120,
      ellipsis: true,
    },
    {
      title: "รวม",
      dataIndex: "sumAll",
      key: "sumAll",
      width: 120,
      ellipsis: true,
    },
    {
      title: "จำนวนคงเหลือ",
      dataIndex: "remaining",
      key: "remaining",
      width: 160,
      ellipsis: true,
      render: (_, record) => record.maxSeats - record.sumAll,
    },
    {
      title: "ดูข้อมูล",
      dataIndex: "sumWait",
      key: "sumWait",
      width: 120,
      render: (_, record) =>
        allFields.length >= 1 ? (
          <>
            <Typography.Link
              onClick={() => openData(record)}
              style={{ marginLeft: 6 }}
            >
              {"ดูข้อมูล"}
            </Typography.Link>
          </>
        ) : null,
    },
    {
      title: "พิมพ์รายชื่อ",
      dataIndex: "printRegis",
      key: "printRegis",
      width: 120,
      render: (_, record) =>
        allFields.length >= 1 ? (
          <>
            <Typography.Link onClick={() => downloadExcel(record.id)}>
              <FileExcelOutlined style={{ color: "green" }} />
              {".xlsx"}
            </Typography.Link>
          </>
        ) : null,
    },
  ];

  const downloadExcel = async (id) => {
    await downloadExcelRegister(id);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24}>
          <Row gutter={[24, 0]}>
            <Col xs={24} className="mb-24">
              <Card
                className="header-solid h-full ant-card-p-0"
                title={
                  <>
                    <Row
                      gutter={[24, 0]}
                      className="ant-row-flex ant-row-flex-middle"
                    >
                      <Col xs={24} md={12}>
                        <h6 className="font-semibold m-0">
                          ข้อมูลลงทะเบียนปฏิบัติธรรมประจำปี
                        </h6>
                      </Col>
                    </Row>
                  </>
                }
              >
                <Table
                  sticky
                  dataSource={allFields}
                  onChange={handleChange}
                  scroll={{ x: 1200 }}
                  rowKey="id"
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "30"],
                  }}
                >
                  <Column {...columns[0]} />
                  <Column {...columns[1]} />
                  <Column {...columns[2]} />
                  <ColumnGroup title="จำนวนที่ลงทะเบียน">
                    <Column {...columns[3]} />
                    <Column {...columns[4]} />
                    <Column {...columns[5]} />
                    <Column {...columns[6]} />
                  </ColumnGroup>
                  <Column {...columns[7]} />
                  <Column {...columns[8]} />
                  <Column {...columns[9]} />
                </Table>

                <Modal
                  title={`การลงทะเบียน : ${onYearName}`}
                  visible={isModalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  footer={null}
                  width="80%"
                >
                  <DhmOnYearRegister
                    onYearId={onYearId}
                    userRegisterDatas={userRegisterData}
                  />
                </Modal>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default DharmaOnYearRegister;
